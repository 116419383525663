import {
	Flex,
	FormControl,
	FormLabel,
	ListItem,
	OrderedList,
	Text,
	Tooltip,
	UnorderedList,
} from '@chakra-ui/react';
import Card from '../../../../components/card/Card';
import GenericInput from '../../../../components/inputs/GenericInput';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
	defaultInputStyle,
	defaultTooltipProps,
} from '../../../../utils/forms/defaultsProps';
import AbasAcuracidade from './abasAcuracidade';
import { useQuery } from '../../../../services/hooks/useQuery';
import { LoadingSpinner } from '../../../../components/loading/Loading';
import { ButtonComponent } from '../../../../components/buttons/ButtonComponent';
import { IconFilter } from '@tabler/icons';
import { AlertComponent } from '../../../../components/alerts/AlertComponent';

import { InputFormLabel } from '../../../../components/fields/InputFormLabel';
import { ScrollBar } from 'components/scroolbar/Scrollbar';
import AcuracidadeProvider, { useAcuracidade } from './context';
import useSidebarHelp from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';

import './Acuracidade.css';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

const AcuracidadeComponent = () => {
	const { setters } = useSidebarHelp();

	const { setDataSidebar } = setters;

	const {
		filters,
		setFilters,
		selectedTab,
		setLastSelectedDate,
		setCanShowAddtionalInfo,
		tableFilters,
		setFilteredDates,
		salesChannelData,
		isLoadingSalesChannelData,
		accuracyFilters,
		isLoadingAccuracyFilters,
		mutateAccuracyFilters,
		isLoadingAccuracyDataControl,
		mutateAccuracyDataControl,
		isLoadingAccuracyDataGraphicalAnalysis,
		mutateAccuracyDataGraphicalAnalysis,
		isLoadingAccuracyDataTable,
		mutateAccuracyDataTable,
		accuracyDataTable,
		getInitialPrevisionType,
	} = useAcuracidade();

	const query = useQuery();
	const areaColaboracao = query.get('areaColaboracao');

	const [hasAccuracy, setHasAccuracy] = useState<boolean>(false);

	const [isFirstRender, setFirstRender] = useState<boolean>(true);

	const forecastType = useMemo(
		() => [
			{
				label: 'Colab. Marketing',
				value: 'colab_marketing',
			},
			{
				label: 'Colab. Planejamento',
				value: 'colab_planejamento',
			},
			{
				label: 'Colab. Comercial',
				value: 'colab_comercial',
			},
			{
				label: 'Plano Irrestrito',
				value: 'consenso',
			},
			{
				label: 'Plano Restrito',
				value: 'restrito',
			},
		],
		[],
	);

	const indicatorType = useMemo(
		() => [
			{
				label: 'Acuracidade Operacional',
				value: 'acuracidade_operacional',
			},
			{
				label: 'Acuracidade Comercial',
				value: 'acuracidade_comercial',
			},
			{
				label: 'MAPE',
				value: 'mape',
			},
		],
		[],
	);

	const selectPrevisionTypes = useMemo(() => {
		return forecastType?.map((type) => (
			<option
				value={type?.value}
				key={type?.value}
				disabled={
					selectedTab === 'analise_grafica' &&
					type?.value?.includes('colab')
				}
				style={{
					color:
						selectedTab === 'analise_grafica' &&
						type?.value?.includes('colab')
							? 'gray'
							: '',
				}}
			>
				{type?.label}
			</option>
		));
	}, [forecastType, selectedTab]);

	const selectIndicatorTypes = useMemo(() => {
		return indicatorType?.map((type) => {
			return (
				<option
					value={type?.value}
					key={type?.value}
				>
					{type?.label}
				</option>
			);
		});
	}, [indicatorType]);

	const selectSalesChannel = useMemo(() => {
		return (
			salesChannelData?.map((channel) => (
				<option
					value={channel?.id}
					key={channel?.id}
				>
					{channel?.nome_canal_venda}
				</option>
			)) || []
		);
	}, [salesChannelData]);

	const filterPriority = useMemo(
		() => [
			'consenso',
			'colab_comercial',
			'colab_planejamento',
			'colab_marketing',
			'restrito',
		],
		[],
	);

	const showWarningExistsChannel = useMemo(
		() =>
			accuracyDataTable &&
			!accuracyDataTable?.exists_channel &&
			filters.canal_venda,
		[accuracyDataTable],
	);
	function renderHelp() {
		return (
			<>
				<Text
					pt={3}
					color='easyBLUE.300'
				>
					Para melhorar, é preciso medir. É assim com qualquer
					processo, e com o Planejamento da Demanda, não podia ser
					diferente. A acuracidade da previsão da demanda é crucial
					para o planejamento eficiente, permitindo que as empresas se
					adaptem às mudanças do mercado, evitem custos desnecessários
					e atendam às expectativas dos clientes de maneira mais
					precisa.
				</Text>

				<Text
					pt={3}
					color='easyBLUE.300'
				>
					A easy360 proporciona a análise através de 4 indicadores:
				</Text>

				<OrderedList
					pt={3}
					color='easyBLUE.300'
				>
					<ListItem>
						<b>MAPE (Erro Percentual Absoluto Médio):</b>
						<OrderedList style={{ listStyleType: 'lower-alpha' }}>
							<ListItem>
								O que é: MAPE avalia a precisão de uma previsão
								comparando os valores previstos com os valores
								reais, expressando o erro como uma porcentagem
								da demanda real.
							</ListItem>
							<ListItem>
								Como interpretar: Varia de 0% a 100%. Quanto
								mais próximo de 100% estiver o indicador, melhor
								será a acuracidade. É calculado somando os
								desvios entre realizado vs previsto de todos os
								produtos e dividido pelo realizado total,
								considerando apenas os volumes de produtos.
							</ListItem>
						</OrderedList>
					</ListItem>
					<ListItem>
						<b>WMAPE (Erro Percentual Absoluto Médio Ponderado):</b>
						<OrderedList style={{ listStyleType: 'lower-alpha' }}>
							<ListItem>
								O que é: O WMAPE é uma variação do MAPE,
								atribuindo pesos de acordo com os erros de
								previsão dos produtos.
							</ListItem>
							<ListItem>
								Como interpretar: Varia de 0% a 100%. Quanto
								mais próximo de 100% estiver o indicador, melhor
								será a acuracidade. É calculado somando os
								desvios entre realizado vs previsto de todos os
								produtos e dividido pelo realizado total,
								considerando o peso de cada produto.
							</ListItem>
						</OrderedList>
					</ListItem>
					<ListItem>
						<b>Acuracidade Comercial:</b>
						<OrderedList style={{ listStyleType: 'lower-alpha' }}>
							<ListItem>
								O que é: Corresponde à soma das variações entre
								o realizado vs previsto dos produtos,
								multiplicado pelo preço médio do canal de venda
								selecionado.
							</ListItem>
							<ListItem>
								Como interpretar: Este indicador tem como
								finalidade mostrar o impacto que os erros das
								previsões de vendas têm na receita final.
							</ListItem>
						</OrderedList>
					</ListItem>
					<ListItem>
						<b>Acuracidade Operacional:</b>
						<OrderedList style={{ listStyleType: 'lower-alpha' }}>
							<ListItem>
								O que é: Corresponde à soma das variações entre
								o realizado vs previsto dos produtos,
								multiplicado pelo custo do produto.
							</ListItem>
							<ListItem>
								Como interpretar: Este indicador tem como
								finalidade mostrar o impacto que os erros das
								previsões de vendas têm no custo operacional
								final.
							</ListItem>
						</OrderedList>
					</ListItem>
				</OrderedList>

				<Text
					pt={3}
					color='easyBLUE.300'
				>
					Além dos indicadores, temos a orientação de aumento e
					redução de estoque da previsão da demanda:
				</Text>

				<UnorderedList
					pt={3}
					color='easyBLUE.300'
				>
					<ListItem>
						Aumento de Estoque: Vendeu acima do valor que foi
						previsto! Logo, você teve uma redução de estoque!
					</ListItem>
					<ListItem>
						Redução de Estoque: Vendeu abaixo do valor que foi
						previsto! Logo, você teve um aumento de estoque!
					</ListItem>
				</UnorderedList>
			</>
		);
	}

	const helpSales: SidebarHelp = useMemo(
		() => ({
			title: 'Acuracidade',
			firstParagraph: '',
			renderContentAsJSX: true,
			helpContentJSX: renderHelp(),
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	function handleOnChange(name: string, event) {
		let value = event?.target?.value;

		const newFilters = {
			...filters,
			[name]: value,
		};

		if (name === 'tipo_previsao' && value === 'restrito')
			newFilters.canal_venda = '';

		setFilters(newFilters);
	}

	function isSelectDisabled(name) {
		if (!accuracyFilters?.demand_types?.length) return true;

		if (name !== 'canal_venda') return false;

		return filters?.tipo_previsao === 'restrito';
	}

	function renderSelectInput(
		name: string,
		selectOptions: any[],
		label: string,
		labelTooltip: string,
		dataTestKey: string,
	) {
		return (
			<FormControl mr={5}>
				{!!labelTooltip ? (
					<InputFormLabel
						label={label}
						labelTooltip={labelTooltip}
						fontSize='sm'
						fontWeight={500}
						color='easyBLUE.300'
					/>
				) : (
					<FormLabel
						fontSize='sm'
						fontWeight={500}
						color='easyBLUE.300'
					>
						{label}
					</FormLabel>
				)}

				<GenericInput
					type='select'
					inputProps={{
						...(name === 'canal_venda'
							? { placeholder: 'Selecione' }
							: undefined),
						mt: '8px',
						name,
						selectOptions,
						value: filters[name],
						onChange: (e) => handleOnChange(name, e),
						disabled: isSelectDisabled(name),
						...defaultInputStyle,
						'data-test': dataTestKey,
					}}
				/>
			</FormControl>
		);
	}

	function renderDateInput(name: string, label: string, dataTestKey: string) {
		return (
			<FormControl mr={5}>
				<FormLabel
					fontSize={'sm'}
					fontWeight={500}
					color={'easyBLUE.300'}
				>
					{label}
				</FormLabel>
				<GenericInput
					type='month'
					inputProps={{
						name,
						value: filters[name],
						onChange: (e) => handleOnChange(name, e),
						max: moment()
							.startOf('day')
							.add({ year: 1 })
							.format('YYYY-MM'),
						...defaultInputStyle,
						'data-test': dataTestKey,
					}}
				/>
			</FormControl>
		);
	}

	function applyFilter() {
		setLastSelectedDate(filters?.intervalo || '');
		setCanShowAddtionalInfo(
			!filters?.tipo_previsao?.includes('colab') && !filters?.canal_venda,
		);

		const start = moment(filters?.intervalo, 'YYYY-MM')?.startOf('month');
		const end = moment(filters?.intervalo, 'YYYY-MM')?.endOf('month');

		setFilteredDates({
			data_inicio: start?.format('YYYY-MM-DD'),
			data_fim: end?.format('YYYY-MM-DD'),
		});

		mutateAccuracyFilters?.();
	}

	function renderButtons() {
		return (
			<Tooltip
				label='Filtrar'
				{...defaultTooltipProps}
			>
				<span>
					<ButtonComponent
						type='icon'
						icon={<IconFilter />}
						onClick={applyFilter}
						mt='30px'
						data-test='button-filtrar_acuracidade'
					/>
				</span>
			</Tooltip>
		);
	}

	function renderFilter() {
		return (
			<Flex direction={'column'}>
				<Flex
					direction='row'
					gap='3'
					mb='35px'
				>
					{renderDateInput(
						'intervalo',
						'Mês/Ano',
						'input-date_mes-ano_acuracidade',
					)}
					{renderSelectInput(
						'tipo_previsao',
						selectPrevisionTypes,
						'Tipo de Previsão',
						selectedTab === 'analise_grafica'
							? 'As colaborações da demanda não possuem aumento e redução de estoque.'
							: '',
						'select_tipo-previsao_acuracidade',
					)}
					{selectedTab === 'controle_acuracidade'
						? renderSelectInput(
								'canal_venda',
								selectSalesChannel,
								'Canal de Venda',
								'',
								'select_canal-venda_acuracidade',
						  )
						: null}
					{selectedTab === 'analise_grafica'
						? renderSelectInput(
								'tipo_indicador',
								selectIndicatorTypes,
								'Indicador',
								'',
								'select_analise-grafica_acuracidade',
						  )
						: null}
					{renderButtons()}
				</Flex>
				{showWarningExistsChannel && (
					<AlertComponent
						title={'Atenção'}
						description={
							'Canal de venda selecionado não participa do rateio do ciclo/ano utilizado para esta previsão..'
						}
						status={'warning'}
						hasCloseButton
					/>
				)}
			</Flex>
		);
	}

	function renderCardFilter() {
		if (isLoadingSalesChannelData) return null;

		return (
			<Card
				px='20px'
				py='20px'
			>
				{renderFilter()}
			</Card>
		);
	}

	function renderCardTable() {
		if (
			isLoadingAccuracyFilters ||
			isLoadingAccuracyDataTable ||
			isLoadingAccuracyDataControl ||
			isLoadingAccuracyDataGraphicalAnalysis ||
			isLoadingSalesChannelData
		)
			return <LoadingSpinner h='50vh' />;

		if (!hasAccuracy)
			return (
				<AlertComponent
					title='Atenção'
					status='info'
					description='Não existe dados para o mês/ano e tipo de previsão selecionados!'
					hasCloseButton
				/>
			);

		return (
			<Card
				px='20px'
				py='20px'
			>
				<ScrollBar color='secondary'>
					<AbasAcuracidade />
				</ScrollBar>
			</Card>
		);
	}

	useEffect(() => {
		setDataSidebar(helpSales);
	}, [helpSales, setDataSidebar]);

	useEffect(() => {
		setTimeout(() => mutateAccuracyDataTable?.(), 350);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableFilters]);

	useEffect(() => {
		if (accuracyFilters?.demand_types?.length) {
			setHasAccuracy(true);

			if (isFirstRender && areaColaboracao) {
				setFilters((prev) => ({
					...prev,
					tipo_previsao: getInitialPrevisionType(areaColaboracao),
				}));

				setTimeout(() => mutateAccuracyDataTable?.(), 350);
				setTimeout(() => mutateAccuracyDataControl?.(), 350);
				setTimeout(() => mutateAccuracyDataGraphicalAnalysis?.(), 350);
			} else if (isFirstRender) {
				const getDemandType = async () => {
					for (const type of filterPriority) {
						const demand = accuracyFilters?.demand_types?.find(
							(el) => el?.key === type,
						);

						if (demand) return demand;
					}
				};

				getDemandType()
					?.then((demandType) => {
						setFilters((prev) => ({
							...prev,
							tipo_previsao: demandType?.key,
						}));

						setTimeout(() => mutateAccuracyDataTable?.(), 350);
						setTimeout(() => mutateAccuracyDataControl?.(), 350);
						setTimeout(
							() => mutateAccuracyDataGraphicalAnalysis?.(),
							350,
						);
					})
					?.catch(() => {
						setHasAccuracy(false);
					});
			} else {
				setTimeout(() => mutateAccuracyDataTable?.(), 350);
				setTimeout(() => mutateAccuracyDataControl?.(), 350);
				setTimeout(() => mutateAccuracyDataGraphicalAnalysis?.(), 350);
			}
			setFirstRender(false);
		} else {
			setHasAccuracy(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		accuracyFilters?.demand_types,
		areaColaboracao,
		filterPriority,
		isFirstRender,
	]);

	useEffect(() => {
		mutateAccuracyFilters?.();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
			gap='5'
		>
			{renderCardFilter()}
			{renderCardTable()}
		</Flex>
	);
};

const Acuracidade = () => {
	return (
		<AcuracidadeProvider>
			<AcuracidadeComponent />
		</AcuracidadeProvider>
	);
};

export default Acuracidade;
