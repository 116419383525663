import { Flex } from '@chakra-ui/react';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import { useContext, useEffect, useMemo } from 'react';
import FormCadastrarEditarEstoqueAtual from 'components/forms/FormCadastrarEditarEstoqueAtual';
import moment from 'moment';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

export default function CadastrarEstoqueAtual() {
	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const helpCadastrarEstoque: SidebarHelp = useMemo(
		() => ({
			title: 'Cadastrar Estoque Atual',
			firstParagraph: `O estoque atual aponta a quantidade de produtos em estoque para cada família em uma data específica. É importante atualizar o estoque atual diariamente para mantê-lo sempre atualizado.`,
			secondParagraph: `Para cadastrar um novo estoque, selecione a Data desejada, o Produto e entre com a quantidade em Estoque do produto selecionado. Clique em Salvar. Observação: é importante que todos os campos estejam preenchidos.`,
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpCadastrarEstoque);
	}, [helpCadastrarEstoque, setDataSidebar]);

	// TO-DO: ajustar as propriedades
	const initialState = {
		data: moment().startOf('day').format('YYYY-MM-DD'),
		familia_id: null,
		produto_id: null,
		quantidade: null,
	};

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<FormCadastrarEditarEstoqueAtual initialState={initialState} />
		</Flex>
	);
}
