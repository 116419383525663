import { Flex } from '@chakra-ui/react';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import { useContext, useEffect, useMemo } from 'react';
import FormCadastrarEditarVendas from '../../../../../components/forms/FormCadastrarEditarVendas';
import moment from 'moment';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

export default function CadastrarVendas() {
	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const helpCadastrarVendas: SidebarHelp = useMemo(
		() => ({
			title: 'Cadastrar Vendas',
			firstParagraph:
				'Para cadastrar uma nova venda, selecione a Data desejada e entre com os dados dos demais campos. Cada venda deve ser associada a hierarquia comercial responsável pela venda. Para isso, selecione um Canal de Venda e Zona de Venda.',
			secondParagraph:
				'Para cadastrar novos canais de venda e zonas de venda, acesse no menu Cadastros > Comercial > Hierarquia. Para cadastrar uma venda referente a um novo cliente, siga o fluxo de “Importar Vendas”.',
			thirdParagraph:
				'Para cadastrar uma venda referente a um novo produto, cadastre o produto previamente em Cadastros > Produto > Produtos.',
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpCadastrarVendas);
	}, [helpCadastrarVendas, setDataSidebar]);

	const initialState = {
		data_venda: moment().startOf('day').format('YYYY-MM-DD'),
		nf: null,
		canal_venda_id: null,
		zona_venda_id: null,
		cliente_id: null,
		familia_id: null,
		produto_id: null,
		quantidade: null,
		receita_bruta: null,
	};

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<FormCadastrarEditarVendas initialState={initialState} />
		</Flex>
	);
}
