import {
	ButtonGroup,
	Flex,
	Heading,
	SimpleGrid,
	useColorModeValue,
	useDisclosure,
} from '@chakra-ui/react';
import FormLancamentoHoraHora from 'components/forms/FormLancamentoHoraHora';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { AlertComponent } from 'components/alerts/AlertComponent';
import { VisualizarLancamentoModal } from './components/VisualizarLancamentoModal';
import { useContext, useEffect, useMemo, useState } from 'react';
import TimeFormProvider from 'contexts/providers/TimeFormProvider';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { FileUploadModal } from 'components/modal/fileUpload/FileUploadModal';
import { useDownloadTemplateHourlyLaunch } from 'services/queryClient/wrapperHooks/uploads/useDownloadTemplateHourlyLaunch';
import { useUploadHourlyLaunch } from 'services/queryClient/wrapperHooks/uploads/useUploadHourlyLaunch';
import { columnsDataHoraHora } from './variables/columnsDataHoraHora';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

export default function LancamentoHoraHora() {
	const textColor = useColorModeValue('secondaryGray.900', 'white');

	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const helpLancamento: SidebarHelp = useMemo(
		() => ({
			title: 'Lançamento Diário',
			firstParagraph: `Lançamento diário do andamento da produção da sua indústria. As informações aqui cadastradas servem como base para o cálculo do IROG dos seus recursos.`,
			secondParagraph: `Para realizar um lançamento selecione o Produto desejado, a atividade e o recurso utilizado para a execução da tarefa. Entre com a quantidade de peças boas e refugos produzidos, bem como a hora inicial e final daquela atividade.`,
			thirdParagraph: `Informe se houve paradas durante a execução da atividade, clicando no botão “Adicionar Parada”. Informe a hora inicial e final da parada, e o motivo da parada. É possível cadastrar mais de uma parada!`,
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpLancamento);
	}, [helpLancamento, setDataSidebar]);

	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: isOpenImportModal,
		onOpen: onOpenImportModal,
		onClose: onCloseImportmodal,
	} = useDisclosure();

	const [hasBaseData, setHasBaseData] = useState(false);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
			rowGap={6}
		>
			<Flex
				direction='column'
				width='stretch'
			>
				<SimpleGrid
					columns={{ sm: 1, md: 1, lg: 1, xl: 3 }}
					gap='20px'
					mb='20px'
				>
					<Flex>
						<Heading
							color={textColor}
							fontSize='xl'
							fontWeight='700'
							lineHeight='100%'
						/>
					</Flex>
				</SimpleGrid>
			</Flex>

			<Flex
				justifyContent='space-between'
				alignSelf={'flex-end'}
			>
				<ButtonGroup spacing={'20px'}>
					<ButtonComponent
						data-test='button-importar-lancamento_diario'
						type={'ghost'}
						title={'Importar Lançamentos'}
						onClick={onOpenImportModal}
					/>
					<FileUploadModal
						isOpen={isOpenImportModal}
						onClose={onCloseImportmodal}
						headerText={'Importar Lançamentos Diários'}
						module={'hourly-launch'}
						useDownloadTemplate={useDownloadTemplateHourlyLaunch}
						stringInvalidQuery='hourly'
						useUpload={useUploadHourlyLaunch}
						columnsData={columnsDataHoraHora}
						hasBaseData={hasBaseData}
					/>
					<ButtonComponent
						data-test='button-visualizar-lancamento_diario'
						type={'primary'}
						title={'Visualizar Lançamento'}
						onClick={onOpen}
					/>
				</ButtonGroup>
			</Flex>

			<AlertComponent
				element={'lancamento-diario'}
				title={'Atenção'}
				status={'warning'}
				description={
					'Para realizar lançamentos diários de produtos semi-acabados é necessário cadastrá-los previamente na tela de produtos.\nDica: use um nome contendo a sigla SA para indicar que é semi-acabado (exemplo, SA-0000).'
				}
				hasCloseButton
			/>

			<TimeFormProvider>
				<FormLancamentoHoraHora />
				<VisualizarLancamentoModal
					isOpen={isOpen}
					onClose={onClose}
					setHasBaseData={setHasBaseData}
				/>
			</TimeFormProvider>
		</Flex>
	);
}
