import { ButtonGroup, Flex, useDisclosure } from '@chakra-ui/react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import Card from 'components/card/Card';
import CheckTable from 'components/dataTables/CheckTable';
import { columnsDataVendas } from './variables/columnsDataVendas';
import { FileUploadModal } from 'components/modal/fileUpload/FileUploadModal';
import moment from 'moment';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDownloadTemplateSale } from 'services/queryClient/wrapperHooks/uploads/useDownloadTemplateSale';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useHistory } from 'react-router-dom';
import { useUploadSales } from 'services/queryClient/wrapperHooks/uploads/useUploadSales';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

interface Sales {
	id: number;
	sequencia_nf: number;
	data_venda: string;
	nf: string;
	nome_canal_venda: string;
	nome_zona_venda: string;
	razao_social: string;
	nome_familia: string;
	codigo_produto: string;
	quantidade: number;
	receita_bruta: number;
	data_registro: string;
}

export default function ListingSales() {
	const { isOpen, onOpen, onClose } = useDisclosure();

	let history = useHistory();

	const { pathCompanyName } = useGlobalContext();

	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const tableColumns = useMemo(() => columnsDataVendas, []);
	const [hasBaseData, setHasBaseData] = useState<boolean>(false);

	const helpSales: SidebarHelp = useMemo(
		() => ({
			title: 'Vendas',
			firstParagraph:
				'Nesta tela é possível visualizar todas as Vendas (ou notas fiscais emitidas) da sua indústria no tempo. ',
			secondParagraph:
				'Acesse o botão “Cadastrar Vendas” para adicionar novas vendas ou importe um arquivo de vendas acessando Importar Vendas. Na janela de importação você encontra o template do arquivo. Caso sua importação resultar em erros, por favor, aplique as correções no arquivo e envie ele novamente.',
			thirdParagraph:
				'Observação: os módulos da easy360 utilizam os dados de vendas como base de cálculo. Importante que você mantenha sempre atualizada com as vendas mais recentes.',
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	const dataDelete = useMemo(
		() => ({
			provider: 'sale',
			toastSuccessTitle: 'Excluido com sucesso!',
			toastSuccessDescription: 'A venda foi excluido com sucesso.',
			toastErrorTitle: 'Erro ao excluir venda!',
			title: 'Deseja realmente excluir esta venda?',
			text: [
				'Você está prestes a excluir esta venda da sua base de cadastros.',
				'',
				'Você tem certeza que deseja excluir?',
			],
			invalidate: 'venda-list',
		}),
		[],
	);

	const deleteMessage = useMemo(
		() => [
			'Você está prestes a excluir estas vendas da sua base de cadastros.',
			'',
		],
		[],
	);

	const dataDeleteAll = useMemo(
		() => ({
			provider: 'sale',
			toastSuccessTitle: 'Registros excluídos com sucesso!',
			toastSuccessDescription: 'As vendas foram excluídas com sucesso',
			toastErrorTitle: 'Erro ao excluir vendas!',
			invalidate: 'venda-list',
		}),
		[],
	);

	const goToCadastroVendas = useCallback(() => {
		history.push(`/${pathCompanyName}/cadastros/venda/criar`);
	}, [history, pathCompanyName]);

	useEffect(() => {
		setDataSidebar(helpSales);
	}, [helpSales, setDataSidebar]);

	const renderRow = useCallback((sale: Sales) => {
		return {
			id: sale?.id,
			dataVenda: sale?.data_venda
				? moment(sale?.data_venda, 'YYYY-MM-DD').format('DD/MM/YYYY')
				: '',
			nf: sale?.nf,
			sequenciaNF: sale?.sequencia_nf,
			canalVenda: sale?.nome_canal_venda,
			zonaVenda: sale?.nome_zona_venda,
			razaoSocial: sale?.razao_social,
			familia: sale?.nome_familia,
			codProduto: sale?.codigo_produto,
			quantidade: sale?.quantidade?.toLocaleString('pt-BR'),
			receitaBruta: sale?.receita_bruta
				? sale?.receita_bruta?.toLocaleString?.('pt-BR', {
						style: 'currency',
						currency: 'BRL',
				  })
				: 'R$ 0,00',
			dataRegistro: sale?.data_registro
				? moment
						.utc(sale.data_registro)
						.local()
						.format('DD/MM/YYYY HH:mm:ss')
				: '',
		};
	}, []);

	const renderButtons = useCallback(() => {
		return (
			<Flex
				flexDirection={'row'}
				justifyContent={'flex-end'}
				mt={'20px'}
				me={'20px'}
				mb={'40px'}
			>
				<ButtonGroup spacing={'20px'}>
					<ButtonComponent
						data-test='button-importar-vendas'
						type={'ghost'}
						title={'Importar Vendas'}
						onClick={onOpen}
					/>
					<FileUploadModal
						isOpen={isOpen}
						onClose={onClose}
						headerText={'Importar Dados de Vendas'}
						module={'vendas'}
						useDownloadTemplate={useDownloadTemplateSale}
						stringInvalidQuery='venda-list'
						useUpload={useUploadSales}
						showLineRestricts={true}
						columnsData={tableColumns}
						hasBaseData={hasBaseData}
					/>
					<ButtonComponent
						data-test='button-cadastrar-vendas'
						type={'primary'}
						title={'Cadastrar Vendas'}
						onClick={goToCadastroVendas}
					/>
				</ButtonGroup>
			</Flex>
		);
	}, [
		goToCadastroVendas,
		isOpen,
		onClose,
		onOpen,
		tableColumns,
		hasBaseData,
	]);

	function renderTable() {
		return (
			<CheckTable
				dataTest='button-filtrar-vendas'
				module='vendas'
				linkEditForm={`/${pathCompanyName}/cadastros/venda/editar`}
				columnsData={tableColumns}
				dataDelete={dataDelete}
				deleteMessage={deleteMessage}
				showGenericSearchInput={false}
				filterModule='venda'
				bulkDeleteProps={dataDeleteAll}
				renderRow={renderRow}
				setHasBaseData={setHasBaseData}
			/>
		);
	}

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				{renderButtons()}
				{renderTable()}
			</Card>
		</Flex>
	);
}
