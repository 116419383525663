import { Flex } from '@chakra-ui/react';
import { LoadingSpinner } from '../../../../../components/loading/Loading';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useGetProductWithId } from '../../../../../services/queryClient/wrapperHooks/useGetProductById';
import { useParams } from 'react-router-dom';
import Card from 'components/card/Card';
import FormCadastrarEditarProduto from '../../../../../components/forms/FormCadastrarEditarProduto';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

export default function EditarProduto() {
	const { setters } = useContext(SidebarHelpContext);
	const { setDataSidebar } = setters;

	const { id: productId } = useParams<any>();

	const { data: ProductData, isLoading: isLoadingProductData } =
		useGetProductWithId(productId);

	const [formInitialState, setFormInitialState] = useState<any>();
	const [autocompleteAdditionalData, setAutocompleteAditionalData] =
		useState<any>();

	const helpEditarProdutos: SidebarHelp = useMemo(
		() => ({
			title: 'Editar Produtos',
			firstParagraph:
				'Para editar um produto, selecione se ele participa do rateio, entre com o nome do Produto, Código do produto, Família e Subfamília.',
			secondParagraph:
				'Para cadastrar um produto em uma nova família, clique no campo de família e no botão Adicionar.',
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpEditarProdutos);
	}, [helpEditarProdutos, setDataSidebar]);

	useEffect(() => {
		if (ProductData) {
			setAutocompleteAditionalData({
				family: ProductData?.familia,
				sub_family: ProductData?.sub_familia,
				product_model: ProductData?.modelo_produto,
			});

			setFormInitialState({
				nome_produto: ProductData?.nome_produto,
				codigo_produto: ProductData?.codigo_produto,
				custo: ProductData?.custo === null ? '' : ProductData.custo,
				familia_id: ProductData?.familia?.id,
				sub_familia_id: ProductData?.sub_familia?.id || '',
				modelo_produto_id: ProductData?.modelo_produto?.id || '',
				status: ProductData?.status,
				rateio: ProductData?.participa_rateio,
			});
		}
	}, [ProductData]);

	return isLoadingProductData || !formInitialState ? (
		<LoadingSpinner />
	) : (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				<FormCadastrarEditarProduto
					initialState={formInitialState}
					productId={productId}
					hasStock={ProductData?.estoque_atual}
					autocompleteAdditionalData={autocompleteAdditionalData}
				/>
			</Card>
		</Flex>
	);
}
