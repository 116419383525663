import { Flex } from '@chakra-ui/react';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useGetPortfolioWithId } from 'services/queryClient/wrapperHooks/portfolio/useGetPortfolioById';
import { useParams } from 'react-router';
import FormCadastrarEditarCarteira from 'components/forms/FormCadastrarEditarCarteira';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

export default function EditarCarteira() {
	const { setters } = useContext(SidebarHelpContext);
	const { setDataSidebar } = setters;

	const { id: portfolioId } = useParams<any>();

	const { data: PortfolioData } = useGetPortfolioWithId(portfolioId);

	const [initialState, setInitialState] = useState<any>();
	const [autocompleteAdditionalData, setAutocompleteAditionalData] =
		useState<any>();

	const helpEditarCarteira: SidebarHelp = useMemo(
		() => ({
			title: 'Editar Carteira de Pedidos',
			firstParagraph: `Para editar uma carteira de cliente, selecione a Competência desejada e entre com os dados do tipo de carteira, Canal de Venda, Cliente, Família, código de produto, quantidade e receita bruta.`,
			secondParagraph: `Caso deseje alterar o canal de venda, tenha certeza de ter cadastrado previamente os canais de venda em Cadastros > Comercial > Hierarquia, bem como os produtos em Cadastros > Produtos.`,
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpEditarCarteira);
	}, [helpEditarCarteira, setDataSidebar]);

	useEffect(() => {
		if (PortfolioData) {
			setAutocompleteAditionalData({
				client: PortfolioData?.cliente,
				family: PortfolioData?.produto?.familia,
				product: PortfolioData?.produto,
			});

			setInitialState({
				codigo: PortfolioData?.codigo,
				sequencia_codigo: PortfolioData?.sequencia_codigo,
				data: PortfolioData?.data,
				cliente_id: PortfolioData?.cliente?.id,
				familia_id: PortfolioData?.produto?.familia?.id,
				produto_id: PortfolioData?.produto?.id,
				quantidade: PortfolioData?.quantidade,
				canal_venda_id: PortfolioData?.canalVenda?.id,
				receita_bruta: PortfolioData?.receita_bruta
					? PortfolioData?.receita_bruta
					: 0,
				faturado: PortfolioData?.faturado,
				ruim: PortfolioData?.ruim,
			});
		}
	}, [PortfolioData]);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<FormCadastrarEditarCarteira
				initialState={initialState}
				portfolioId={portfolioId}
				autocompleteAdditionalData={autocompleteAdditionalData}
			/>
		</Flex>
	);
}
