import {
	CircularProgress,
	Flex,
	FormControl,
	FormLabel,
	Select,
	useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import SectionConfig from './components/SectionConfig';
import SectionTable from './components/SectionWorrkingDay';

import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { WorkingDaysModal } from 'components/modal/WorkingDaysModal';

import { useQuery } from '@tanstack/react-query';
import { getWorkingDays } from 'services/api/requests/workingDays';
import { useEditWorkingDays } from 'services/queryClient/wrapperHooks/useEditWorkingDays';

import moment from 'moment';

import { RouterPrompt } from 'components/modal/RouterPrompt';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { applyDateToDateRange } from '../../../../../utils/functions/dateUtils';

import { URL_CT360, URL_FALE_CONOSCO } from '../../../../../constants/global';

export default function DiasUteis() {
	const { data, isLoading, isError, isFetched } = useQuery(
		['working-days-list'],
		getWorkingDays,
		{
			refetchOnWindowFocus: false,
		},
	);

	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const helpRecursos: SidebarHelp = useMemo(
		() => ({
			title: 'Dias Úteis',
			firstParagraph:
				'Nesta tela é possível cadastrar e visualizar a quantidade de Dias Úteis e Colaboradores da sua indústria. A easy360 retorna automaticamente a quantidade de dias úteis, ignorando feriados e finais de semana, mas você pode editar a quantidade de dias trabalhadas. A quantidade de colaboradores é importante para orientar na capacidade produtiva manual.',
			secondParagraph:
				'As configurações de trabalho também compõem o cálculo da capacidade produtiva e a disponibilidade total em minutos da sua indústria. Por isso, é importante informar as horas semanais, regime de trabalho e turnos trabalhados.',
			thirdParagraph:
				'Visualize o histórico das configurações de trabalho acessando o botão “Verificar Cadastro”.',
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpRecursos);
	}, [helpRecursos, setDataSidebar]);

	const [edit, setEdit] = useState([{}]);

	const [closeSave, setCloseSave] = useState(false);

	const currentYear = Number(moment().format('YYYY'));
	const [year, setYear] = useState(currentYear);
	const [agesList, setAgesList] = useState<number[]>([]);

	const [dataTable, setDataTable] = useState<Object>();
	const [initialDataTable, setInitialDataTable] = useState<any>();

	const { mutate } = useEditWorkingDays();

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { onClose: onCloseConfirmSave } = useDisclosure();

	const [tamanhoCiclo] = useState<number>(12);
	const [worrkingDays, setWorrkingDays] = useState({});

	const [formInitialValues, setFormInitialValues] = useState<any>({});
	const [isChangedForm, setChangedForm] = useState(false);
	const [reset, setReset] = useState(false);

	const onChangeAge = (value) => {
		setYear(value);
	};

	const onSubmitTurnos = useCallback(
		(values) => {
			const monthIntialDate = moment({ date: 1 })
				.startOf('day')
				.toISOString();

			const configuracoes = {
				horas_semana: Number(values?.horas_semana),
				regime: values?.regime === 'true',
				quantidade_turno: values?.turnos?.length,
				sabado_inteiro: values?.sabado_inteiro,
				turnos:
					values?.turnos?.map?.((t, i) => {
						const { start: hora_inicio, end: hora_fim } =
							applyDateToDateRange(
								monthIntialDate,
								moment(t?.hora_inicio, 'HH:mm')?.toISOString(),
								moment(t?.hora_fim, 'HH:mm')?.toISOString(),
							);
						return {
							turno: i + 1,
							hora_inicio,
							hora_fim,
							duracao: moment.duration(t?.duracao).asSeconds(),
						};
					}) || [],
			};

			let ano;
			const meses = {};

			for (const key of Object.keys(edit || {})) {
				if (isNaN(Number(key))) continue;

				if (!ano) {
					ano = edit[key]?.ano;
				}

				meses[key] = {
					colaboradores: edit[key]?.colaboradores,
					dias_uteis: edit[key]?.dias_uteis,
					feriados: edit[key]?.feriados,
				};
			}

			const payload = {
				configuracoes,
				meses,
				ano,
			};

			setCloseSave(false);

			mutate(payload);
		},
		[edit, mutate],
	);

	useEffect(() => {
		if (dataTable) {
			let initialTableData;
			if (currentYear === new Date().getFullYear()) {
				const nextMonth =
					new Date().getMonth() + 2 <= 12
						? new Date().getMonth() + 2
						: 12;
				initialTableData = dataTable[nextMonth];
			} else {
				initialTableData = dataTable[1];
			}

			const { horas_semana, regime, turnos, sabado_inteiro } =
				initialTableData;

			setFormInitialValues({
				horas_semana: String(horas_semana),
				regime: String(regime),
				turno: String(turnos?.length),
				sabado_inteiro,
				turnos: turnos?.map((t) => ({
					hora_inicio: moment(
						t?.hora_inicio,
						'YYYY-MM-DDTHH:mm:ss.ZZZ',
					).format('HH:mm'),
					hora_fim: moment(
						t?.hora_fim,
						'YYYY-MM-DDTHH:mm:ss.ZZZ',
					).format('HH:mm'),
					duracao: moment.utc(t?.duracao * 1000)?.format('HH:mm'),
				})),
			});
		}
	}, [currentYear, dataTable]);

	useEffect(() => {
		if (data) {
			const ageList: number[] = data?.map((element) => +element.ano);
			ageList.sort((a, b) => a - b);

			setAgesList(ageList);

			const dataTable = data?.find((element) => +element.ano === +year);

			setDataTable(dataTable);
			setInitialDataTable(JSON.stringify(dataTable));
		}
	}, [data, year]);

	useEffect(() => {
		if (reset) {
			setDataTable(JSON.parse(initialDataTable));
			setReset(false);
		}
	}, [initialDataTable, reset]);

	return (
		<Flex pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<Flex
				direction='column'
				width='stretch'
			>
				{isLoading && (
					<CircularProgress
						isIndeterminate
						color='red.600'
					/>
				)}
				{isError && <h1>Ocorreu um erro, tente novamente!</h1>}
				{isFetched && (
					<>
						<Flex my={8}>
							<FormControl
								mr='5px'
								className='filter-bar'
							>
								<FormLabel>Visualizar ano da tabela</FormLabel>
								<Flex
									gap={3}
									w='20%'
								>
									<Select
										onChange={(event) =>
											onChangeAge(event.target.value)
										}
									>
										{agesList.map((element) => {
											// eslint-disable-next-line eqeqeq
											return (
												<option
													key={`${element}`}
													selected={
														+element ===
														+currentYear
													}
													value={element}
												>
													{element}
												</option>
											);
										})}
									</Select>
								</Flex>
							</FormControl>

							<RouterPrompt
								isOpen={closeSave || isChangedForm}
								onCloseConfirmSave={onCloseConfirmSave}
								message='As alterações não foram salvas, ao sair da tela elas serão perdidas! Deseja voltar e salvar as alterações?'
								discardChanges={true}
							/>

							<Flex
								justify={'end'}
								alignItems={'flex-end'}
							>
								<ButtonComponent
									type={'ghost'}
									onClick={onOpen}
									title={'Verificar Cadastro'}
								/>
								{isOpen && (
									<WorkingDaysModal
										isOpen={isOpen}
										onClose={onClose}
										tamanhoCiclo={tamanhoCiclo}
									/>
								)}
							</Flex>
						</Flex>
						{dataTable && (
							<SectionTable
								defaultValues={initialDataTable}
								setEdit={setEdit}
								setCloseSave={setCloseSave}
								year={year}
								dataWorkingDays={dataTable}
								tamanhoCiclo={tamanhoCiclo}
								setWorrkingDays={setWorrkingDays}
								worrkingDays={worrkingDays}
							/>
						)}
						<Flex justify={'space-between'}>
							<SectionConfig
								onSubmit={onSubmitTurnos}
								initialValues={formInitialValues}
								setReset={setReset}
								setChangedForm={setChangedForm}
							/>
						</Flex>
					</>
				)}
			</Flex>
		</Flex>
	);
}
