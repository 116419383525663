import { Flex } from '@chakra-ui/react';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useContext, useEffect, useMemo } from 'react';
import Card from 'components/card/Card';
import FormCadastrarEditarMembro from 'components/forms/FormCadastrarEditarMembro';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

export default function CadastrarMembros() {
	const { setters } = useContext(SidebarHelpContext);
	const { setDataSidebar } = setters;

	const helpCadastrarMembros = useMemo(
		(): SidebarHelp => ({
			title: 'Cadastrar Membros',
			firstParagraph:
				'Para cadastrar um novo membro entre com os dados de Nome do Membro, Função, E-mail, e Área da Empresa. O único campo obrigatório é o nome do membro.',
			secondParagraph:
				'Observação: o campo área da empresa se refere aos três setores especialistas responsáveis pelas colaborações do processo de demanda.',
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpCadastrarMembros);
	}, [helpCadastrarMembros, setDataSidebar]);

	const formInitialState = {
		area_empresa_id: '',
		ativo: false,
		email: '',
		funcao: '',
		nome_membro: '',
	};

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				<FormCadastrarEditarMembro initialState={formInitialState} />
			</Card>
		</Flex>
	);
}
