import { Flex } from '@chakra-ui/react';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import { useContext, useEffect, useMemo, useState } from 'react';
import FormCadastrarEditarVendas from '../../../../../components/forms/FormCadastrarEditarVendas';
import { useGetSaleWithId } from '../../../../../services/queryClient/wrapperHooks/useGetSaleById';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

export default function EditarVenda() {
	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const { id: saleId } = useParams<any>();

	const { data: SaleData } = useGetSaleWithId(saleId);

	const [initialState, setInitialState] = useState<any>();
	const [autocompleteAdditionalData, setAutocompleteAditionalData] =
		useState<any>();

	const helpEditarVendas: SidebarHelp = useMemo(
		() => ({
			title: 'Editar Vendas',
			firstParagraph:
				'Para editar uma venda, selecione a Data desejada e entre com os dados dos demais campos. Cada venda deve ser associada a hierarquia comercial responsável pela venda.',
			secondParagraph:
				'Para cadastrar novos canais de venda e zonas de venda, acesse no menu Cadastros > Comercial > Hierarquia. Para cadastrar uma venda referente a um novo cliente, siga o fluxo de Importar Vendas.',
			thirdParagraph:
				'Para cadastrar uma venda referente a um novo produto, cadastre o produto previamente em Cadastros > Produto > Produtos.',
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpEditarVendas);
	}, [helpEditarVendas, setDataSidebar]);

	useEffect(() => {
		if (SaleData) {
			setAutocompleteAditionalData({
				product: SaleData?.produto,
				family: SaleData?.produto?.familia,
				client: SaleData?.cliente_relacionado,
			});

			setInitialState({
				nf: SaleData?.nf,
				sequencia_nf: SaleData?.sequencia_nf,
				data_venda: moment(SaleData?.data_venda, 'YYYY-MM-DD').format(
					'YYYY-MM-DD',
				),
				canal_venda_id: SaleData?.canal_venda?.id,
				zona_venda_id: SaleData?.zona_venda?.id,
				cliente_id: SaleData?.cliente_relacionado?.id,
				familia_id: SaleData?.produto?.familia?.id,
				produto_id: SaleData?.produto?.id,
				quantidade: SaleData?.quantidade,
				receita_bruta: SaleData?.receita_bruta
					? Number(SaleData?.receita_bruta?.toFixed(2))
					: 0,
			});
		}
	}, [SaleData]);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<FormCadastrarEditarVendas
				initialState={initialState}
				saleId={saleId}
				autocompleteAdditionalData={autocompleteAdditionalData}
			/>
		</Flex>
	);
}
