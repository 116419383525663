import Card from 'components/card/Card';
import moment from 'moment';
import { AlertComponent } from 'components/alerts/AlertComponent';
import {
	Box,
	ButtonGroup,
	Flex,
	FormControl,
	FormLabel,
	Select,
	Spacer,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
} from '@chakra-ui/react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { LineBarChart } from 'components/chart/LineBarChart';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useCycleList } from 'services/queryClient/wrapperHooks/useCycleList';
import { useDemandDashboardData } from 'services/queryClient/wrapperHooks/dashboardDemanda/useDemandDashboardData';
import { useFamilyListWithoutProducts } from 'services/queryClient/wrapperHooks/useFamilyList';
import { useSalesChannelList } from 'services/queryClient/wrapperHooks/useSalesChannelList';
import { numberToLocaleString } from 'utils/functions/number';
import { ScrollBar } from 'components/scroolbar/Scrollbar';
import UnrestrictedPlanModal from 'components/modal/UnrestrictedPlanModal';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

export default function Dashboard() {
	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const helpDashboardDemanda: SidebarHelp = {
		title: 'Dashboard de Demanda',
		firstParagraph: `Previsão de demanda é o processo em que as empresas estimam a demanda e/ou necessidade futura de produtos e/ou serviços.`,
		secondParagraph: `Neste dashboard é possível analisar a sua demanda para os próximos meses em quantidade de produtos (gráfico de quantidades), bem como uma estimativa de receita bruta (gráfico da receita). Use os filtros de Canal de Venda e Família para visualização mais detalhada.`,
		thirdParagraph: `Acesse o botão "Ver Plano Irrestrito" para analisar a saída do seu processo de demanda por família de produto.`,
		firstLink: URL_CT360,
		secondLink: URL_FALE_CONOSCO,
	};

	useEffect(() => {
		setDataSidebar(helpDashboardDemanda);
	}, []);

	const { isOpen, onOpen, onClose } = useDisclosure();

	const [filterInput, setFilterInput] = useState({
		year: moment().format('YYYY'),
		cycleId: '',
		salesChannelId: 0,
		familyId: 0,
	});

	const [projectionDataTable, setProjectionDataTable] = useState<any[]>([]);
	const [quantityDataTable, setQuantityDataTable] = useState<any[]>([]);

	// Filtros
	const { data: CycleData } = useCycleList();
	const { data: SalesChannelData } = useSalesChannelList();
	const { data: FamilyData } = useFamilyListWithoutProducts(true);

	// Data: Dashboard de Demanda
	const { data: DemandDashboardData } = useDemandDashboardData(
		Number(filterInput.cycleId),
		filterInput.salesChannelId,
		filterInput.familyId,
	);

	const monthDataArray = DemandDashboardData?.map((budget) => {
		return moment(budget.mes_ano, 'YYYY-MM-DD').format('MM/YYYY');
	});

	const tableMonthArray = useMemo(() => {
		return !!monthDataArray?.length
			? ['Descrição', ...monthDataArray, 'Total']
			: [];
	}, [monthDataArray]);

	// Traz o ciclo atual para o filtro inicial
	useEffect(() => {
		if (CycleData) {
			const actualMonth = moment().format('M');
			CycleData.filter((cycle: any) => {
				if (cycle.ciclo == actualMonth) {
					setFilterInput({
						...filterInput,
						cycleId: cycle.id,
					});
				}
			});
		}
	}, [CycleData]);

	// INÍCIO: Gráfico: Projeções de Quantidade
	const [lineBarChartDataQuantity, setLineBarChartDataQuantity] = useState<
		any[]
	>([
		// array vazio, utilizado para inicializar o Gráfico: Projeções de Quantidade
	]);

	// (Opções de "Configuração")Gráfico: Projeções de Quantidade
	/**
	 * Obs: O gráfico está cortando as bordas dos dados. Existem diversos relatos de usuários do apex charts com
	 * o mesmo problema.
	 * Alguns link dos testes realizados:
	 * 	https://github.com/apexcharts/apexcharts.js/issues/1685
	 * 	https://github.com/apexcharts/apexcharts.js/issues/958#issuecomment-539441492
	 *  https://github.com/apexcharts/apexcharts.js/issues/661
	 *
	 * Não incluir propriedade tooltip em yaxis, pois o gráfico não renderiza o tooltip corretamente
	 */
	const [lineBarChartOptionsQuantity, setLineBarChartOptionsQuantity] =
		useState({
			title: {
				text: 'Projeções de Quantidade',
				offsetX: 50,
				offsetY: -5,
				style: {
					color: '#314866',
					fontSize: '24px',
					fontWeight: 'bold',
					fontFamily: undefined,
				},
			},
			chart: {
				toolbar: {
					show: true,
				},
			},
			tooltip: {
				theme: 'dark',
				x: {
					show: true,
					format: 'MM/yy',
					formatter: undefined,
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: 'smooth',
				show: true,
				width: [4, 4, 4, 4, 2, 2],
				colors: [
					'transparent',
					'transparent',
					'transparent',
					'transparent',
					'#25364D',
					'#B01A2E',
				],
				dashArray: [null, null, null, null, 10, 10],
			},
			xaxis: {
				tickPlacement: 'on',
				type: 'datetime',
				categories: monthDataArray,
				labels: {
					style: {
						colors: '#A3AED0',
						fontSize: '12px',
						fontWeight: 500,
					},
					format: 'MM/yy',
					showDuplicates: false,
				},
				axisBorder: {
					show: false,
				},
			},
			yaxis: [
				{
					seriesName: 'Mesmo mês ano anterior',
					show: false,
					labels: {
						style: {
							colors: '#A3AED0',
						},
						formatter: function (value) {
							return `${value?.toLocaleString('pt-BR', {
								minimumFractionDigits: 0,
								maximumFractionDigits: 0,
							})}`;
						},
						offsetX: -15,
					},
					tickAmount: 4,
					max: 0,
					min: 0,
				},
				{
					seriesName: 'Orçamento',
					axisTicks: {
						show: true,
					},
					axisBorder: {
						show: true,
						color: '#A3AED0',
					},
					labels: {
						style: {
							colors: '#A3AED0',
						},
						formatter: function (value) {
							return `${value?.toLocaleString('pt-BR', {
								minimumFractionDigits: 0,
								maximumFractionDigits: 0,
							})}`;
						},
						offsetX: -15,
					},
					tickAmount: 4,
					max: 0,
					min: 0,
				},
				{
					seriesName: 'Plano irrestrito',
					show: false,
					labels: {
						style: {
							colors: '#A3AED0',
						},
						formatter: function (value) {
							return `${value?.toLocaleString('pt-BR', {
								minimumFractionDigits: 0,
								maximumFractionDigits: 3,
							})}`;
						},
					},
					tickAmount: 4,
					max: 0,
					min: 0,
				},
				{
					seriesName: 'Plano restrito',
					show: false,
					labels: {
						style: {
							colors: '#A3AED0',
						},
						formatter: function (value) {
							return `${value?.toLocaleString('pt-BR', {
								minimumFractionDigits: 0,
								maximumFractionDigits: 3,
							})}`;
						},
					},
					tickAmount: 4,
					max: 0,
					min: 0,
				},
				{
					seriesName: 'Plano irrestrito / Orçamento',
					opposite: true,
					axisTicks: {
						show: true,
					},
					axisBorder: {
						show: true,
						color: '#A3AED0',
					},
					labels: {
						style: {
							colors: '#A3AED0',
						},
						formatter: function (value) {
							return `${value?.toLocaleString('pt-BR')}%`;
						},
						offsetX: -25,
					},
					tickAmount: 4,
					max: 100,
					min: 0,
				},
				{
					seriesName: 'Plano restrito / Orçamento',
					show: false,
					labels: {
						style: {
							colors: '#A3AED0',
						},
						formatter: function (value) {
							return `${value?.toLocaleString('pt-BR')}%`;
						},
					},
					tickAmount: 4,
					max: 100,
					min: 0,
				},
			],
			legend: {
				show: true,
				ontSize: '12px',
				fontFamily: undefined,
				fontWeight: 400,
				labels: {
					colors: '#A3AED0',
				},
				markers: {
					width: [12, 12, 12, 12, 15, 15],
					height: [12, 12, 12, 12, 2, 2],
				},
				itemMargin: {
					horizontal: 15,
					vertical: 10,
				},
				offsetY: 10,
			},
			grid: {
				padding: {
					left: 10,
					right: 10,
				},
				strokeDashArray: 5,
				yaxis: {
					lines: {
						show: false,
					},
				},
				xaxis: {
					lines: {
						show: false,
					},
				},
			},
			plotOptions: {
				bar: {
					borderRadius: 4,
					borderRadiusApplication: 'end',
				},
			},
			colors: [
				'#567EB3',
				'#A3AED0',
				'#314866',
				'#F0243E',
				'#25364D',
				'#B01A2E',
			],
			noData: {
				text: 'Carregando...',
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: undefined,
					fontSize: '20px',
					fontFamily: undefined,
				},
			},
		});

	// ("Renderização")Gráfico: Projeções de Quantidade
	useEffect(() => {
		if (DemandDashboardData?.length > 0 && monthDataArray?.length > 0) {
			const mapPropertyToNumberArray = (property) => {
				return DemandDashboardData?.map((demand) => {
					const value = demand[property]
						? Number(demand[property]).toFixed(2)
						: 0;
					return value;
				});
			};

			const salesQuantityHistoricDataArray = mapPropertyToNumberArray(
				'vendasQuantidadeHistorico',
			);
			const budgetQuantityDataArray = mapPropertyToNumberArray(
				'orcamentoQuantidade',
			);
			const irrestrictQuantityDataArray = mapPropertyToNumberArray(
				'planoIrrestritoQuantidade',
			);
			const restrictQuantityDataArray = mapPropertyToNumberArray(
				'planoRestritoQuantidade',
			);

			setQuantityDataTable([
				{
					lineName: 'Mesmo mês ano anterior',
					values: [...salesQuantityHistoricDataArray],
				},
				{
					lineName: 'Orçamento',
					values: [...budgetQuantityDataArray],
				},
				{
					lineName: 'Plano Irrestrito',
					values: [...irrestrictQuantityDataArray],
				},
				{
					lineName: 'Plano Restrito',
					values: [...restrictQuantityDataArray],
				},
			]);

			const irrestrictPlanOnBudgetQuantityPercentageDataArray =
				mapPropertyToNumberArray(
					'planoIrrestritoSobOrcamentoQuantidadePercentual',
				);

			const restrictPlanOnBudgetQuantityPercentageDataArray =
				mapPropertyToNumberArray(
					'planoRestritoSobOrcamentoQuantidadePercentual',
				);

			const maxValuesQuantityFirstAxis = [
				Math.max(...salesQuantityHistoricDataArray),
				Math.max(...budgetQuantityDataArray),
				Math.max(...irrestrictQuantityDataArray),
				Math.max(...restrictQuantityDataArray),
			];

			const maxValueQuantityFirstAxis = Math.max(
				...maxValuesQuantityFirstAxis,
			);

			const maxValuesQuantitySecondAxis = [
				Math.max(...irrestrictPlanOnBudgetQuantityPercentageDataArray),
				Math.max(...restrictPlanOnBudgetQuantityPercentageDataArray),
			];

			const maxValueQuantitySecondAxis = Math.max(
				...maxValuesQuantitySecondAxis,
			);

			const newLineBarChartQuantity = [
				{
					name: 'Mesmo mês ano anterior',
					type: 'bar',
					data: salesQuantityHistoricDataArray,
				},
				{
					name: 'Orçamento',
					type: 'bar',
					data: budgetQuantityDataArray,
				},
				{
					name: 'Plano irrestrito',
					type: 'bar',
					data: irrestrictQuantityDataArray,
				},
				{
					name: 'Plano restrito',
					type: 'bar',
					data: restrictQuantityDataArray,
				},
				{
					name: 'Plano irrestrito / Orçamento',
					type: 'line',
					data: irrestrictPlanOnBudgetQuantityPercentageDataArray,
				},
				{
					name: 'Plano restrito / Orçamento',
					type: 'line',
					data: restrictPlanOnBudgetQuantityPercentageDataArray,
				},
			];

			const newYAxis = [...lineBarChartOptionsQuantity.yaxis];

			newYAxis[0] = {
				...newYAxis[0],
				max: maxValueQuantityFirstAxis,
			};

			newYAxis[1] = {
				...newYAxis[1],
				max: maxValueQuantityFirstAxis,
			};

			newYAxis[2] = {
				...newYAxis[2],
				max: maxValueQuantityFirstAxis,
			};

			newYAxis[3] = {
				...newYAxis[3],
				max: maxValueQuantityFirstAxis,
			};

			newYAxis[4] = {
				...newYAxis[4],
				max: maxValueQuantitySecondAxis,
			};

			newYAxis[5] = {
				...newYAxis[5],
				max: maxValueQuantitySecondAxis,
			};

			const emptyArray = existData();
			setLineBarChartDataQuantity([]);
			if (emptyArray.length === 0) {
				setLineBarChartOptionsQuantity({
					...lineBarChartOptionsQuantity,
					noData: {
						text: 'Não há nenhum dado para ser exibido com essa seleção de filtros.',
						align: 'center',
						verticalAlign: 'middle',
						offsetX: 0,
						offsetY: 0,
						style: {
							color: undefined,
							fontSize: '20px',
							fontFamily: undefined,
						},
					},
				});
			} else {
				setLineBarChartOptionsQuantity({
					...lineBarChartOptionsQuantity,
					xaxis: {
						...lineBarChartOptionsQuantity.xaxis,
						categories: monthDataArray,
						type: 'datetime',
						labels: {
							style: {
								colors: '#A3AED0',
								fontSize: '12px',
								fontWeight: 500,
							},
							format: 'MM/yy',
							showDuplicates: false,
						},
					},
					yaxis: newYAxis,
				});
				setLineBarChartDataQuantity([...newLineBarChartQuantity]);
			}
		}
	}, [DemandDashboardData]);
	// FIM: Gráfico: Projeções de Quantidade

	// INÍCIO: Gráfico: Projeções de Receita
	const [lineBarChartDataRevenue, setLineBarChartDataRevenue] = useState<
		any[]
	>([
		// array vazio, utilizado para inicializar o Gráfico: Projeções de Receita
	]);

	// (Opções de "Configuração")Gráfico: Projeções de Receita
	const [lineBarChartOptionsRevenue, setLineBarChartOptionsRevenue] =
		useState({
			title: {
				text: 'Projeções de Receita',
				offsetX: 50,
				offsetY: -5,
				style: {
					color: '#314866',
					fontSize: '24px',
					fontWeight: 'bold',
					fontFamily: undefined,
				},
			},
			chart: {
				toolbar: {
					show: true,
				},
			},
			tooltip: {
				theme: 'dark',
				x: {
					show: true,
					format: 'MM/yy',
					formatter: undefined,
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: 'smooth',
				show: true,
				width: [4, 4, 4, 4, 2, 2],
				colors: [
					'transparent',
					'transparent',
					'transparent',
					'transparent',
					'#25364D',
					'#B01A2E',
				],
				dashArray: [null, null, null, null, 10, 10],
			},
			xaxis: {
				tickPlacement: 'between',
				type: 'datetime',
				categories: monthDataArray,
				labels: {
					style: {
						colors: '#A3AED0',
						fontSize: '12px',
						fontWeight: 500,
					},
					format: 'MM/yy',
					showDuplicates: false,
				},
				axisBorder: {
					show: false,
				},
			},
			yaxis: [
				{
					seriesName: 'Mesmo mês ano anterior',
					show: false,
					labels: {
						style: {
							colors: '#A3AED0',
						},
						formatter: function (value) {
							return Math.trunc(value).toLocaleString('pt-BR', {
								style: 'currency',
								currency: 'BRL',
								maximumFractionDigits: 0,
							});
						},
						offsetX: -15,
					},
					tickAmount: 4,
					max: 0,
					min: 0,
				},
				{
					seriesName: 'Orçamento',
					axisTicks: {
						show: true,
					},
					axisBorder: {
						show: true,
						color: '#A3AED0',
					},
					labels: {
						style: {
							colors: '#A3AED0',
						},
						formatter: function (value) {
							return Math.trunc(value).toLocaleString('pt-BR', {
								style: 'currency',
								currency: 'BRL',
								maximumFractionDigits: 0,
							});
						},
						offsetX: -15,
					},
					tickAmount: 4,
					max: 0,
					min: 0,
				},
				{
					seriesName: 'Plano irrestrito',
					show: false,
					labels: {
						style: {
							colors: '#A3AED0',
						},
						formatter: function (value) {
							return Math.trunc(value).toLocaleString('pt-BR', {
								style: 'currency',
								currency: 'BRL',
								maximumFractionDigits: 0,
							});
						},
					},
					tickAmount: 4,
					max: 0,
					min: 0,
				},
				{
					seriesName: 'Plano restrito',
					show: false,
					labels: {
						style: {
							colors: '#A3AED0',
						},
						formatter: function (value) {
							return Math.trunc(value).toLocaleString('pt-BR', {
								style: 'currency',
								currency: 'BRL',
								maximumFractionDigits: 0,
							});
						},
					},
					tickAmount: 4,
					max: 0,
					min: 0,
				},
				{
					seriesName: 'Plano irrestrito / Orçamento',
					opposite: true,
					axisTicks: {
						show: true,
					},
					axisBorder: {
						show: true,
						color: '#A3AED0',
					},
					labels: {
						style: {
							colors: '#A3AED0',
						},
						formatter: function (value) {
							return `${value?.toLocaleString('pt-BR')}%`;
						},
						offsetX: -25,
					},
					tickAmount: 4,
					max: 100,
					min: 0,
				},
				{
					seriesName: 'Plano restrito / Orçamento',
					show: false,
					labels: {
						style: {
							colors: '#A3AED0',
						},
						formatter: function (value) {
							return `${value?.toLocaleString('pt-BR')}%`;
						},
					},
					tickAmount: 4,
					max: 100,
					min: 0,
				},
			],
			legend: {
				show: true,
				ontSize: '12px',
				fontFamily: undefined,
				fontWeight: 400,
				labels: {
					colors: '#A3AED0',
				},
				markers: {
					width: [12, 12, 12, 12, 15, 15],
					height: [12, 12, 12, 12, 2, 2],
				},
				itemMargin: {
					horizontal: 15,
					vertical: 10,
				},
				offsetY: 10,
			},
			grid: {
				padding: {
					left: 10,
					right: 10,
				},
				strokeDashArray: 5,
				yaxis: {
					lines: {
						show: false,
					},
				},
				xaxis: {
					lines: {
						show: false,
					},
				},
			},
			plotOptions: {
				bar: {
					borderRadius: 4,
					borderRadiusApplication: 'end',
				},
			},
			colors: [
				'#567EB3',
				'#A3AED0',
				'#314866',
				'#F0243E',
				'#25364D',
				'#B01A2E',
			],
			noData: {
				text: 'Carregando...',

				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: undefined,
					fontSize: '20px',
					fontFamily: undefined,
				},
			},
		});

	// ("Renderização")Gráfico: Projeções de Receita
	useEffect(() => {
		if (DemandDashboardData?.length > 0 && monthDataArray?.length > 0) {
			const mapPropertyToNumberArray = (property) => {
				return DemandDashboardData?.map((demand) => {
					const value = demand[property]
						? Number(demand[property]).toFixed(2)
						: 0;
					return value;
				});
			};

			const salesRevenueHistoricDataArray = mapPropertyToNumberArray(
				'vendasReceitaHistorico',
			);

			const budgetRevenueDataArray =
				mapPropertyToNumberArray('orcamentoReceita');

			const irrestrictRevenueDataArray = mapPropertyToNumberArray(
				'planoIrrestritoReceita',
			);
			const restrictRevenueDataArray = mapPropertyToNumberArray(
				'planoRestritoReceita',
			);

			const irrestrictPlanOnBudgetRevenuePercentageDataArray =
				mapPropertyToNumberArray(
					'planoIrrestritoSobOrcamentoReceitaPercentual',
				);

			const restrictPlanOnBudgetRevenuePercentageDataArray =
				mapPropertyToNumberArray(
					'planoRestritoSobOrcamentoReceitaPercentual',
				);

			setProjectionDataTable([
				{
					lineName: 'Mesmo mês ano anterior',
					values: [...salesRevenueHistoricDataArray],
				},
				{
					lineName: 'Orçamento',
					values: [...budgetRevenueDataArray],
				},
				{
					lineName: 'Plano Irrestrito',
					values: [...irrestrictRevenueDataArray],
				},
				{
					lineName: 'Plano Restrito',
					values: [...restrictRevenueDataArray],
				},
			]);

			const maxValuesRevenueFirstAxis = [
				Math.max(...salesRevenueHistoricDataArray),
				Math.max(...budgetRevenueDataArray),
				Math.max(...irrestrictRevenueDataArray),
				Math.max(...restrictRevenueDataArray),
			];

			const maxValueRevenueFirstAxis = Math.max(
				...maxValuesRevenueFirstAxis,
			);

			const maxValuesRevenueSecondAxis = [
				Math.max(...irrestrictPlanOnBudgetRevenuePercentageDataArray),
				Math.max(...restrictPlanOnBudgetRevenuePercentageDataArray),
			];

			const maxValueRevenueSecondAxis = Math.max(
				...maxValuesRevenueSecondAxis,
			);

			const newLineBarChartRevenue = [
				{
					name: 'Mesmo mês ano anterior',
					type: 'bar',
					data: salesRevenueHistoricDataArray,
				},
				{
					name: 'Orçamento',
					type: 'bar',
					data: budgetRevenueDataArray,
				},
				{
					name: 'Plano irrestrito',
					type: 'bar',
					data: irrestrictRevenueDataArray,
				},
				{
					name: 'Plano restrito',
					type: 'bar',
					data: restrictRevenueDataArray,
				},
				{
					name: 'Plano irrestrito / Orçamento',
					type: 'line',
					data: irrestrictPlanOnBudgetRevenuePercentageDataArray,
				},
				{
					name: 'Plano restrito / Orçamento',
					type: 'line',
					data: restrictPlanOnBudgetRevenuePercentageDataArray,
				},
			];

			const newYAxis = [...lineBarChartOptionsRevenue.yaxis];

			newYAxis[0] = {
				...newYAxis[0],
				max: maxValueRevenueFirstAxis,
			};

			newYAxis[1] = {
				...newYAxis[1],
				max: maxValueRevenueFirstAxis,
			};

			newYAxis[2] = {
				...newYAxis[2],
				max: maxValueRevenueFirstAxis,
			};

			newYAxis[3] = {
				...newYAxis[3],
				max: maxValueRevenueFirstAxis,
			};

			newYAxis[4] = {
				...newYAxis[4],
				max: maxValueRevenueSecondAxis,
			};

			newYAxis[5] = {
				...newYAxis[5],
				max: maxValueRevenueSecondAxis,
			};

			const emptyArray = existData();
			const existDataRevenue = verifyExistDataRevenue();
			setLineBarChartDataRevenue([]);
			if (emptyArray.length === 0) {
				setLineBarChartOptionsRevenue({
					...lineBarChartOptionsRevenue,
					noData: {
						text: 'Não há nenhum dado para ser exibido com essa seleção de filtros.',
						align: 'center',
						verticalAlign: 'middle',
						offsetX: 0,
						offsetY: 0,
						style: {
							color: undefined,
							fontSize: '20px',
							fontFamily: undefined,
						},
					},
				});
			} else if (!existDataRevenue) {
				setLineBarChartOptionsRevenue({
					...lineBarChartOptionsRevenue,
					noData: {
						text: 'Não há preço médio calculado para exibir os dados do gráfico.',
						align: 'center',
						verticalAlign: 'middle',
						offsetX: 0,
						offsetY: 0,
						style: {
							color: undefined,
							fontSize: '20px',
							fontFamily: undefined,
						},
					},
				});
			} else {
				setLineBarChartOptionsRevenue({
					...lineBarChartOptionsRevenue,
					xaxis: {
						...lineBarChartOptionsRevenue.xaxis,
						categories: monthDataArray,
						type: 'datetime',
						labels: {
							style: {
								colors: '#A3AED0',
								fontSize: '12px',
								fontWeight: 500,
							},
							format: 'MM/yy',
							showDuplicates: false,
						},
					},
					yaxis: newYAxis,
				});
				setLineBarChartDataRevenue([...newLineBarChartRevenue]);
			}
		}
	}, [DemandDashboardData]);
	// FIM: Gráfico: Projeções de Receita

	// INÍCIO: Filtros
	const setYearFilter = new Set();

	const yearFilter =
		CycleData &&
		CycleData.filter((cycle) => {
			const duplicatedYear = setYearFilter.has(cycle.ano);
			setYearFilter.add(cycle.ano);
			return !duplicatedYear;
		}).map((cycle) => {
			return (
				<option
					key={cycle.id}
					value={cycle.ano}
				>
					{cycle.ano}
				</option>
			);
		});

	const cycleFilter =
		CycleData &&
		CycleData.filter((cycle) => {
			return cycle.ano == filterInput.year;
		}).map((cycle) => {
			return (
				<option
					key={cycle.id}
					value={cycle.id}
				>
					{cycle.ciclo}
				</option>
			);
		});

	function capitalizeFirstLetter(string: string) {
		const lowerString = string.toLowerCase();
		return string.charAt(0).toUpperCase() + lowerString.slice(1);
	}

	const channelFilter = SalesChannelData?.map((channel) => {
		return (
			<option
				key={channel.id}
				value={channel.id}
			>
				{capitalizeFirstLetter(channel.nome_canal_venda)}
			</option>
		);
	});

	const familyFilter =
		FamilyData &&
		FamilyData.rows &&
		FamilyData.rows?.map((family) => {
			return (
				<option
					key={family.id}
					value={family.id}
				>
					{capitalizeFirstLetter(family.nome_familia)}
				</option>
			);
		});
	// FIM: Filtros

	const existBudget = DemandDashboardData?.filter((budget) => {
		return (
			budget.orcamentoQuantidade !== 0 && budget.orcamentoReceita !== 0
		);
	});

	const existData = () => {
		const DemandDashboardDataArray = DemandDashboardData?.map((data) => {
			return (
				data.orcamentoQuantidade !== 0 ||
				data.planoIrrestritoQuantidade !== 0 ||
				data.planoRestritoQuantidade !== 0 ||
				data.planoIrrestritoSobOrcamentoQuantidadePercentual !== 0 ||
				data.planoRestritoSobOrcamentoQuantidadePercentual !== 0 ||
				data.orcamentoReceita !== 0 ||
				data.planoIrrestritoReceita !== 0 ||
				data.planoRestritoReceita !== 0 ||
				data.planoIrrestritoSobOrcamentoReceitaPercentual !== 0 ||
				data.planoRestritoSobOrcamentoReceitaPercentual !== 0
			);
		});
		const filterDemandDashboardDataArray = DemandDashboardDataArray?.filter(
			(data) => {
				return data === true;
			},
		);
		return filterDemandDashboardDataArray;
	};

	const verifyExistDataRevenue = () => {
		if (DemandDashboardData && DemandDashboardData.length > 0) {
			for (const objects of DemandDashboardData) {
				const properties = Object.keys(objects);

				if (
					properties.some(
						(propriedade) =>
							propriedade.includes('Receita') &&
							objects[propriedade] != 0,
					)
				) {
					//  Se alguma receita for diferente de zero, retorna true e sai da função
					return true;
				}
			}
		}

		return false; // Todas as receitas são zeradas ou não há dados no array
	};

	function renderTable(tipo, data, additionalNumberFormat = {}) {
		if (!data?.length || !tableMonthArray?.length) return null;

		const rows = data?.map((row, index) => {
			const { lineName, values } = row;
			const total = values.reduce(
				(acc, value) => acc + Math.trunc(Number(value)),
				0,
			);

			return (
				<Tr
					data-test={`tr-tabela-projecoes-${tipo}`}
					style={{
						borderBottom: '1.5px solid #E2E8F0',
					}}
				>
					<Td>{lineName}</Td>
					{values.map((value) => (
						<Td data-test={`td-tabela-projecoes-${tipo}`}>
							{numberToLocaleString({
								value: Math.trunc(Number(value)),
								maximumFractionDigits: 0,
								...additionalNumberFormat,
							})}
						</Td>
					))}
					<Td
						data-test={`td-total-tabela-projecoes-${tipo}`}
						style={{
							backgroundColor: '#f6f6f6',
						}}
					>
						{numberToLocaleString({
							value: total,
							maximumFractionDigits: 0,
							...additionalNumberFormat,
						})}
					</Td>
				</Tr>
			);
		});

		return (
			<>
				<br />
				<ScrollBar color='secondary'>
					<Table>
						<Thead
							style={{
								backgroundColor: '#F2F5FA',
							}}
						>
							<Tr>
								{tableMonthArray.map((month) => (
									<Th>
										<span style={{ fontWeight: 'bold' }}>
											{month}
										</span>
									</Th>
								))}
							</Tr>
						</Thead>
						<Tbody>{rows}</Tbody>
					</Table>
				</ScrollBar>
			</>
		);
	}

	return (
		<Flex
			direction={'column'}
			pt={{ base: '130px', md: '80px', xl: '80px' }}
		>
			<Card pt={10}>
				<Flex>
					<FormControl mr={5}>
						<FormLabel
							fontSize={'sm'}
							fontWeight={500}
							color={'easyBLUE.300'}
						>
							Ano
						</FormLabel>
						<Select
							data-test='select-filtrar-ano'
							borderRadius={'16px'}
							name={'year'}
							onChange={(e) =>
								setFilterInput({
									...filterInput,
									year: e.target.value,
								})
							}
							value={filterInput.year}
							color={'easyBLUE.300'}
						>
							<option
								value=''
								disabled
							>
								Selecione um Ano
							</option>
							<option value=''></option>
							{yearFilter}
						</Select>
					</FormControl>
					<FormControl mr={5}>
						<FormLabel
							fontSize={'sm'}
							fontWeight={500}
							color={'easyBLUE.300'}
						>
							Ciclo
						</FormLabel>
						<Select
							data-test='select-filtrar-ciclo'
							borderRadius={'16px'}
							name={'cycleId'}
							onChange={(e) =>
								setFilterInput({
									...filterInput,
									cycleId: e.target.value,
									salesChannelId: 0,
									familyId: 0,
								})
							}
							value={filterInput.cycleId}
							color={'easyBLUE.300'}
						>
							<option
								value=''
								disabled
							>
								Selecione um Ciclo
							</option>
							<option value=''></option>
							{cycleFilter}
						</Select>
					</FormControl>
					{/* <FormControl mr={5}>
						<FormLabel
							fontSize={'sm'}
							fontWeight={500}
							color={'easyBLUE.300'}
						>
							Canal de Venda
						</FormLabel>
						<Select
							borderRadius={'16px'}
							name={'channel'}
							onChange={e =>
								setFilterInput({
									...filterInput,
									salesChannelId: Number(e.target.value),
								})
							}
							value={filterInput.salesChannelId}
							color={'easyBLUE.300'}
						>
							<option
								value={0}
								disabled
							>
								Selecione um Canal de Venda
							</option>
							<option value=''></option>
							{channelFilter}
						</Select>
					</FormControl> */}
					<FormControl mr={5}>
						<FormLabel
							fontSize={'sm'}
							fontWeight={500}
							color={'easyBLUE.300'}
						>
							Família
						</FormLabel>
						<Select
							data-test='select-filtrar-familia'
							borderRadius={'16px'}
							name={'channel'}
							onChange={(e) =>
								setFilterInput({
									...filterInput,
									familyId: Number(e.target.value),
								})
							}
							value={filterInput.familyId}
							color={'easyBLUE.300'}
						>
							<option
								value={0}
								disabled
							>
								Selecione uma Família
							</option>
							<option value=''></option>
							{familyFilter}
						</Select>
					</FormControl>
					{/* INÍCIO: Button */}
					<Flex
						justifyContent='end'
						mt={'44px'}
						my={7}
					>
						<ButtonGroup spacing={'24px'}>
							<ButtonComponent
								data-test='button-ver-plano_irrestrito'
								type={'ghost'}
								title={'Ver Plano Irrestrito'}
								onClick={onOpen}
							/>
						</ButtonGroup>
					</Flex>
					{/* FINAL: Button */}
				</Flex>
				<Box>
					{/* Renderiza uma mensagem de orçamento zerado */}
					{existBudget?.length === 0 && (
						<AlertComponent
							element={'dash'}
							title={'Atenção'}
							status={'error'}
							description={
								'Você não possui orçamento cadastrado para o canal ou família selecionados! Por favor, cadastre um orçamento para estes itens!'
							}
						/>
					)}
				</Box>
			</Card>
			{/* INÍCIO: Modal */}
			<UnrestrictedPlanModal
				isOpen={isOpen}
				onClose={onClose}
				cycleId={filterInput.cycleId}
			/>
			{/* FINAL: Modal */}
			{/* CARD: Quantidade total do Ciclo Ano */}
			<Flex mt={'1em'}>
				<Card
					maxWidth={'100%'}
					align={'center'}
					justifyContent={'center'}
					my={'.5em'}
					overflow={'hidden'}
					borderRadius={'2em'}
				>
					{DemandDashboardData?.length > 0 ? (
						<LineBarChart
							chartData={lineBarChartDataQuantity}
							chartOptions={lineBarChartOptionsQuantity}
						/>
					) : DemandDashboardData === undefined ? (
						<Box>
							<Text>Carregando...</Text>
						</Box>
					) : (
						<Box>
							<Text>
								Não existe Colaboração, Consenso e Rateio para
								este ciclo. Por favor, revise seu rateio e faça
								as colaborações e consenso do ciclo selecionado!
							</Text>
						</Box>
					)}
					{renderTable('quantidade', quantityDataTable)}
				</Card>
				<Spacer />
			</Flex>
			<Flex my={'1em'}>
				<Card
					maxWidth={'100%'}
					align={'center'}
					justifyContent={'center'}
					my={'.5em'}
					overflow={'hidden'}
					borderRadius={'2em'}
				>
					{DemandDashboardData?.length > 0 ? (
						<LineBarChart
							chartData={lineBarChartDataRevenue}
							chartOptions={lineBarChartOptionsRevenue}
						/>
					) : DemandDashboardData === undefined ? (
						<Box>
							<Text>Carregando...</Text>
						</Box>
					) : (
						<Box>
							<Text>
								Não existe Colaboração, Consenso e Rateio para
								este ciclo. Por favor, revise seu rateio e faça
								as colaborações e consenso do ciclo selecionado!
							</Text>
						</Box>
					)}

					{renderTable('receita', projectionDataTable, {
						style: 'currency',
						currency: 'BRL',
					})}
				</Card>
				<Spacer />
			</Flex>
		</Flex>
	);
}
