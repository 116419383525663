import { Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import { useContext, useEffect } from 'react';
import FormCadastrarEditarClienteRelacionamento from '../../../../../components/forms/FormCadastrarEditarClienteRelacionamento';

import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

export default function CadastrarClientesRelacionamento() {
	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const initialState = {
		id: '',
		cliente_relacionado_id: '',
		autoRelationShip: false,
	};

	const initialStateModal = {
		razao_social: null,
		documento: null,
		tipo_documento: null,
	};

	useEffect(() => {
		const helpCadastrarClienteRelacionamento: SidebarHelp = {
			title: 'Cadastrar Cliente Relacionamento',
			firstParagraph:
				'Para cadastrar um novo cliente de relacionamento entre com os dados de Razão Social, CNPJ/CPF e Grupo de Relacionamento. É possível criar um relacionamento, clicando no botão Adicionar.',
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		};

		setDataSidebar(helpCadastrarClienteRelacionamento);
	}, [setDataSidebar]);

	return (
		<Flex pt={{ sm: '125px', lg: '75px' }}>
			<Card px='form-cadastro'>
				<FormCadastrarEditarClienteRelacionamento
					initialState={initialState}
					initialStateModal={initialStateModal}
				/>
			</Card>
		</Flex>
	);
}
