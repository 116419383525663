// configuração de APIs de comunicação
// Importação de Axios, ferramenta necessária para requisições no front
import axios from 'axios';
import customToast from 'components/toast/customToatsStandalone';
import getErrorAction from './variables/getErrorAction';
import { environmentVars } from './variables/environment';
// Criar uma configuração base para acessar a mesma url em cada requisição / Configuração padrão do AXIOS com a propriedade
// baseURL já atribuída ao servidor da API
const api = axios.create({
	baseURL: environmentVars.core.api,
	headers: {
		'Access-Control-Allow-Origin': '*',
	},
});

// 🚧 Interceptor para pegar o erro de autenticação quando a sessão expirar e redirecionar para a página de login
api.interceptors.response.use(
	function (response) {
		// Faça algo com os dados de resposta
		return response;
	},
	function (error) {
		// Faça algo com erro de resposta
		let errorData;
		let statusCode;
		let errorMessage;
		if (error.response) {
			errorData = error.response.data[0];
			statusCode = errorData?.statusCode;
			errorMessage = errorData?.message;
		} else {
			if (
				error.code === 'ERR_NETWORK' &&
				error.config.baseURL.includes('permissoes')
			) {
				errorData = { ...error };
				statusCode = error.code;
				errorMessage = error.message;
			}
		}
		const errorAction = getErrorAction(statusCode, errorMessage);
		if (errorAction) {
			localStorage.clear();
			window.location.href = errorAction.redirectUrl;
			customToast(errorAction.toast);
		}
		return Promise.reject(error);
	},
);

export default api;
