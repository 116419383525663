import { ButtonGroup, Flex, useDisclosure } from '@chakra-ui/react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import Card from 'components/card/Card';
import CheckTable from 'components/dataTables/CheckTable';
import { columnsDataEstoqueAtual } from './variables/columnsDataEstoqueAtual';
import { FileUploadModal } from 'components/modal/fileUpload/FileUploadModal';
import moment from 'moment';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDownloadTemplateActualStock } from 'services/queryClient/wrapperHooks/uploads/useDownloadTemplateActualStock';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useHistory } from 'react-router-dom';
import { useUploadActualStock } from 'services/queryClient/wrapperHooks/uploads/useUploadActualStock';
import { formatStringoToDateAndTime } from 'utils/functions/dateUtils';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

interface ActualStock {
	id: number;
	nome_familia: string;
	codigo_produto: string;
	nome_produto: string;
	data: string;
	quantidade: number;
	data_registro: string;
}

export default function EstoqueAtual() {
	const { isOpen, onOpen, onClose } = useDisclosure();

	let history = useHistory();

	const { pathCompanyName } = useGlobalContext();

	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const tableColumns = useMemo(() => columnsDataEstoqueAtual, []);

	const [hasBaseData, setHasBaseData] = useState<boolean>(false);

	const helpEstoque: SidebarHelp = useMemo(
		() => ({
			title: 'Estoque Atual',
			firstParagraph: `O estoque atual aponta a quantidade de produtos em estoque na data específica. É importante atualizar o estoque atual diariamente para mantê-lo sempre atualizado.`,
			secondParagraph: `Acesse o botão Cadastrar Estoque para adicionar novos estoques. Você também pode editar o estoque atual de algum produto através do ícone "editar" ou até mesmo deletar clicando no ícone "lixeira".`,
			thirdParagraph: `Acesse Orientação de Estoque para saber qual o estoque sugerido pela easy360 para cada família de produtos. Essa orientação é com base em histórico de vendas e estatísticas.`,
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	const dataDelete = useMemo(
		() => ({
			provider: 'actualStock',
			toastSuccessTitle: 'Excluido com sucesso!',
			toastSuccessDescription: 'Estoque excluido com sucesso.',
			toastErrorTitle: 'Erro ao excluir estoque!',
			title: 'Deseja realmente excluir este Estoque?',
			text: [
				'Você está prestes a excluir este estoque da sua base de cadastros.',
				'',
				'Você tem certeza que deseja excluir?',
			],
			invalidate: 'estoque-list',
		}),
		[],
	);

	const deleteMessage = useMemo(
		() => [
			'Você está prestes a excluir estes estoques da sua base de cadastros.',
			'',
		],
		[],
	);

	const dataDeleteAll = useMemo(
		() => ({
			provider: 'actualStock',
			toastSuccessTitle: 'Registros excluídos com sucesso!',
			toastSuccessDescription: 'Os estoques foram excluídos com sucesso',
			toastErrorTitle: 'Erro ao excluir estoques!',
			invalidate: 'estoque-list',
		}),
		[],
	);

	const goToCreateAdviceStock = useCallback(() => {
		history.push(`/${pathCompanyName}/analiseCapacidade/estoqueDesejado`);
	}, [history, pathCompanyName]);

	const goToCadastroActualStock = useCallback(() => {
		history.push(`/${pathCompanyName}/analiseCapacidade/estoque/criar`);
	}, [history, pathCompanyName]);

	const renderRow = useCallback((actualStock: ActualStock) => {
		return {
			id: actualStock.id,
			data: moment(actualStock?.data, 'YYYY/MM/DD').format('DD/MM/YYYY'),
			codigo_produto: actualStock.codigo_produto,
			nome_produto: actualStock.nome_produto,
			nome_familia: actualStock.nome_familia,
			quantidade: actualStock?.quantidade?.toLocaleString('pt-BR'),
			dataRegistro: formatStringoToDateAndTime(
				actualStock?.data_registro,
			),
		};
	}, []);

	const renderButtons = useCallback(() => {
		return (
			<Flex
				flexDirection={'row'}
				justifyContent={'flex-end'}
				mt={'20px'}
				me={'20px'}
				mb={'40px'}
			>
				<ButtonGroup spacing={'20px'}>
					<ButtonComponent
						data-test={'button-importar-estoque_atual'}
						type={'ghost'}
						title={'Importar Estoque'}
						onClick={onOpen}
					/>
					<ButtonComponent
						type={'ghost'}
						title={'Orientação de Estoque'}
						onClick={goToCreateAdviceStock}
					/>
					<FileUploadModal
						isOpen={isOpen}
						onClose={onClose}
						headerText={'Importar Estoque'}
						module={'estoqueAtual'}
						useDownloadTemplate={useDownloadTemplateActualStock}
						stringInvalidQuery='estoque-list'
						useUpload={useUploadActualStock}
						showLineRestricts={true}
						columnsData={tableColumns}
						hasBaseData={hasBaseData}
					/>
					<ButtonComponent
						data-test={'button-cadastrar-estoque_atual'}
						type={'primary'}
						title={'Cadastrar Estoque'}
						onClick={goToCadastroActualStock}
					/>
				</ButtonGroup>
			</Flex>
		);
	}, [
		goToCadastroActualStock,
		goToCreateAdviceStock,
		hasBaseData,
		isOpen,
		onClose,
		onOpen,
		tableColumns,
	]);

	function renderTable() {
		return (
			<CheckTable
				dataTest='button-filtrar-estoque_atual'
				module={'estoqueAtual'}
				linkEditForm={`/${pathCompanyName}/analiseCapacidade/estoque/editar`}
				columnsData={tableColumns}
				dataDelete={dataDelete}
				deleteMessage={deleteMessage}
				showGenericSearchInput={false}
				filterModule='estoque'
				bulkDeleteProps={dataDeleteAll}
				renderRow={renderRow}
				setHasBaseData={setHasBaseData}
				queriesToInvalidadeOnRemove={[
					['production-dashboard-data'],
					['production-dashboard-data-by-family-stock'],
					['production-dashboard-filter'],
				]}
				resetQuery={true}
			/>
		);
	}

	useEffect(() => {
		setDataSidebar(helpEstoque);
	}, [helpEstoque, setDataSidebar]);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				{renderButtons()}
				{renderTable()}
			</Card>
		</Flex>
	);
}
