import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import { useContext, useEffect } from 'react';
import TemplateCadastrarColabCons from '../templateCadastrarColabCons/cadastrar';
import ProviderColaboracaoConsenso from '../templateCadastrarColabCons/context';
import SectionConsenso from './components/SectionConsensus';
import ProviderConsenso from './context';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

export default function CadastrarConsenso() {
	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	useEffect(() => {
		const helpCadastrarConsenso: SidebarHelp = {
			title: 'Consenso',
			firstParagraph: `Para o ciclo atual, em conjunto com todos os especialistas envolvidos, deve ser definido a demanda em quantidade para todas as famílias de produto em seus respectivos canais de vendas.`,
			secondParagraph: `Neste dashboard pode ser analisado informações de histórico de vendas, valores quantitativos de ciclos anteriores, oportunidades, exceções (ou seja, eventos), estatísticas, bem como as demandas informadas por cada especialista, para auxiliar no processo da previsão da demanda.`,
			thirdParagraph: `Para completar o consenso de cada canal, navegue até o menu lateral esquerdo e clique em uma família por vez. Para cada família selecionada, preencha os campos de cada mês futuro com a demanda em quantidade. Clique em “Salvar”. Ao salvar com sucesso, aparecerá ao lado da família em que foi realizado o consenso.`,
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		};

		setDataSidebar(helpCadastrarConsenso);
	}, []);

	return (
		<>
			<ProviderConsenso>
				<ProviderColaboracaoConsenso page='consenso'>
					<TemplateCadastrarColabCons
						SectionColabCons={SectionConsenso}
						page='consenso'
					/>
				</ProviderColaboracaoConsenso>
			</ProviderConsenso>
		</>
	);
}
