interface Toast {
	title: string;
	description: string;
	status: 'error' | 'success' | 'warning' | 'info';
	duration: number;
	isClosable: boolean;
}
interface ErrorMapping {
	message: string;
	redirectUrl: string;
	toast: Toast;
}
interface ErrorMappings {
	[statusCode: number | string]: ErrorMapping;
}
interface ErrorAction {
	message: string;
	redirectUrl: string;
	toast: Toast;
}
const isWithinMaintenanceWindow = (): boolean => {
	const now = new Date();
	const hours = now.getHours();
	const minutes = now.getMinutes();
	return (
		(hours === 0 && minutes >= 30) ||
		(hours > 0 && hours < 5) ||
		(hours === 5 && minutes <= 30)
	);
};
export const errorMappings: ErrorMappings = {
	401: {
		message: 'Unauthorized',
		redirectUrl: '/auth/login',
		toast: {
			title: 'Sessão expirada',
			description:
				'Faça login novamente para continuar usando a Easy360.',
			status: 'error',
			duration: 8000,
			isClosable: true,
		},
	},
	ERR_NETWORK: {
		message: 'Sistema indisponível!',
		redirectUrl: isWithinMaintenanceWindow() ? '/offline' : '',
		toast: {
			title: 'Sistema indisponível',
			description:
				'Estado de hibernação ativo. Nos contato através do e-mail contato@easy360.ind.br.',
			status: 'error',
			duration: 8000,
			isClosable: true,
		},
	},
};
const getErrorAction = (
	statusCode: number,
	errorMessage: string,
): ErrorAction | null => {
	const response = errorMappings[statusCode] ?? null;
	return response;
};

export default getErrorAction;
