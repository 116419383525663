// Chakra imports
import { Box, Portal, useDisclosure } from '@chakra-ui/react';
// Layout components
import { useState } from 'react';
import Navbar from '../../../components/navbar/NavbarAdmin';
import Sidebar from '../../../components/sidebar/Sidebar';

import 'mapbox-gl/dist/mapbox-gl.css';

import { useGlobalContext } from 'contexts/GlobalContext';
import { NavbarContext } from 'contexts/NavbarContext';
import SidebarHelpProvider from 'contexts/providers/SidebarHelpProvider';
import { SidebarContext } from 'contexts/SidebarContext';
import 'mapbox-gl/dist/mapbox-gl.css';
// Custom Chakra theme
export default function Default(props) {
	const { ...rest } = props;
	const { routes } = useGlobalContext();

	// states and functions
	const [fixed] = useState(false);
	const [toggleSidebar, setToggleSidebar] = useState(false);

	const [sidebarState, setSidebarState] = useState('opened');
	const [sidebarHelpState, setSidebarHelpState] = useState('closed');

	function sidebarsSize() {
		if (sidebarState === 'closed') {
			return {
				base: '100%',
				xl: 'calc( 100% - 100px )',
				sm: 'calc( 100% - 100px )',
			};
		} else {
			return {
				base: '100%',
				xl: 'calc( 100% - 314px )',
				sm: 'calc( 100% - 304px )',
			};
		}
	}
	document.documentElement.dir = 'ltr';

	const { onOpen } = useDisclosure();
	const route = props.route;
	return (
		<Box
		//>>>DESCOMENTAR
		// display={'flex'}
		>
			<NavbarContext.Provider
				value={{ sidebarHelpState, setSidebarHelpState }}
			>
				<SidebarContext.Provider
					value={{
						toggleSidebar,
						setToggleSidebar,
					}}
				>
					<SidebarHelpProvider>
						{/*MENU*/}
						<Sidebar
							toggleSidebar={toggleSidebar}
							setToggleSidebar={setToggleSidebar}
							//>>>
							sidebarState={sidebarState}
							setSidebarState={setSidebarState}
							sidebarHelpState={sidebarHelpState}
							routes={routes}
							display='none'
							{...rest}
						/>
						{/* <Box > */}

						{/* </Box> */}
						{/* TABELA */}
						<Box
							float='right'
							minHeight='100vh'
							height='100%'
							overflow='auto'
							position='relative'
							// position='fixed'
							maxHeight='100%'
							//IMPLEMENTAÇÃO DO HELP-SIDEBAR
							w={sidebarsSize}
							mr={'10px'}
							transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
							transitionDuration='.2s, .2s, .35s'
							transitionProperty='top, bottom, width'
							transitionTimingFunction='linear, linear, ease'
						>
							<Portal>
								<Box>
									<Navbar
										sidebarState={sidebarState}
										sidebarHelpState={sidebarHelpState}
										setSidebarHelpState={
											setSidebarHelpState
										}
										onOpen={onOpen}
										// logoText={"Horizon UI Dashboard PRO"}
										brandText={route.name}
										brandAlternativeText={
											route.alternativeName
										}
										// secondary={getActiveNavbar(routes)}
										fixed={fixed}
										routes={routes}
										activeRoute={route}
										{...rest}
									/>
								</Box>
							</Portal>
							<Box
								mx='auto'
								p={{ base: '20px', md: '30px' }}
								pe='20px'
								minH='100vh'
								pt='50px'
							>
								{props.children}
							</Box>
						</Box>
					</SidebarHelpProvider>
				</SidebarContext.Provider>
			</NavbarContext.Provider>
		</Box>
	);
}
