import { Flex } from '@chakra-ui/react';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useContext, useEffect, useMemo } from 'react';
import Card from 'components/card/Card';
import FormCadastrarEditarPrecoMedio from 'components/forms/FormCadastrarEditarPrecoMedio';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

export default function CadastrarPrecoMedio() {
	const { setters } = useContext(SidebarHelpContext);
	const { setDataSidebar } = setters;

	const helpCadastroPrecoMedio = useMemo(
		(): SidebarHelp => ({
			title: 'Cadastrar Preço Médio',
			firstParagraph:
				'Selecione a família a que o produto pertence. Você verá uma lista com todas as famílias disponíveis. Após selecionar a família, aparecerá uma lista de produtos correspondentes. Selecione o produto desejado. Indique o canal de venda para o qual deseja cadastrar o preço médio. Isso garante que cada canal tenha o valor adequado. Informe o Valor do Preço Médio. Digite o valor desejado para o preço médio do produto no canal selecionado. Certifique-se de inserir um valor válido e positivo. Clique em "Salvar".',
			secondParagraph:
				'Observação: Produtos podem ter preços médios diferentes para cada canal de venda. Se já existir um preço médio para o canal selecionado, será possível editá-lo ou substituí-lo.',
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpCadastroPrecoMedio);
	}, [helpCadastroPrecoMedio, setDataSidebar]);

	const formInitialState = {
		familia_id: null,
		produto_id: null,
		canal_venda_id: null,
		ticket_medio_editado: null,
	};

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				<FormCadastrarEditarPrecoMedio
					initialState={formInitialState}
				/>
			</Card>
		</Flex>
	);
}
