import { Flex } from '@chakra-ui/react';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useContext, useEffect, useMemo } from 'react';
import Card from 'components/card/Card';
import FormCadastrarEditarOrcamento from 'components/forms/FormCadastrarEditarOrcamento';
import moment from 'moment';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

export default function CadastrarOrcamento() {
	const { setters } = useContext(SidebarHelpContext);
	const { setDataSidebar } = setters;

	const helpCadastroOrcamento = useMemo(
		(): SidebarHelp => ({
			title: 'Cadastrar Orçamento',
			firstParagraph:
				'Para cadastrar um novo orçamento, selecione a Competência desejada e entre com os dados do Canal de Venda, Família de produto, quantidade e receita bruta.',
			secondParagraph:
				'Para cadastrar um orçamento, cadastre os canais de venda previamente em Cadastros > Comercial > Hierarquia, bem como as famílias de produtos em Cadastros > Produtos > Família.',
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpCadastroOrcamento);
	}, [helpCadastroOrcamento, setDataSidebar]);

	const formInitialState = {
		mes_ano: moment().format('YYYY-MM'),
		canal_venda_id: null,
		familia_id: null,
		quantidade: null,
		receita_bruta: null,
	};

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				<FormCadastrarEditarOrcamento initialState={formInitialState} />
			</Card>
		</Flex>
	);
}
