import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { ButtonGroup, Flex, useDisclosure } from '@chakra-ui/react';
import Card from 'components/card/Card';
import CheckTable from 'components/dataTables/CheckTable';
import { columnsDataOportunidades } from './variables/columnsDataOportunidades';
import {
	documentFormatters,
	DocumentType,
} from 'utils/functions/formatDocuments';
import { FileUploadModal } from 'components/modal/fileUpload/FileUploadModal';
import moment from 'moment';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDownloadTemplateOpportunity } from 'services/queryClient/wrapperHooks/uploads/useDownloadTemplateOppotunity';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useHistory } from 'react-router-dom';
import { useUploadOpportunity } from 'services/queryClient/wrapperHooks/uploads/useUploadOpportunity';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

interface Opportunity {
	id: number;
	codigo: string;
	sequencia_codigo: number;
	data: string;
	mes_ano_ciclo: string;
	nome_cliente: string;
	cpf: string;
	cnpj: string;
	nome_familia: string;
	nome_produto: string;
	codigo_produto: string;
	quantidade: number;
	nome_membro: string;
	receita_bruta: number;
	nome_canal_venda: string;
	data_registro: string;
	tipo_documento: DocumentType;
	documento: string;
}

export default function CadastroOportunidades() {
	const { isOpen, onOpen, onClose } = useDisclosure();

	let history = useHistory();

	const { pathCompanyName } = useGlobalContext();

	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const tableColumns = useMemo(() => columnsDataOportunidades, []);

	const [hasBaseData, setHasBaseData] = useState<boolean>(false);

	const helpOportunidades: SidebarHelp = useMemo(
		() => ({
			title: 'Oportunidades',
			firstParagraph:
				'Nesta tela é possível visualizar todas as oportunidades de negócios no tempo da sua indústria. Estas oportunidades, geralmente, vêm do seu CRM em estágio mais avançado de negociação.',
			secondParagraph:
				'Acesse o botão “Cadastrar Oportunidades” para adicionar uma nova oportunidade ou “Importar Oportunidades” para subir grande quantidade de dados.',
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	const dataDelete = useMemo(
		() => ({
			provider: 'opportunity',
			toastSuccessTitle: 'Excluida com sucesso!',
			toastSuccessDescription: 'A oportunidade foi excluida com sucesso.',
			toastErrorTitle: 'Erro ao excluir oportunidade!',
			title: 'Deseja realmente excluir esta oportunidade?',
			text: [
				'Você está prestes a excluir esta oportunidade da sua base de cadastros. Excluir esta oportunidade resultará na remoção dos seguintes itens vinculados a ela:',
				'REPLACE_ME',
				'Você tem certeza que deseja excluir?',
			],
			invalidate: 'oportunidade-list',
		}),
		[],
	);

	const deleteMessage = useMemo(
		() => [
			'Você está prestes a excluir estas oportunidades da sua base de cadastros. Excluir estas oportunidades resultará na remoção dos seguintes itens vinculados a ela:',
			'REPLACE_ME',
		],
		[],
	);

	const dataDeleteAll = useMemo(
		() => ({
			provider: 'opportunity',
			toastSuccessTitle: 'Registros excluídos com sucesso!',
			toastSuccessDescription:
				'As oportunidades foram excluídas com sucesso',
			toastErrorTitle: 'Erro ao excluir oportunidades!',
			invalidate: 'oportunidade-list',
		}),
		[],
	);

	const goToCadastroOportunidades = useCallback(() => {
		history.push(`/${pathCompanyName}/rotina/oportunidades/criar`);
	}, [history, pathCompanyName]);

	const renderRow = useCallback((opportunity: Opportunity) => {
		return {
			id: opportunity?.id,
			codigoOportunidade: opportunity?.codigo,
			sequenciaCodigoOportunidade: opportunity?.sequencia_codigo,
			competencia: moment(opportunity?.data, 'YYYY/MM/DD')?.format(
				'DD/MM/YYYY',
			),
			cicloAno: opportunity?.mes_ano_ciclo,
			cliente: opportunity?.nome_cliente,
			cnpjCpfId: documentFormatters(
				opportunity?.tipo_documento,
				opportunity?.documento,
			),
			familia: opportunity?.nome_familia || '-',
			produto: opportunity?.nome_produto,
			codigoProduto: opportunity?.codigo_produto,
			quantidade: opportunity?.quantidade?.toLocaleString('pt-BR'),
			membro: opportunity?.nome_membro,
			receitaBruta: opportunity?.receita_bruta
				? opportunity?.receita_bruta?.toLocaleString?.('pt-BR', {
						style: 'currency',
						currency: 'BRL',
				  })
				: 'R$ 0,00',
			canalVenda: opportunity?.nome_canal_venda || '-',
			dataRegistro: opportunity?.data_registro
				? moment
						.utc(opportunity.data_registro)
						.local()
						.format('DD/MM/YYYY HH:mm:ss')
				: '',
		};
	}, []);

	const renderButtons = useCallback(() => {
		return (
			<Flex
				flexDirection={'row'}
				justifyContent={'flex-end'}
				mt={'20px'}
				me={'20px'}
				mb={'40px'}
			>
				<ButtonGroup spacing={'20px'}>
					<ButtonComponent
						data-test={'button-importar-oportunidades'}
						type={'ghost'}
						title={'Importar Oportunidades'}
						onClick={onOpen}
					/>
					<FileUploadModal
						isOpen={isOpen}
						onClose={onClose}
						headerText={'Importar Oportunidades'}
						module={'oportunidades'}
						useDownloadTemplate={useDownloadTemplateOpportunity}
						stringInvalidQuery='oportunidade-list'
						useUpload={useUploadOpportunity}
						showLineRestricts={true}
						columnsData={tableColumns}
						hasBaseData={hasBaseData}
					/>
					<ButtonComponent
						data-test={'button-cadastrar-oportunidades'}
						type={'primary'}
						title={'Cadastrar Oportunidades'}
						onClick={goToCadastroOportunidades}
					/>
				</ButtonGroup>
			</Flex>
		);
	}, [
		goToCadastroOportunidades,
		isOpen,
		onClose,
		onOpen,
		tableColumns,
		hasBaseData,
	]);

	function renderTable() {
		return (
			<CheckTable
				dataTest='button-filtrar-oportunidades'
				module={'oportunidades'}
				linkEditForm={`/${pathCompanyName}/rotina/oportunidades/editar`}
				columnsData={tableColumns}
				dataDelete={dataDelete}
				deleteMessage={deleteMessage}
				showGenericSearchInput={false}
				filterModule='oportunidade'
				bulkDeleteProps={dataDeleteAll}
				renderRow={renderRow}
				setHasBaseData={setHasBaseData}
			/>
		);
	}

	useEffect(() => {
		setDataSidebar(helpOportunidades);
	}, [helpOportunidades, setDataSidebar]);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				{renderButtons()}
				{renderTable()}
			</Card>
		</Flex>
	);
}
