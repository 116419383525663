// Chakra imports
import {
	Flex,
	Text,
	Heading,
	Image,
	useColorModeValue,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { ButtonComponent } from '../../../../components/buttons/ButtonComponent';
import * as global from '../../../../constants/global';

export default function SystemOfflineNotice() {
	const history = useHistory();
	const textColor = useColorModeValue('white', 'easyGRAY.200');
	const redirectToLogin = () => {
		history.push('auth/login');
	};
	return (
		<Flex
			direction='column'
			h='100vh'
			w='100vw'
			align='center'
			justify='flex-start'
			pt={100}
			textAlign='center'
			px={4}
			position='relative'
			bg={useColorModeValue('easyBLUE.600', 'easyBLUE.500')}
			sx={{
				'&::before': {
					content: "''",
					position: 'absolute',
					top: 0,
					left: 0,
					height: '100%',
					width: '100%',
					backgroundImage:
						'url(assets/images/decoratives/data-blocks.svg)',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
				},
			}}
		>
			<Image
				src='assets/images/logos/logo-easy360-white-and-colors.svg'
				alt='Easy360'
				w='250px'
				h='250px'
				mb={6}
			/>
			<Heading
				color={textColor}
				fontSize='4xl'
				fontWeight='700'
				lineHeight='120%'
				mb={6}
			>
				Sistema em estado de hibernação!
			</Heading>
			<Text
				color={textColor}
				fontSize='xl'
				fontWeight='600'
				lineHeight='150%'
			>
				O sistema será reiniciado automaticamente às{' '}
				{global.SYSTEM_RESTART_TIME} horas (horário de São Paulo).
				<br />
				Caso precise de ajuda, nos contate através do e-mail:
				<br />
				<strong>{global.SUPPORT_EMAIL}</strong>
			</Text>
			<ButtonComponent
				type='secondary'
				title='Retornar ao login'
				onClick={redirectToLogin}
				mt={10}
			/>
		</Flex>
	);
}
