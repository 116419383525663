// Chakra imports
import { Flex, Heading, Icon, Image, Text } from '@chakra-ui/react';
import { FaChevronLeft } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import logoEasy360 from '../../../assets/images/logos/logo-easy360-white-and-colors.svg';
import { useAuthenticator } from '@aws-amplify/ui-react';
import FormRegistrarUsuario from 'components/forms/FormRegistrarUsuario';

export default function RegistrarUsuario() {
	const history = useHistory();

	const { user, signOut } = useAuthenticator((context) => [
		context.route,
		context.user,
		context.signOut,
	]);
	const userGroups = user?.getSignInUserSession()?.getIdToken()?.payload?.[
		'cognito:groups'
	];

	const initialState = {
		plano_de_assinatura: '',
		duracao_do_contrato: '',
		cnpj: '',
		razao_social: '',
		nome_fantasia: '',
		telefone: '',
		cep: '',
		estado: '',
		cidade: '',
		bairro: '',
		rua: '',
		numero: '',
		complemento: '',
		nome_do_usuario: '',
		email: '',
		senha: '',
		confirmacao_de_senha: '',
	};

	return (
		<>
			<Flex
				h={'100vh'}
				overflowY={'hidden'}
			>
				<Flex
					flexDirection={'column'}
					background={
						'linear-gradient(135deg, hsla(352, 87%, 54%, 1) 0%, hsla(214, 35%, 30%, 1) 100%)'
					}
					w={'30%'}
					alignItems={'center'}
				>
					<Image
						mt={'20%'}
						src={logoEasy360}
						boxSize='300px'
						alt='Logo easy360'
					/>

					{/* Alternativas de redirecionamento */}
					{userGroups?.includes('vendedor') ? (
						<Flex
							align='center'
							ps={{ base: '25px', lg: '0px' }}
							pt={{ lg: '0px', xl: '0px' }}
							w='fit-content'
							mt={'50%'}
							onClick={() => {
								signOut();
								history.push('/auth/login');
							}}
							_hover={{
								cursor: 'pointer',
							}}
						>
							<Icon
								as={FaChevronLeft}
								me='12px'
								h='13px'
								w='8px'
								color='easyBrandMenu.100'
							/>
							<Text
								ms='0px'
								fontSize='sm'
								color='easyBrandMenu.100'
							>
								Fazer Logout
							</Text>
						</Flex>
					) : (
						<Flex
							align='center'
							ps={{ base: '25px', lg: '0px' }}
							pt={{ lg: '0px', xl: '0px' }}
							w='fit-content'
							mt={'50%'}
							onClick={() => {
								history.push('/auth/login');
							}}
							_hover={{
								cursor: 'pointer',
							}}
						>
							<Icon
								as={FaChevronLeft}
								me='12px'
								h='13px'
								w='8px'
								color='easyBrandMenu.100'
							/>
							<Text
								ms='0px'
								fontSize='sm'
								color='easyBrandMenu.100'
							>
								Acessar Easy360
							</Text>
						</Flex>
					)}
				</Flex>
				<Flex
					flexDirection={'column'}
					w={'70%'}
					p={'4em 8em'}
					h='100vh'
					overflow={'auto'}
				>
					<Heading
						color={'#2B3674'}
						fontWeight='600'
						lineHeight='100%'
						width='100%'
						textAlign='center'
						mt={2}
						// mb={16}
					>
						{/* Olá! Crie sua conta Easy360 */}
						Olá! Crie a conta do usuário Easy360
					</Heading>
					<Text
						color={'green.500'}
						fontWeight={'700'}
						textAlign={'center'}
						p={'.5em'}
						mb={4}
					>
						{/* Experimente por 90 dias grátis */}
						Lembre-se de informar ao usuário que ele receberá um
						email com os dados de acesso após o sucesso do cadastro.
					</Text>

					<FormRegistrarUsuario
						initialState={initialState}
					></FormRegistrarUsuario>
				</Flex>
			</Flex>
		</>
	);
}
