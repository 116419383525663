import { ButtonComponent } from 'components/buttons/ButtonComponent';
import Card from 'components/card/Card';
import CheckTable from 'components/dataTables/CheckTable';
import { columnsDataClientesRelacionamento } from './variables/columnsDataClientesRelacionamento';
import {
	documentFormatters,
	DocumentType,
} from 'utils/functions/formatDocuments';
import { ButtonGroup, Flex, useDisclosure } from '@chakra-ui/react';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useHistory } from 'react-router-dom';
import { FileUploadModal } from 'components/modal/fileUpload/FileUploadModal';
import { useDownloadTemplateRelatedCustomer } from 'services/queryClient/wrapperHooks/uploads/useDownloadTemplateRelatedCustomer';
import { useExportRelatedCustomer } from 'services/queryClient/wrapperHooks/useExportRelatedCustomer';
import { useUploadRelatedCustomer } from 'services/queryClient/wrapperHooks/uploads/useUploadRelatedCustomer';
import { TooltipStandard } from 'components/tooltip/TooltipStandard';
import ButtonExportGenericCSV from 'components/buttons/ButtonExportGenericCSV';
import CheckTableModal from 'components/modal/CheckTableModal';
import moment from 'moment';
import { formatStringoToDateAndTime } from 'utils/functions/dateUtils';

import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

interface RelatedCustomer {
	id: number;
	razao_social: string;
	tipo_documento: DocumentType;
	documento: string;
	cliente_relacionado_nome: string;
	status: boolean;
	data_registro: string;
}

export default function CadastroClientesRelacionamento() {
	const { isOpen, onOpen, onClose } = useDisclosure();

	let history = useHistory();

	const { pathCompanyName } = useGlobalContext();

	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const {
		isOpen: isOpenModalList,
		onOpen: onOpenModalList,
		onClose: onCloseModalList,
	} = useDisclosure();

	const [selectedModuleProps, setSelectedModuleProps] = useState<any>({});

	const tableColumns = useMemo(() => columnsDataClientesRelacionamento, []);

	const [hasBaseData, setHasBaseData] = useState<boolean>(false);

	const helpRelationshipCustomer: SidebarHelp = useMemo(
		() => ({
			title: 'Cliente Relacionamento',
			firstParagraph:
				'Nesta tela é possível visualizar a hierarquia de relacionamento, refletindo as agregações de relacionamento. Deve ser referenciado o cliente (razão social) ao seu grupo de relacionamento (ou cliente relacionamento).',
			secondParagraph:
				'Acesse o botão “Cadastrar Cliente” para adicionar novas hierarquias de relacionamento.',
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	const dataDelete = useMemo(
		() => ({
			provider: 'relatedCustomer',
			toastSuccessTitle: 'Excluido com sucesso!',
			toastSuccessDescription:
				'O cliente relacionamento foi excluido com sucesso.',
			toastErrorTitle: 'Erro ao excluir cliente relacionamento!',
			title: 'Deseja realmente excluir este cliente relacionamento?',
			text: [
				'Você está prestes a excluir este cliente relacionamento da sua base de cadastros. Excluir este cliente relacionamento resultará na remoção dos seguintes itens vinculados a ele:',
				'REPLACE_ME',
				'Você tem certeza que deseja excluir?',
			],
			invalidate: 'clienteRelacionado-list',
		}),
		[],
	);

	const deleteMessage = useMemo(
		() => [
			'Você está prestes a excluir este(s) relacionamento(s) da sua base de cadastros. Excluir este(s) relacionamento(s) resultará na remoção dos seguintes itens vinculados a ele(s):',
			'REPLACE_ME',
		],
		[],
	);

	const dataDeleteAll = useMemo(
		() => ({
			provider: 'relatedCustomer',
			toastSuccessTitle: 'Registros excluídos com sucesso!',
			toastSuccessDescription:
				'Os relacionamentos foram excluídos com sucesso',
			toastErrorTitle: 'Erro ao excluir relacionamentos!',
			invalidate: 'clienteRelacionado-list',
		}),
		[],
	);

	const goToCadastroRelatedCustomer = useCallback(() => {
		history.push(`/${pathCompanyName}/cadastros/clienteRelacionado/criar`);
	}, [history, pathCompanyName]);

	const renderRow = useCallback((relatedCustomer: RelatedCustomer) => {
		return {
			id: relatedCustomer?.id,
			razaoSocial: relatedCustomer?.razao_social,
			cnpjCpfId: documentFormatters(
				relatedCustomer?.tipo_documento,
				relatedCustomer?.documento,
			),
			clienteRelacionado: relatedCustomer?.cliente_relacionado_nome,
			status: relatedCustomer?.status ? 'Ativo' : 'Inativo',
			dataRegistro: formatStringoToDateAndTime(
				relatedCustomer?.data_registro,
			),
		};
	}, []);

	const renderButtons = useCallback((): JSX.Element => {
		return (
			<Flex
				flexDirection={'row'}
				justifyContent={'flex-end'}
				mt={'20px'}
				me={'20px'}
				mb={'40px'}
			>
				<ButtonGroup spacing={'20px'}>
					<ButtonComponent
						data-test='button-importar-cliente_relacionamento'
						type={'ghost'}
						title={'Importar Clientes'}
						onClick={onOpen}
					/>
					<FileUploadModal
						isOpen={isOpen}
						onClose={onClose}
						headerText={'Importar Clientes Relacionados'}
						module={'clienteRelacionado'}
						useDownloadTemplate={useDownloadTemplateRelatedCustomer}
						stringInvalidQuery='cliente-relacionado'
						useUpload={useUploadRelatedCustomer}
						showLineRestricts={true}
						columnsData={tableColumns}
						hasBaseData={hasBaseData}
					/>
					<ButtonComponent
						data-test='button-cadastrar-cliente_relacionamento'
						type={'primary'}
						title={'Cadastrar Clientes'}
						onClick={goToCadastroRelatedCustomer}
					/>
					<TooltipStandard
						label='Exportar hierarquia de relacionamento para CSV'
						shouldWrapChildren={true}
					>
						<ButtonExportGenericCSV
							useExport={useExportRelatedCustomer}
							cycleId={'cliente-relacionado'}
							module={'cliente-relacionado'}
							errorDescription='Não há dados para exportar!'
						/>
					</TooltipStandard>
				</ButtonGroup>
			</Flex>
		);
	}, [
		onOpen,
		isOpen,
		onClose,
		tableColumns,
		hasBaseData,
		goToCadastroRelatedCustomer,
	]);

	function renderTable() {
		return (
			<CheckTable
				dataTest='button-filtrar-cliente_relacionamento'
				module={'clienteRelacionado'}
				linkEditForm={`/${pathCompanyName}/cadastros/clienteRelacionamento/editar`}
				columnsData={tableColumns}
				dataDelete={dataDelete}
				deleteMessage={deleteMessage}
				showGenericSearchInput={false}
				filterModule='clienteRelacionado'
				bulkDeleteProps={dataDeleteAll}
				renderRow={renderRow}
				setHasBaseData={setHasBaseData}
				tableTest='tabela-cliente_relacionamento'
			/>
		);
	}

	useEffect(() => {
		setDataSidebar(helpRelationshipCustomer);
	}, [helpRelationshipCustomer, setDataSidebar]);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				{renderButtons()}
				{renderTable()}
			</Card>
			<CheckTableModal
				filterModule={selectedModuleProps?.filterModule}
				columnsData={selectedModuleProps?.columnsData}
				renderRow={selectedModuleProps?.renderRow}
				isOpen={isOpenModalList}
				onClose={onCloseModalList}
				modalTitle={selectedModuleProps?.modalTitle}
				simpleDelete={selectedModuleProps?.simpleDelete}
				deleteMany={selectedModuleProps?.deleteMany}
				deleteAll={selectedModuleProps?.deleteAll}
				queriesToInvalidadeOnRemove={['clienteRelacionado-list']}
				queriesToInvalidadeOnEdit={['clienteRelacionado-list']}
			/>
		</Flex>
	);
}
