import {
	Box,
	Button,
	ButtonGroup,
	Container,
	Flex,
	FormControl,
	Grid,
	GridItem,
	Image,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalOverlay,
	Radio,
	Select,
	Text,
} from '@chakra-ui/react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';

import Barcode from 'assets/images/icons/icon-bar-code.png';
import Card from 'components/card/Card.js';
import Mastercard from 'components/card/Mastercard';
import { VSeparator } from 'components/separator/Separator';
import { useState } from 'react';
import { useForm } from 'services/hooks/useForm';

export default function DadosPagamento() {
	const { form, onChange } = useForm({
		forma: 'cc',
		number: '',
		cvv: '',
		mounth: '',
		year: '',
	});

	const [isOpen, setIsOpen] = useState(false);

	const [number, setNumber] = useState('XXXX XXXX XXXX XXXX');
	const [cvv, setCvv] = useState('XXX');
	const [expiration, setExpiration] = useState('XX/XX');

	const updateCard = () => {
		const n = form['number'];
		// set all numbers to X except the last 4
		const masked = n.replace(/.(?=.{4})/g, 'X');

		setNumber(masked);
		setCvv(form['cvv']);
		setExpiration(form['mounth'] + '/' + form['year']);
		setIsOpen(false);
	};

	const onClose = () => setIsOpen(false);

	console.log('form', form);
	return (
		<Flex
			direction={{ base: 'column' }}
			pt={{ base: '130px', md: '80px', xl: '80px' }}
			flexWrap='wrap'
		>
			<form>
				<Container maxW='container.lg'>
					<Box
						marginTop={50}
						width='100%'
					>
						<Grid
							templateColumns='repeat(2, 1fr)'
							gap='5'
						>
							<FormControl>
								<label>Nome Completo</label>
								<Input
									type='text'
									height='44px'
									borderRadius={10}
								/>
							</FormControl>

							<FormControl>
								<label>Email</label>
								<Input
									type='email'
									height='44px'
									borderRadius={10}
								/>
							</FormControl>

							<FormControl>
								<label>CNPJ</label>
								<Input
									type='text'
									height='44px'
									borderRadius={10}
								/>
							</FormControl>

							<FormControl>
								<label>Razão Social</label>
								<Input
									type='text'
									height='44px'
									borderRadius={10}
								/>
							</FormControl>
						</Grid>
						<Grid
							templateColumns='repeat(6, 1fr)'
							gap='5'
							mt={5}
						>
							<FormControl gridColumn='span 3'>
								<label>Rua</label>
								<Input
									type='text'
									height='44px'
									borderRadius={10}
								/>
							</FormControl>

							<FormControl gridColumn='span 1'>
								<label>
									N<sup>o</sup>
								</label>
								<Input
									type='text'
									height='44px'
									borderRadius={10}
								/>
							</FormControl>

							<FormControl gridColumn='span 2'>
								<label>
									Complemento{' '}
									<Text
										color='gray.600'
										fontSize={'xs'}
										display={'inline'}
									>
										(Opcional)
									</Text>
								</label>
								<Input
									type='text'
									height='44px'
									borderRadius={10}
								/>
							</FormControl>
						</Grid>

						<Grid
							templateColumns='repeat(2, 1fr)'
							gap='5'
							mt={5}
						>
							<FormControl>
								<label>Bairro</label>
								<Input
									type='text'
									height='44px'
									borderRadius={10}
								/>
							</FormControl>

							<FormControl>
								<label>CEP</label>
								<Input
									type='text'
									height='44px'
									borderRadius={10}
								/>
							</FormControl>

							<FormControl>
								<label>Telefone Principal</label>
								<Input
									type='text'
									height='44px'
									borderRadius={10}
								/>
							</FormControl>

							<FormControl>
								<label>
									Telefone Alternativo{' '}
									<Text
										color='gray.600'
										fontSize={'xs'}
										display={'inline'}
									>
										(Opcional)
									</Text>
								</label>
								<Input
									type='text'
									height='44px'
									borderRadius={10}
								/>
							</FormControl>
						</Grid>
					</Box>
				</Container>
				<Container maxW='container.lg'>
					<Grid
						templateColumns='repeat(2, 1fr)'
						mt='20px'
						gap='6'
					>
						<Card
							p='20px'
							align='center'
							direction='column'
							w='100%'
							boxShadow='1px 1px 14px -3px silver'
						>
							<Radio
								isChecked={form['forma'] === 'cc'}
								onChange={(e) => onChange(e)}
								name='forma'
								value='cc'
								w={'100%'}
								justifyContent='center'
							>
								<Text
									fontSize='lg'
									fontWeight='bold'
								>
									Pagamento automático no cartão
								</Text>
							</Radio>
							<Text
								color='gray.600'
								fontSize='sm'
								mt='10px'
							>
								Você será cobrado automaticamente no seu cartão
								de crédito.
							</Text>
							<Mastercard
								mt='20px'
								maxW='300px'
								number={number}
								cvv={cvv}
								exp={expiration}
							></Mastercard>
							<Grid
								p='15px'
								px='20px'
								mt='20px'
								templateColumns={'repeat(5, 2fr)'}
							>
								<GridItem
									as={Flex}
									direction='column'
									py='5px'
									textAlign={'left'}
									colSpan={2}
								>
									<Text
										fontSize='xs'
										color='secondaryGray.600'
										fontWeight='700'
										mb='5px'
									>
										Cobrança em:
									</Text>
									<Text fontWeight='700'>30 de Outubro</Text>
								</GridItem>
								<VSeparator
									margin='0 auto'
									colSpan={1}
								/>
								<GridItem
									as={Flex}
									colSpan={2}
									direction='column'
									py='5px'
								>
									<ButtonComponent
										type='primary'
										title='Editar'
										fontSize='sm'
										onClick={() => setIsOpen(true)}
									></ButtonComponent>
								</GridItem>
							</Grid>
						</Card>

						<Card
							p='20px'
							align='center'
							direction='column'
							w='100%'
							boxShadow='1px 1px 14px -3px silver'
						>
							<Radio
								value='boleto'
								onChange={(e) => onChange(e)}
								name='forma'
								w={'100%'}
								justifyContent='center'
								isChecked={form['forma'] === 'boleto'}
							>
								<Text
									fontSize='lg'
									fontWeight='bold'
								>
									Boleto Bancário
								</Text>
							</Radio>
							<Text
								color='gray.600'
								fontSize='sm'
								mt='10px'
							>
								Um boleto será gerado de forma automática dias
								antes do vencimento de sua mensalidade.
							</Text>

							<Box
								boxShadow='0px 32px 41px -18px rgba(0, 0, 0, 0.2)'
								w='80%'
								p='20px'
								mx='auto'
								mb='10px'
								mt='10px'
								backgroundColor='#F4F7FE'
								borderRadius='40px'
							>
								<Image src={Barcode}></Image>
							</Box>
						</Card>
					</Grid>

					<Flex
						justifyContent='end'
						mt={'44px'}
					>
						<ButtonGroup spacing={'24px'}>
							<ButtonComponent
								type={'ghost'}
								title={'Cancelar'}
							/>
							<ButtonComponent
								type={'primary'}
								title={'Salvar'}
								action={'submit'}
							/>
						</ButtonGroup>
					</Flex>
				</Container>
			</form>

			<Modal
				isOpen={isOpen}
				onClose={onClose}
				size='2xl'
				isCentered
			>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<ModalBody p='30px'>
						<Grid
							templateColumns='repeat(2, 1fr)'
							gap='5'
						>
							<FormControl>
								<label>Número do Cartão</label>
								<Input
									type='text'
									height='44px'
									borderRadius={10}
									name='number'
									onChange={(e) => onChange(e)}
									value={form['number']}
								/>
							</FormControl>

							<FormControl>
								<label>Nome do Titular</label>
								<Input
									type='text'
									height='44px'
									borderRadius={10}
								/>
							</FormControl>
						</Grid>
						<Grid
							templateColumns='repeat(3, 1fr)'
							gap='5'
							mt='20px'
						>
							<FormControl>
								<label>Vencimento</label>
								{/* select 1 to 12 */}
								<Select
									placeholder='Mês'
									height='44px'
									borderRadius={10}
									name='mounth'
									onChange={(e) => onChange(e)}
									value={form['mounth']}
								>
									<option value='1'>1</option>
									<option value='2'>2</option>
									<option value='3'>3</option>
									<option value='4'>4</option>
									<option value='5'>5</option>
									<option value='6'>6</option>
									<option value='7'>7</option>
									<option value='8'>8</option>
									<option value='9'>9</option>
									<option value='10'>10</option>
									<option value='11'>11</option>
									<option value='12'>12</option>
								</Select>
							</FormControl>

							<FormControl>
								<label>Ano</label>
								<Input
									type='number'
									height='44px'
									borderRadius={10}
									onChange={(e) => onChange(e)}
									value={form['year']}
									name='year'
								/>
							</FormControl>

							<FormControl>
								<label>Código de Segurança</label>
								<Input
									type='number'
									height='44px'
									borderRadius={10}
									onChange={(e) => onChange(e)}
									value={form['cvv']}
									name='cvv'
								/>
							</FormControl>
						</Grid>
					</ModalBody>
					<ModalFooter>
						<Button
							onClick={onClose}
							variant='outline'
							mr='5px'
						>
							Cancelar
						</Button>
						<ButtonComponent
							type='primary'
							title='Salvar'
							onClick={() => updateCard()}
						></ButtonComponent>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Flex>
	);
}
