import { Flex } from '@chakra-ui/react';
import FormGerenciarPerfil from 'components/forms/FormGerenciarPerfil';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useGetUserAccount } from 'services/queryClient/wrapperHooks/authentication/useGetUserAccount';

export default function GerenciarPerfil() {
	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const { data: UserAccountData } = useGetUserAccount();

	const [initialState, setInitialState] = useState<any>();

	const helpCadastroUsurios: SidebarHelp = useMemo(
		() => ({
			title: 'Gerenciar Perfil',
			firstParagraph:
				'Nesta tela é possível configurar os dados do usuário que está logado na easy360. Para editar suas informações, altere os campos de Nome, Telefone Comercial e CEP. Observação: uma vez alterado o e-mail, não será permitido utilizar o mesmo e-mail para acessar outra conta da easy360.',
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpCadastroUsurios);
	}, [helpCadastroUsurios, setDataSidebar]);

	useEffect(() => {
		if (UserAccountData) {
			setInitialState({
				nome: UserAccountData?.nome || null,
				telefone:
					UserAccountData?.telefone?.replace(
						/(\d{2})(\d{5})(\d{4})/,
						'($1) $2-$3',
					) || null,
				email: UserAccountData?.email || null,
				rua: UserAccountData?.rua || null,
				numero: UserAccountData?.numero?.toString() || null,
				complemento: UserAccountData?.complemento || null,
				bairro: UserAccountData?.bairro || null,
				endereco:
					UserAccountData?.rua && UserAccountData?.bairro
						? `${UserAccountData?.rua}, ${UserAccountData?.bairro}`
						: null,
				cep:
					UserAccountData?.cep?.replace(/(\d{5})(\d{3})/, '$1-$2') ||
					null,
				membro_id: UserAccountData?.membro_id || null,
			});
		}
	}, [UserAccountData]);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<FormGerenciarPerfil initialState={initialState} />
		</Flex>
	);
}
