import { Flex } from '@chakra-ui/react';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
	useGetHierarchyWithId,
	useGetHierarchyWithIdAsMutate,
} from 'services/queryClient/wrapperHooks/useGetHierarchyWithId';
import { useHistory, useParams } from 'react-router-dom';
import { LoadingSpinner } from 'components/loading/Loading';
import FormCadastrarEditarHierarquia from 'components/forms/FormCadastrarEditarHierarquia';
import Card from 'components/card/Card';
import { useGlobalContext } from 'contexts/GlobalContext';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

export default function EditarMercado() {
	const history = useHistory();
	const { pathCompanyName } = useGlobalContext();

	const { setters } = useContext(SidebarHelpContext);
	const { setDataSidebar } = setters;

	const { id: hierarchyId } = useParams<any>();

	const helpEditarMercados: SidebarHelp = useMemo(
		() => ({
			title: 'Editar Hierarquia',
			firstParagraph:
				'Para editar uma hierarquia comercial, altere os campos desejados de, por exemplo, Canal de Venda, Zona de Venda e Membro.',
			secondParagraph:
				'Tenha certeza de ter cadastrado previamente os membros em Cadastros > Comercial > Membros.',
			thirdParagraph:
				'Observação: cada membro pode possuir apenas uma zona de venda dentro do seu mercado de atuação. Mas, o mesmo membro pode pertencer a mais de um mercado de atuação!',
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	const [initialState, setInitialState] = useState<any>();
	const [additionalData, setAdditionalData] = useState<any>();
	const [canRefetchData, setCanRefetchData] = useState(false);
	const [checkHierarchyExists, setCheckHierarchyExists] = useState(false);

	const { data: HierarchyData, isLoading: isLoadingHierarchyData } =
		useGetHierarchyWithId(hierarchyId);

	const {
		data: hierarchyDataAsMutate,
		isLoading: isLoadingHierarchyDataAsMutate,
		mutate: RefetchHierarchyDataAsMutate,
	} = useGetHierarchyWithIdAsMutate(hierarchyId);

	useEffect(() => {
		setDataSidebar(helpEditarMercados);
	}, [helpEditarMercados, setDataSidebar]);

	useEffect(() => {
		if (HierarchyData) {
			setInitialState({
				mercado: HierarchyData?.mercado_id || '',
				canal_venda: HierarchyData?.canal_venda?.id,
				forca_venda: HierarchyData?.forca_venda?.id || '',
				zona_venda: HierarchyData?.zona_venda?.id,
				membro: HierarchyData?.membro?.id,
			});

			setAdditionalData({
				forcaVenda: HierarchyData?.forca_venda,
				zonaVenda: HierarchyData?.zona_venda,
			});
		}
	}, [HierarchyData]);

	useEffect(() => {
		if (canRefetchData) {
			RefetchHierarchyDataAsMutate();
			setCheckHierarchyExists(true);
		}
	}, [canRefetchData, RefetchHierarchyDataAsMutate, hierarchyId]);

	useEffect(() => {
		if (isLoadingHierarchyDataAsMutate) setCanRefetchData(false);
	}, [isLoadingHierarchyDataAsMutate]);

	useEffect(() => {
		if (
			!hierarchyDataAsMutate &&
			!isLoadingHierarchyDataAsMutate &&
			checkHierarchyExists
		) {
			setCheckHierarchyExists(false);
			history.push(`/${pathCompanyName}/cadastros/comercial/hierarquia`);
		}
	}, [
		checkHierarchyExists,
		hierarchyDataAsMutate,
		isLoadingHierarchyDataAsMutate,
		history,
		pathCompanyName,
	]);

	return isLoadingHierarchyData || !initialState ? (
		<LoadingSpinner />
	) : (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				<FormCadastrarEditarHierarquia
					initialState={initialState}
					hierarchyId={hierarchyId}
					additionalData={additionalData}
					setCanRefetchData={setCanRefetchData}
				/>
			</Card>
		</Flex>
	);
}
