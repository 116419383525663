import {
	Box,
	Button,
	ButtonGroup,
	Flex,
	Heading,
	Icon,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Select,
	Spacer,
	Stack,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Tr,
	useColorModeValue,
	useDisclosure,
} from '@chakra-ui/react';
import Card from 'components/card/Card';

import {
	IconArrowLeft,
	IconArrowRight,
	IconCaretDown,
	IconCaretRight,
	IconEdit,
	IconTrash,
} from '@tabler/icons';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import TreeView from 'components/treeView/TreeView';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import { useCallback, useContext, useMemo, useEffect, useState } from 'react';
import { ActionDrop, useDragAndDrop } from 'services/hooks/useDragAndDrop';
import { usePathProduct } from 'services/queryClient/wrapperHooks/usePathProduct';
import { useFamilyListWithProducts } from '../../../../../services/queryClient/wrapperHooks/useFamilyListWithProducts';
import ModalCadastrarEditarFamilia from './modalCadastrarEditar';
import ModalDelete from './modalDelete';
import { useProductNotFamilyList } from 'services/queryClient/wrapperHooks/product/useProductNotFamilyList';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

interface OptionFamily {
	nome: string;
	id: string;
}
interface OptionsSubfamily {
	[key: string]: OptionFamily[];
}

interface OptionsProductModel {
	[key: string]: OptionsSubfamily[];
}

export default function CadastrarFamilias() {
	const [familys, setFamilys] = useState<any>({
		root: {
			index: 'root',
			children: [],
			data: 'Root family',
			hasChildren: true,
			indexComponent: 0,
		},
	});

	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const helpFamily: SidebarHelp = useMemo(
		() => ({
			title: 'Família',
			firstParagraph:
				'Nesta tela é possível visualizar, organizar e cadastrar a Hierarquia de Produtos do seu negócio, permitindo uma visão macro e estruturada dos produtos. A hierarquia é comporta por Famílias > Subfamílias > Produto (SKU).',
			secondParagraph:
				'Acesse o botão “Cadastrar Família” para adicionar novas famílias de produtos. Ao criar uma família é possível incluir uma subfamília a fim de descrever melhor sua estrutura de produtos.',
			thirdParagraph:
				'Observação: os produtos sem família não compõem o rateio e previsão da demanda, portanto garanta que alocou todos os produtos a uma família.',
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpFamily);
	}, [helpFamily, setDataSidebar]);

	const [body, setBody] = useState<{
		id: string;
		familia_id: string | null;
		sub_familia_id: string | null;
		modelo_produto_id: string | null;
	}>();

	const { mutate } = usePathProduct(
		{
			id: body?.id,
		},
		{
			familia_id: Number(body?.familia_id) || null,
			sub_familia_id: Number(body?.sub_familia_id) || null,
			modelo_produto_id: Number(body?.modelo_produto_id) || null,
		},
	);

	const {
		isOpen: isOpenDeleteModal,
		onOpen: onOpenDeleteModal,
		onClose: onCloseDeleteModal,
	} = useDisclosure();

	const {
		isOpen: isOpenCreateEditModal,
		onOpen: onOpenCreateEditModal,
		onClose: onCloseCreateEditModal,
	} = useDisclosure();

	const [autocompleteAdditionalData, setAutocompleteAdditionalData] =
		useState<any>({});

	const [deleteId, setDeleteId] = useState<string | number>('');
	const [product, setProducts] = useState({});

	const [filterValueFamily, setFilterValueFamily] = useState<string>();
	const [filterValueSubfamily, setFilterValueSubfamily] = useState<string>();

	const [optionsFamilys, setOptionsFamilys] = useState<OptionFamily[]>([]);
	const [optionsSubfamilys, setOptionsSubfamilys] =
		useState<OptionsSubfamily>({});

	const [createOrEditType, setCreateOrEditType] = useState<
		'family' | 'subFamily' | 'productModel'
	>('family');

	const action: ActionDrop = (evt, idsFatherTo, idsFatherFrom, produto) => {
		let familia_id: string | null = null,
			sub_familia_id: string | null = null,
			modelo_produto_id: string | null = null;

		const id = produto.original.id;
		const keyProduct = produto.index;
		const newProduct = { ...product };
		const newFamilys = { ...familys };

		// const insertIntoFamily = Boolean(familys[keyProduct]);
		newFamilys[keyProduct] = produto;
		if (idsFatherFrom) {
			const idFather = idsFatherFrom[idsFatherFrom.length - 2];

			const newChildrenFrom = newFamilys[idFather].children.filter(
				(key: string) => key !== keyProduct,
			);

			newFamilys[idFather].children = newChildrenFrom;
		} else {
			delete newProduct[keyProduct];
		}

		if (idsFatherTo && idsFatherTo.length === 3) {
			const [idFamiliaTo, idSubFamiliaTo, idProductModelTo] = idsFatherTo;

			newFamilys[idProductModelTo].children = [
				...newFamilys[idProductModelTo].children,
				keyProduct,
			];

			familia_id = idFamiliaTo.split('-')[1];
			sub_familia_id = idSubFamiliaTo.split('-')[1];
			modelo_produto_id = idProductModelTo.split('-')[1];
		} else if (idsFatherTo && idsFatherTo.length === 2) {
			const [idFamiliaTo, idSubFamiliaTo] = idsFatherTo;

			newFamilys[idSubFamiliaTo].children = [
				...newFamilys[idSubFamiliaTo].children,
				keyProduct,
			];

			familia_id = idFamiliaTo.split('-')[1];
			sub_familia_id = idSubFamiliaTo.split('-')[1];
		} else if (idsFatherTo) {
			const [keyFamilia] = idsFatherTo;

			newFamilys[keyFamilia].children = [
				...newFamilys[keyFamilia].children,
				keyProduct,
			];
			familia_id = keyFamilia.split('-')[1];
		} else {
			newProduct[keyProduct] = produto;
		}
		setBody({
			id,
			familia_id,
			sub_familia_id,
			modelo_produto_id,
		});
		setProducts(newProduct);
		setFamilys(newFamilys);
	};

	useEffect(() => {
		body && mutate();
	}, [body, mutate]);

	const {
		onDragEnd,
		onDragEnter,
		onDragLeave,
		onDragOver,
		onDragStart,
		onDrop,
	} = useDragAndDrop(action);

	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

	const setOpen = useCallback(
		(id: string) => {
			setFamilys({
				...familys,
				[id]: { ...familys[id], isOpen: !familys[id].isOpen },
			});
		},
		[familys],
	);

	const { data: dataFamily, isLoading: isLoadingFamily } =
		useFamilyListWithProducts();

	const { data: dataProduct } = useProductNotFamilyList();

	const { onOpen: onOpenCreate } = useDisclosure();

	const onOpenDelete = useCallback(
		(id, tipo: 'família' | 'subfamília' | 'modeloProduto') => {
			switch (tipo) {
				case 'família':
					setCreateOrEditType('family');
					break;
				case 'subfamília':
					setCreateOrEditType('subFamily');
					break;
				case 'modeloProduto':
					setCreateOrEditType('productModel');
					break;
			}

			const entityId = id?.split('-')?.[1];

			setDeleteId(!!entityId ? Number(entityId) : '');

			onOpenDeleteModal();
		},
		[onOpenDeleteModal],
	);

	//EDITAR FAMILIA
	const onOpenEdit = useCallback(
		(data, id, tipo: 'família' | 'subfamília' | 'modeloProduto') => {
			switch (tipo) {
				case 'família':
					setCreateOrEditType('family');
					setAutocompleteAdditionalData({
						family: data,
					});
					onOpenCreateEditModal();
					break;
				case 'subfamília':
					setCreateOrEditType('subFamily');
					const familyBySubFamily = dataFamily?.rows?.find(
						(f) => f.id === data.familia_id,
					);
					setAutocompleteAdditionalData({
						family: familyBySubFamily,
						subFamily: data,
					});
					break;
				case 'modeloProduto':
					setCreateOrEditType('productModel');
					const familyByProductModel = dataFamily?.rows?.find(
						(f) => f.id === data.familia_id,
					);
					const subFamilyByProductModel =
						familyByProductModel?.sub_familia?.find(
							(sf) => sf.id === data.sub_familia_id,
						);
					setAutocompleteAdditionalData({
						family: familyByProductModel,
						subFamily: subFamilyByProductModel,
						productModel: data,
					});
					break;
			}

			onOpenCreateEditModal();
		},
		[dataFamily, onOpenCreateEditModal],
	);

	useEffect(() => {
		if (dataFamily?.rows) {
			const newFamilys: any = {
				root: {
					index: 'root',
					children: [],
					data: 'Root family',
					hasChildren: true,
					indexComponent: 0,
				},
			};

			const newOptionsFamily: OptionFamily[] = [];
			const newOptionsSubfamilys: OptionsSubfamily = {};

			dataFamily?.rows?.forEach((family: any, index: number) => {
				const keyFamily = `familia-${family.id}`;

				newFamilys.root.children.push(keyFamily);

				newFamilys[keyFamily] = {
					id: family.id,
					index: keyFamily,
					children: [],
					data: family.nome_familia,
					hasChildren: true,
					indexComponent: 1,
					isOpen: familys[keyFamily]?.isOpen || false,
					original: family,
				};

				newOptionsFamily.push({
					id: family.id,
					nome: family.nome_familia,
				});

				const newOptionsSubfamily: OptionFamily[] = [];

				family?.sub_familia?.forEach(
					(subFamily: any, subIndex: number) => {
						const keySubfamily = `subfamilia-${subFamily.id}`;

						newFamilys[keyFamily].children.push(keySubfamily);

						newFamilys[keySubfamily] = {
							id: subFamily.id,
							index: keySubfamily,
							children: [],
							data: subFamily.nome_sub_familia,
							hasChildren: true,
							indexComponent: 2,
							isOpen: familys[keySubfamily]?.isOpen || false,
							original: subFamily,
						};

						newOptionsSubfamily.push({
							id: subFamily.id,
							nome: subFamily.nome_sub_familia,
						});

						const newOptionsProduct: OptionsSubfamily[] = [];

						subFamily?.modelo?.forEach(
							(productModel: any, productModelIndex: number) => {
								const keyProductModel = `modeloProduto-${productModel.id}`;

								newFamilys[keySubfamily].children.push(
									keyProductModel,
								);

								newFamilys[keyProductModel] = {
									id: productModel.id,
									index: keyProductModel,
									children: [],
									data: productModel.nome_modelo,
									hasChildren: false,
									indexComponent: 3,
									isOpen:
										familys[keyProductModel]?.isOpen ||
										false,
									original: productModel,
								};

								newOptionsProduct.push({
									id: productModel.id,
									nome: productModel.nome_modelo,
								});
							},
						);
					},
				);

				newOptionsSubfamilys[family.id] = newOptionsSubfamily;

				family?.produto?.forEach((product: any, subIndex: number) => {
					const keyProduct = `produto-${product.id}`;

					if (!!product?.modelo_produto_id) {
						const keyProductModel = `modeloProduto-${product.modelo_produto_id}`;

						newFamilys[keyProductModel]?.children?.push(keyProduct);
					} else {
						const keyFather = product.sub_familia_id
							? `subfamilia-${product.sub_familia_id}`
							: `${keyFamily}`;

						newFamilys[keyFather].children.push(keyProduct);
					}

					newFamilys[keyProduct] = {
						id: product.id,
						index: keyProduct,
						children: [],
						data: product.nome_produto,
						hasChildren: false,
						indexComponent: 4,
						isOpen: false,
						original: product,
					};
				});
			});

			setFamilys(newFamilys);
			setOptionsFamilys(newOptionsFamily);
			setOptionsSubfamilys(newOptionsSubfamilys);
			// setOptionsProductModel(newOptionsProductModel);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataFamily]);

	useEffect(() => {
		const products: {
			[key: string]: {
				id: string;
				index: string;
				children: [];
				hasChildren: false;
				indexComponent: 4;
				data: string;
				original: any;
				isOpen: false;
			};
		} = {};
		if (dataProduct) {
			dataProduct.forEach((product: any) => {
				const keyProduct = `produto-${product.id}`;

				products[keyProduct] = {
					id: product.id,
					index: keyProduct,
					children: [],
					data: product.nome_produto,
					hasChildren: false,
					indexComponent: 4,
					isOpen: false,
					original: product,
				};
			});
			setProducts(products);
		}
	}, [dataProduct]);

	function buildCreateButton() {
		return (
			<Menu>
				<MenuButton
					data-test='button-cadastrar-familia'
					as={Button}
					size='large'
					fontWeight='100'
					px='30px'
					py='8px'
					border='1px solid'
					borderRadius='100px'
					bg='easyBLUE.300'
					borderColor='easyBLUE.150'
					color='easyBrandMenu.100'
					fontSize='16px'
					_hover={{ bg: 'easyBLUE.300', color: 'easyBrandMenu.100' }}
					_focus={{ bg: 'easyBLUE.300', color: 'easyBrandMenu.100' }}
					_active={{
						bg: 'easyBLUE.300',
						color: 'easyBrandMenu.100',
						boxShadow: 'none',
					}}
				>
					Cadastrar
				</MenuButton>
				<MenuList>
					<MenuItem
						data-test='item-cadastrar-familia-page-familia'
						onClick={() => {
							setCreateOrEditType('family');
							setAutocompleteAdditionalData({});
							onOpenCreateEditModal();
						}}
					>
						Família
					</MenuItem>
					<MenuItem
						data-test='item-cadastrar-subfamilia-page-familia'
						onClick={() => {
							setCreateOrEditType('subFamily');
							setAutocompleteAdditionalData({});
							onOpenCreateEditModal();
						}}
					>
						Subfamília
					</MenuItem>
					<MenuItem
						data-test='item-cadastrar-modelo_de_produto-page-familia'
						onClick={() => {
							setCreateOrEditType('productModel');
							setAutocompleteAdditionalData({});
							onOpenCreateEditModal();
						}}
					>
						Modelo de Produto
					</MenuItem>
				</MenuList>
			</Menu>
		);
	}

	const renderTreeViewBox = useCallback(
		({ dataTest, data, children }) => (
			<Box>
				<Flex
					flexDirection={'column'}
					data-test={dataTest}
				>
					{children}
				</Flex>
			</Box>
		),
		[],
	);

	const renderFamilyLevel = useCallback(
		(props) => {
			const { dataTest, data, id, datas, children } = props;
			const idFamylyData = familys[id].original.id;
			const showFamily =
				!filterValueFamily || filterValueFamily === `${idFamylyData}`;
			return (
				<Box display={showFamily ? undefined : 'none'}>
					<Flex
						data-test={dataTest}
						borderBottom={'1px solid'}
						borderColor={borderColor}
						minHeight={12}
						alignItems={'center'}
						fontSize={'16px'}
						fontWeight={700}
						color={'easyBLUE.300'}
						onDragLeave={(e) => onDragLeave(e)}
						onDragEnter={(e) => onDragEnter(e)}
						onDragEnd={(e) => onDragEnd(e)}
						onDragOver={(e) => onDragOver(e)}
						onDrop={(e) => onDrop(e, datas)}
					>
						<Flex
							flexGrow={1}
							onClick={() => setOpen(id)}
						>
							<Flex mr={3.5}>
								<Icon
									as={
										familys[id].isOpen
											? IconCaretDown
											: IconCaretRight
									}
									boxSize={5}
									color={'secondaryGray.600'}
								/>
							</Flex>
							<Text>{data}</Text>{' '}
						</Flex>
						<Flex cursor='pointer'>
							<Stack
								direction='row'
								alignSelf={{
									base: 'unset',
									md: 'flex-end',
								}}
								spacing='8px'
							>
								<Icon
									data-test='link-editar-familia-page-familia'
									color='secondaryGray.500'
									as={IconEdit}
									w='20px'
									h='20px'
									onClick={() =>
										onOpenEdit(
											familys[id].original,
											id,
											'família',
										)
									}
								/>
								<Icon
									data-test='link-excluir-familia-page-familia'
									color='secondaryGray.500'
									as={IconTrash}
									w='20px'
									h='20px'
									onClick={() => onOpenDelete(id, 'família')}
								/>
							</Stack>
						</Flex>
					</Flex>
					{familys[id].isOpen && children}
				</Box>
			);
		},
		[
			borderColor,
			familys,
			filterValueFamily,
			onDragEnd,
			onDragEnter,
			onDragLeave,
			onDragOver,
			onDrop,
			onOpenDelete,
			onOpenEdit,
			setOpen,
		],
	);

	const renderIntermediateLevels = useCallback(
		({ dataTest, data, id, datas, children, actionKey, paddingLeft }) => {
			const idData = familys[id].original.id;
			return (
				<Box alignItems={'center'}>
					<Flex
						data-test={dataTest}
						paddingLeft={paddingLeft}
						fontSize={'16px'}
						minHeight={12}
						fontWeight={700}
						color={'easyBLUE.300'}
						alignItems={'center'}
						borderBottom={'1px solid'}
						borderColor={borderColor}
						display={
							!filterValueFamily ||
							!filterValueSubfamily ||
							filterValueSubfamily === `${idData}`
								? undefined
								: 'none'
						}
						onDragLeave={(e) => onDragLeave(e)}
						onDragEnter={(e) => onDragEnter(e)}
						onDragEnd={(e) => onDragEnd(e)}
						onDragOver={(e) => onDragOver(e)}
						onDrop={(e) => onDrop(e, datas)}
					>
						<Flex
							flexGrow={1}
							onClick={() => setOpen(id)}
						>
							<Flex mr={3.5}>
								<Icon
									as={
										familys[id].isOpen
											? IconCaretDown
											: IconCaretRight
									}
									boxSize={5}
									color={'secondaryGray.600'}
								/>
							</Flex>
							<Text>{data}</Text>
						</Flex>
						<Flex cursor='pointer'>
							<Stack
								direction='row'
								alignSelf={{
									base: 'unset',
									md: 'flex-end',
								}}
								spacing='8px'
							>
								<Icon
									data-test={`link-editar-${
										actionKey === 'subfamília'
											? 'subfamilia'
											: 'modelo_de_produto'
									}-page-familia`}
									color='secondaryGray.500'
									as={IconEdit}
									w='20px'
									h='20px'
									onClick={() =>
										onOpenEdit(
											familys[id].original,
											id,
											actionKey,
										)
									}
								/>
								<Icon
									data-test={`link-excluir-${
										actionKey === 'subfamília'
											? 'subfamilia'
											: 'modelo_de_produto'
									}-page-familia`}
									color='secondaryGray.500'
									as={IconTrash}
									w='20px'
									h='20px'
									onClick={() => onOpenDelete(id, actionKey)}
								/>
							</Stack>
						</Flex>
					</Flex>

					{familys[id].isOpen && children}
				</Box>
			);
		},
		[
			borderColor,
			familys,
			filterValueFamily,
			filterValueSubfamily,
			onDragEnd,
			onDragEnter,
			onDragLeave,
			onDragOver,
			onDrop,
			onOpenDelete,
			onOpenEdit,
			setOpen,
		],
	);

	const renderProductLevel = useCallback(
		({ dataTest, data, id, deeper, datas }) => {
			const codeProductData = familys[id].original.codigo_produto;

			const paddingLeft = {
				1: 0,
				2: 12,
				3: 24,
				4: 36,
			}[deeper];

			return (
				<>
					<Box
						alignItems={'center'}
						borderBottom={data !== 'Total' ? '1px solid' : 'null'}
						borderColor={data !== 'Total' ? borderColor : 'null'}
					>
						<Flex
							data-test={dataTest}
							onClick={() => setOpen(id)}
							paddingLeft={paddingLeft}
							fontSize={'16px'}
							fontWeight={400}
							color={'easyBLUE.300'}
							minHeight={12}
							alignItems={'center'}
							bg={
								data === 'Total' ? 'secondaryGray.300' : 'white'
							}
							draggable
							onDragStart={(e) =>
								onDragStart(e, datas, familys[id])
							}
							onDragEnd={(e) => onDragEnd(e)}
							id={`${id}`}
						>
							<Text
								fontWeight={
									data === 'Total' ? 'bold' : 'normal'
								}
							>
								{codeProductData ? (
									`${codeProductData} - ${data}`
								) : (
									<>
										<Text
											as={'span'}
											color={'easyRED.300'}
										>
											Sem SKU
										</Text>
										{` - ${data}`}
									</>
								)}
							</Text>
							<Spacer></Spacer>
							{/* <RowEditAndDelete
	  dataDelete={{}}

	/> */}
						</Flex>
					</Box>
				</>
			);
		},
		[borderColor, familys, onDragEnd, onDragStart, setOpen],
	);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				<Flex
					align={{ sm: 'flex-start', lg: 'flex-start' }}
					mt={'20px'}
					me={'20px'}
					justify={'space-between'}
					w='100%'
					px='22px'
					mb='36px'
				>
					<SearchBar
						h='44px'
						placeholder='Buscar...'
						w={{ lg: '390px' }}
						borderRadius='16px'
					/>
					<Spacer />
					<ButtonGroup>{buildCreateButton()}</ButtonGroup>
				</Flex>
				<Flex
					color={'black'}
					gap={'10px'}
					paddingX='20px'
					paddingY={'20px'}
				>
					<Box
						flexGrow={1}
						gap='5px'
						display={'grid'}
					>
						<Text fontWeight='500'>Familias</Text>
						<Select
							data-test='select-familias-page-familia'
							borderRadius={16}
							value={filterValueFamily}
							onChange={(e) =>
								setFilterValueFamily(e.target.value)
							}
						>
							<option value={''}></option>
							{optionsFamilys.map(({ id, nome }) => (
								<option value={id}>{nome}</option>
							))}
						</Select>
					</Box>
					<Box
						gap='5px'
						flexGrow={1}
						display={'grid'}
					>
						<Text fontWeight='500'>Subfamílias</Text>

						<Select
							data-test='select-subfamilias-page-familia'
							borderRadius={16}
							value={filterValueSubfamily}
							onChange={(e) =>
								setFilterValueSubfamily(e.target.value)
							}
						>
							<option value={''}></option>
							{filterValueFamily &&
								optionsSubfamilys[filterValueFamily]?.map(
									({ id, nome }) => (
										<option value={id}>{nome}</option>
									),
								)}
						</Select>
					</Box>
				</Flex>

				<Flex
					pl='25px'
					pr='25px'
				>
					<Box w='47%'>
						<Heading
							color='gray.500'
							mb={2}
							size='md'
						>
							Organização por Famílias
						</Heading>

						{/* tela de famílias */}
						{!dataFamily?.rows.length && !isLoadingFamily ? (
							<Box
								bgColor='gray.200'
								borderRadius='25px'
								minHeight='250px'
								display='flex'
								justifyContent='center'
								flexWrap='wrap'
								flexDirection='column'
								textAlign='center'
								alignItems='center'
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='44'
									height='44'
									viewBox='0 0 24 24'
									stroke-width='1.5'
									stroke='#F0243E'
									fill='none'
									stroke-linecap='round'
									stroke-linejoin='round'
								>
									<path
										stroke='none'
										d='M0 0h24v24H0z'
										fill='none'
									/>
									<line
										x1='9'
										y1='12'
										x2='15'
										y2='12'
									/>
									<line
										x1='12'
										y1='9'
										x2='12'
										y2='15'
									/>
									<path d='M4 6v-1a1 1 0 0 1 1 -1h1m5 0h2m5 0h1a1 1 0 0 1 1 1v1m0 5v2m0 5v1a1 1 0 0 1 -1 1h-1m-5 0h-2m-5 0h-1a1 1 0 0 1 -1 -1v-1m0 -5v-2m0 -5' />
								</svg>
								<Heading
									display='block'
									color='gray.600'
									mb={2}
									size='sm'
								>
									Você ainda não possui Famílias
								</Heading>

								<Text>
									Você pode iniciar cadastrando sua primeira
									família
								</Text>

								<Button
									variant='easyRed'
									size='sm'
									mt='2'
									mr='2'
									onClick={onOpenCreate}
								>
									{' '}
									Cadastrar Família
								</Button>
							</Box>
						) : (
							<TreeView
								ids={[]}
								id='root'
								Containers={[
									(props) =>
										renderTreeViewBox({
											...props,
											dataTest: 'tree_view-page-familia',
										}),
									(props) =>
										renderFamilyLevel({
											...props,
											dataTest:
												'tree_view-familia-page-familia',
										}),
									(props) =>
										renderIntermediateLevels({
											...props,
											dataTest:
												'tree_view-subfamilia-page-familia',
											actionKey: 'subfamília',
											paddingLeft: 12,
										}),
									(props) =>
										renderIntermediateLevels({
											...props,
											dataTest:
												'tree_view-modelo_de_produto-page-familia',
											actionKey: 'modeloProduto',
											paddingLeft: 24,
										}),
									(props) =>
										renderProductLevel({
											...props,
											dataTest:
												'tree_view-produto-page-familia',
										}),
								]}
								items={familys}
								deeper={0}
							/>
						)}
					</Box>
					<Spacer
						display='flex'
						justifyContent='center'
						alignItems='center'
					>
						<Box>
							<Icon
								as={IconArrowRight}
								boxSize={5}
								color={'secondaryGray.600'}
								display='block'
							/>
							<Icon
								as={IconArrowLeft}
								boxSize={5}
								color={'secondaryGray.600'}
								display='block'
							/>
						</Box>
					</Spacer>
					<Box
						w='47%'
						display={'flex'}
						flexDirection={'column'}
					>
						<Heading
							color='gray.500'
							mb={2}
							size='md'
						>
							Produtos Sem Família
						</Heading>
						<TableContainer
							maxHeight='70vh'
							overflowY='auto'
							flexGrow={1}
						>
							<Table
								height={'100%'}
								width={'100%'}
								display={'flex'}
							>
								<Tbody
									flexDirection={'column'}
									onDrop={(e) => onDrop(e, false)}
									onDragLeave={(e) => onDragLeave(e)}
									onDragEnter={(e) => onDragEnter(e)}
									onDragEnd={(e) => onDragEnd(e)}
									onDragOver={(e) => onDragOver(e)}
									height={'100%'}
									width={'100%'}
									display={'flex'}
								>
									{Object.values(product).map(
										(props: any) => {
											const { data, id, original } =
												props;
											const dataConcatenated =
												original?.codigo_produto
													? `${original?.codigo_produto} - ${data}`
													: `Sem SKU - ${data}`;
											return (
												<Tr
													width={'100%'}
													id={data}
													draggable
													onDragStart={(e) =>
														onDragStart(
															e,
															false,
															props,
														)
													}
													onDragEnd={(e) =>
														onDragEnd(e)
													}
													display={'flex'}
													justifyContent={'center'}
													alignItems={'center'}
													height={12}
												>
													<Td
														borderBottom='1px solid'
														borderColor='#e9e9e9'
														width={'100%'}
														height={12}
													>
														{dataConcatenated}
													</Td>
												</Tr>
											);
										},
									)}
								</Tbody>
							</Table>
						</TableContainer>
					</Box>
				</Flex>
			</Card>

			<ModalCadastrarEditarFamilia
				type={createOrEditType}
				isOpen={isOpenCreateEditModal}
				onClose={onCloseCreateEditModal}
				autocompleteAdditionalData={autocompleteAdditionalData}
			/>

			<ModalDelete
				type={createOrEditType}
				isOpen={isOpenDeleteModal}
				onClose={onCloseDeleteModal}
				removeId={deleteId}
			/>
		</Flex>
	);
}
