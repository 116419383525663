import { Flex } from '@chakra-ui/react';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useGetAverageTicketWithId } from 'services/queryClient/wrapperHooks/averageTicket/useGetTicketMedioById';
import { useParams } from 'react-router-dom';
import Card from 'components/card/Card';
import FormCadastrarEditarPrecoMedio from 'components/forms/FormCadastrarEditarPrecoMedio';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

export default function EditarPrecoMedio() {
	const { setters } = useContext(SidebarHelpContext);
	const { setDataSidebar } = setters;

	const { id: averageTicketId } = useParams<any>();

	const { data: AverageTicketData } =
		useGetAverageTicketWithId(averageTicketId);

	const [initialState, setInitialState] = useState<any>();
	const [autocompleteAdditionalData, setAutocompleteAditionalData] =
		useState<any>();

	const helpEditarVendasTicketMedio: SidebarHelp = useMemo(
		() => ({
			title: 'Editar Preço Médio',
			firstParagraph: `Para editar o valor do preço médio do produto selecionado entre com o novo valor no campo Preço Médio. Observação: Este valor influência nos cálculos de previsão de receita bruta do processo de demanda da easy360.`,
			secondParagraph: `Uma vez alterado o valor do preço médio, a easy360 não recalculará automaticamente um novo valor conforme novas vendas. Caso deseje seguir com o cálculo automático, lembre-se de, após subir dados de novas vendas, acionar o botão do produto desejado.`,
			thirdParagraph: `A easy360 usa como padrão o histórico de 6 meses de vendas para calcular o preço médio, você pode alterar essa configuração acessando Configurações > Configurações da Easy360. Observação: somente o administrador da conta possui permissão para alterar essa configuração.`,
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpEditarVendasTicketMedio);
	}, [helpEditarVendasTicketMedio, setDataSidebar]);

	useEffect(() => {
		if (AverageTicketData) {
			setAutocompleteAditionalData({
				family: AverageTicketData?.produto?.familia,
				product: AverageTicketData?.produto,
			});

			setInitialState({
				familia_id: AverageTicketData?.produto?.familia?.id,
				produto_id: AverageTicketData?.produto?.id,
				canal_venda_id: AverageTicketData?.canalVenda?.id,
				ticket_medio_editado: AverageTicketData.recalcular
					? AverageTicketData?.ticket_medio_calculado
					: AverageTicketData?.ticket_medio_editado,
			});
		}
	}, [AverageTicketData]);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				<FormCadastrarEditarPrecoMedio
					initialState={initialState}
					averageTicketId={averageTicketId}
					autocompleteAdditionalData={autocompleteAdditionalData}
				/>
			</Card>
		</Flex>
	);
}
