import { Flex } from '@chakra-ui/react';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import { useContext, useEffect, useMemo, useState } from 'react';
import FormCadastrarEditarClienteRelacionamento from '../../../../../components/forms/FormCadastrarEditarClienteRelacionamento';
import { useGetCustomerAndRelationshipById } from '../../../../../services/queryClient/wrapperHooks/useGetCustomerAndRelationshipById';
import { useParams } from 'react-router-dom';
import Card from 'components/card/Card';

import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

export default function EditarClienteRelacionamento() {
	const { id: customerId } = useParams<any>();

	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const [initialState, setInitialState] = useState<any>();
	const [autocompleteAdditionalData, setAutocompleteAdditionalData] =
		useState<any>();

	const { data: CustomerRelationshipData } =
		useGetCustomerAndRelationshipById(customerId);

	const helpEditarClienteRelacionamento: SidebarHelp = useMemo(
		() => ({
			title: 'Editar Cliente Relacionamento',
			firstParagraph:
				'Para editar um cliente de relacionamento entre com os dados de Razão Social, CNPJ/CPF e Grupo de Relacionamento. É possível criar um relacionamento, clicando no botão Adicionar.',
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpEditarClienteRelacionamento);
	}, [helpEditarClienteRelacionamento, setDataSidebar]);

	useEffect(() => {
		if (CustomerRelationshipData) {
			setAutocompleteAdditionalData({
				customer: CustomerRelationshipData?.cliente,
				relationship: CustomerRelationshipData?.relacionado,
			});

			setInitialState({
				id: CustomerRelationshipData?.cliente?.id,
				cliente_relacionado_id:
					CustomerRelationshipData?.relacionado?.id || null,
				autoRelationShip:
					CustomerRelationshipData?.cliente?.id ===
					CustomerRelationshipData?.relacionado?.id,
			});
		}
	}, [CustomerRelationshipData]);

	const initialStateModal = {
		razao_social: null,
		documento: null,
		tipo_documento: null,
	};

	return (
		<Flex pt={{ sm: '125px', lg: '75px' }}>
			<Card px='form-cadastro'>
				<FormCadastrarEditarClienteRelacionamento
					initialState={initialState}
					initialStateModal={initialStateModal}
					autocompleteAdditionalData={autocompleteAdditionalData}
					customerId={customerId}
				/>
			</Card>
		</Flex>
	);
}
