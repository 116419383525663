// Chakra imports
import { Flex } from '@chakra-ui/react';
import { useGetParameterizationCompanyById } from 'services/queryClient/wrapperHooks/useGetParameterizationCompanyById';
import { useContext, useEffect, useMemo, useState } from 'react';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import Card from 'components/card/Card';
import FormConfiguracoesEasy from '../../../components/forms/FormConfiguracoesEasy';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

export default function ConfiguracoesUsuario() {
	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;
	const [initialState, setInitialState] = useState<any>();

	const { data: parameterizationData } = useGetParameterizationCompanyById();

	const helpCadastroOportunidades: SidebarHelp = useMemo(
		() => ({
			title: 'Configurações da easy360',
			firstParagraph:
				'Nesta tela é possível configurar os parâmetros gerais da easy360. O CNAE primário da sua indústria é importante para orientar sua indústria conforme segmento, retornando dados mais assertivos e direcionados.',
			secondParagraph:
				'O ciclo representa a janela de tempo considerada no processo de previsão de demanda. Por padrão, orienta-se analisar os próximos 6 meses, porém você pode alterar para um tamanho de ciclo de 9 ou 12 meses futuros.',
			thirdParagraph:
				'A easy360 usa como padrão o histórico de 6 meses de vendas para calcular o preço médio dos produtos. Porém, você pode alterar para considerar 3, 6, 9 ou 12 meses de histórico. Observação: quando utilizado um tempo menor de histórico, alguns produtos podem ficar sem renovação no valor do preço médio devido não possuir histórico de venda dentro da janela de tempo selecionada.',
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpCadastroOportunidades);
	}, [helpCadastroOportunidades, setDataSidebar]);

	useEffect(() => {
		if (parameterizationData) {
			let cnaeMask = (numero) => {
				if (/^\d{5}$/.test(numero)) {
					return numero.replace(/^(\d{4})(\d{1})$/, '$1-$2');
				} else if (/^\d{7}$/.test(numero)) {
					return numero.replace(
						/^(\d{4})(\d{1})(\d{2})$/,
						'$1-$2/$3',
					);
				} else {
					return numero;
				}
			};

			setInitialState({
				cnae: cnaeMask(parameterizationData.cnae_primario?.toString()),
				cycleSize: parameterizationData.tamanho_ciclo,
				salesHistory: parameterizationData.tamanho_rateio_ticket,
				decimalPrecision: parameterizationData.precisao_decimal,
			});
		}
	}, [parameterizationData]);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				<FormConfiguracoesEasy initialState={initialState} />
			</Card>
		</Flex>
	);
}
