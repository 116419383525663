import { Flex } from '@chakra-ui/react';
import FormBotSettings from 'components/forms/FormBotSettings';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';

import { SidebarHelp } from 'models/sidebar-help.model';
import { useContext, useEffect } from 'react';

import { URL_CT360, URL_FALE_CONOSCO } from '../../../../constants/global';

export default function BotSettings() {
	const { setters } = useContext(SidebarHelpContext);
	const { setDataSidebar } = setters;

	const helpConfigurarProcessos: SidebarHelp = {
		title: 'Configurações do Robô',
		firstParagraph:
			'Nesta tela, você pode configurar e visualizar os parâmetros para a importação automatizada. Ajuste a planilha gerada pelo seu sistema de acordo com os parâmetros específicos de cada processo para automatizar a importação com eficiência.',
		secondParagraph:
			'Para configurar um processo de importação, selecione um "Processo de Automação" para carregar as propriedades necessárias que compõem esse processo. Após listar as colunas, escolha uma "Coluna do Processo" para relacioná-la com a coluna correspondente da sua planilha. Para visualizar as colunas da sua planilha, importe uma "Planilha Modelo" selecionando o arquivo desejado.',
		thirdParagraph:
			'Se necessário, é possível adicionar tratamentos nos campos para realizar alterações antes que o processo seja automatizado. Em seguida, basta clicar em "Vincular Colunas" para ajustar os relacionamentos "De - Para". Após preencher as informações, clique em "Salvar" para armazenar os dados.',
		firstLink: URL_CT360,
		secondLink: URL_FALE_CONOSCO,
	};

	useEffect(() => {
		setDataSidebar(helpConfigurarProcessos);
	}, []);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			{<FormBotSettings />}
		</Flex>
	);
}
