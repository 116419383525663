import { Auth } from 'aws-amplify';
import {
	Authenticator,
	Theme,
	ThemeProvider,
	useAuthenticator,
	useTheme,
	Flex,
	SelectField,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useGlobalContext } from 'contexts/GlobalContext';
import logoEasy360 from 'assets/images/logos/logo-easy360-white-and-colors.svg';
import {
	Box,
	Grid,
	Heading,
	Image,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import staticBackground from 'assets/images/background/bg-connections.webp';
import { DefaultComponents } from '@aws-amplify/ui-react/dist/types/components/Authenticator/hooks/useCustomComponents/defaultComponents';
import { TermsAndPolicyModal } from 'components/modal/TermsAndPolicyModal';
import { environmentVars } from 'services/api/variables/environment';

I18n.putVocabularies(translations);
I18n.setLanguage('pt');

const AutenticacaoPage = () => {
	const history = useHistory();
	const { pathCompanyName } = useGlobalContext();

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [checked, setChecked] = useState(false);

	const { user, route } = useAuthenticator((context) => [
		context.route,
		context.user,
	]);

	useEffect(() => {
		if (route === 'transition') {
			history.go(0);
		}
	}, [route]);

	const location: any = useLocation();

	let from = location.state?.from?.pathname || '/';
	const userGroups = user?.getSignInUserSession()?.getIdToken()?.payload?.[
		'cognito:groups'
	];

	useEffect(() => {
		if (route === 'authenticated') {
			console.log('userGroups', userGroups);
			if (userGroups?.includes('vendedor') && pathCompanyName) {
				history.replace(
					`/${pathCompanyName}/cadastros/usuarios/registrar`,
					true,
				);
			} else if (userGroups?.includes('representanteComercial')) {
				const token = user
					.getSignInUserSession()
					?.getIdToken()
					.getJwtToken();
				history.push({
					state: {
						externalUrl: `${environmentVars.procedo.url}${token}`,
					},
				});
				window.location.href = `${environmentVars.procedo.url}${token}`;
			} else {
				history.replace(`/${pathCompanyName}`, true);
			}
		}
	}, [route, history, from]);

	const formFields = {
		signIn: {
			username: {
				placeholder: 'Digite seu email aqui',
				isRequired: true,
				label: 'Email:',
			},
			password: {
				placeholder: 'Digite sua senha aqui',
				isRequired: true,
				label: 'Senha:',
			},
		},
		signUp: {
			email: {
				placeholder: 'Digite seu email aqui',
				isRequired: true,
				label: 'Email:',
				order: 6,
			},
			password: {
				placeholder: 'Digite sua senha aqui',
				isRequired: true,
				label: 'Senha:',
				order: 7,
			},
			confirm_password: {
				placeholder: 'Por favor, confirme sua senha aqui',
				isRequired: true,
				label: 'Confirme sua senha:',
				order: 8,
			},
			phone_number: {
				placeholder: 'Ex: (11) 99999-9999',
				isRequired: true,
				label: 'Telefone:',
				dialCode: '+55',
				order: 5,
			},
			name: {
				placeholder: 'Digite seu nome completo aqui',
				label: 'Nome completo:',
				order: 4,
			},
			'custom:razaoSocial': {
				placeholder: 'Digite a razão social aqui',
				isRequired: true,
				label: 'Razão Social:',
				order: 3,
			},
			'custom:CNPJ': {
				placeholder: 'Digite o CNPJ aqui',
				isRequired: true,
				label: 'CNPJ:',
				order: 2,
			},
		},
		resetPassword: {
			username: {
				placeholder: 'Digite seu email aqui',
				isRequired: true,
				label: 'Email:',
			},
		},
		confirmResetPassword: {
			confirmation_code: {
				placeholder: 'Digite o código de confirmação aqui',
				label: 'Código de confirmação:',
				isRequired: true,
			},
			password: {
				placeholder: 'Digite sua nova senha aqui',
				label: 'Nova senha:',
				isRequired: true,
			},
			confirm_password: {
				placeholder: 'Confirme sua nova senha aqui',
				label: 'Confirme sua nova senha:',
				isRequired: true,
			},
		},
		confirmVerifyUser: {
			confirmation_code: {
				placeholder: 'Digite o código de confirmação aqui',
				label: 'Código de confirmação:',
				isRequired: true,
			},
		},
	};

	const components: DefaultComponents = {
		Header() {
			return (
				<Box
					backgroundImage='linear-gradient(180deg, #065688 0%, #BBCFDC 100%)'
					borderTopRadius={'xl'}
				>
					<Image
						alt='Easy360 logo'
						src={logoEasy360}
						paddingTop={6}
						paddingX={6}
						paddingBottom={2}
					/>
				</Box>
			);
		},
		Footer() {
			return (
				<Box
					background={'#bdcfdb'}
					borderBottomRadius={'xl'}
					padding={2}
				></Box>
			);
		},
		SignIn: {
			Header() {
				const { tokens } = useTheme();
				return (
					<Box marginBottom={-2}>
						<Heading
							padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
							size={'md'}
						>
							Acesso Easy360
						</Heading>
						<Text
							padding={`${tokens.space.xs} 0 0 ${tokens.space.xl}`}
						>
							Seja bem-vindo a plataforma web completa
							<br />
							para a sua indústria.
						</Text>
					</Box>
				);
			},
		},

		SignUp: {
			FormFields() {
				return (
					<Grid
						templateColumns='repeat(2, 1fr)'
						rowGap={3}
						columnGap={5}
					>
						<SelectField
							label='Plano de assinatura:'
							placeholder='Selecione o plano aqui'
							name='custom:planoAssinatura'
						>
							<option value='0'>Gratuito</option>
							<option value='1'>Básico</option>
							<option value='2'>Avançado</option>
							<option value='3'>Mentoria</option>
						</SelectField>
						<Authenticator.SignUp.FormFields />
					</Grid>
				);
			},
		},

		ResetPassword: {
			Header() {
				return (
					<>
						<Heading size={'md'}>Redefinir sua senha</Heading>
						<Text>
							Insira seu email para receber um código de
							confirmação
						</Text>
					</>
				);
			},
		},

		ConfirmResetPassword: {
			Header() {
				return (
					<>
						<Heading size={'md'}>
							Insira seu código de confirmação e sua nova senha
						</Heading>
					</>
				);
			},
		},

		VerifyUser: {
			Header() {
				return (
					<>
						<Heading
							size={'md'}
							mb={2}
						>
							Verifique seu email para prosseguir
						</Heading>
						<Text
							textAlign={'justify'}
							mb={4}
						>
							<Text
								as={'span'}
								fontWeight={'bold'}
								color={'easyRED.300'}
							>
								Obs.{' '}
							</Text>
							Não pule esta etapa, pois sem a verificação do seu
							email você não conseguirá utilizar todos os recursos
							da plataforma.
						</Text>
					</>
				);
			},
		},

		ConfirmVerifyUser: {
			Header() {
				const { tokens } = useTheme();
				return (
					<>
						<Heading
							size={'md'}
							mb={2}
						>
							Digite seu código de verificação
						</Heading>
						<Text
							textAlign={'justify'}
							mb={2}
						>
							<Text
								as={'span'}
								fontWeight={'bold'}
								color={'easyRED.300'}
							>
								Obs.{' '}
							</Text>
							É importante que você não pule esta etapa, pois sem
							a verificação do seu email você não conseguirá
							utilizar todos os recursos da plataforma.
						</Text>
						<Text mb={2}>
							Ao continuar você aceita nossos{' '}
							<Text
								as={'span'}
								onClick={onOpen}
								_hover={{
									cursor: 'pointer',
								}}
								color={'#065688'}
								fontWeight={'500'}
							>
								Termos de Serviço e Política de Privacidade
							</Text>
						</Text>
						<TermsAndPolicyModal
							isOpen={isOpen}
							onClose={onClose}
							setChecked={setChecked}
							checked={checked}
						/>
					</>
				);
			},
		},
	};

	const theme: Theme = {
		name: 'Auth Example Theme',
		tokens: {
			colors: {
				background: {
					secondary: {
						value: '#bdcfdb',
					},
				},
				font: {
					interactive: {
						value: '#0789C1',
					},
				},
				brand: {
					primary: {
						'10': '#DFF0FF',
						'80': '#056690',
						'90': '#044663',
						'100': '#044663',
					},
				},
			},
			components: {
				tabs: {
					item: {
						_focus: {
							color: {
								value: '#314866',
							},
						},
						_hover: {
							color: {
								value: '#F0243E',
							},
						},
						_active: {
							color: {
								value: '#0789C1',
							},
						},
						paddingVertical: {
							value: '5px',
						},
					},
				},
				fieldcontrol: {
					borderRadius: {
						value: '10px',
					},
				},
				authenticator: {
					router: {
						borderStyle: {
							value: 'none',
						},
					},
					container: {
						widthMax: {
							value: `${route === 'signUp' ? '550px' : '400px'}`,
						},
					},
					modal: {
						backgroundColor: {
							value: 'transparent',
						},
					},
				},
			},
		},
	};

	return (
		<ThemeProvider theme={theme}>
			<Flex
				height='100vh'
				width='100vw'
				justifyContent='center'
				alignItems='center'
			>
				<Image
					alt='Easy360 Background'
					src={staticBackground}
					position='absolute'
					top={0}
					right={0}
					bottom={0}
					left={0}
					width='100%'
					height='100%'
					objectFit='cover'
					zIndex={-1000}
				/>
				<Authenticator
					loginMechanisms={['email']}
					variation='modal'
					services={{
						async handleSignIn(formData) {
							try {
								const { username, password } = formData;
								const result = await Auth.signIn(
									username,
									password,
								);

								window.localStorage.removeItem('logout');
								return result;
							} catch (error: any) {
								// Tratamentos de erros para o usuário em português
								switch (error.message) {
									case 'User is not confirmed.':
										throw new Error(
											'Usuário não confirmado!',
										);
									case 'User does not exist.':
										throw new Error('Usuário não existe!');
									case 'Incorrect username or password.':
										throw new Error(
											'Nome de usuário ou senha incorretos!',
										);
									case 'User is disabled.':
										throw new Error('Usuário desativado!');
									default:
										throw new Error(error.message);
								}
							}
						},
					}}
					formFields={formFields}
					components={components}
					hideSignUp
					signUpAttributes={['phone_number', 'name', 'gender']}
				></Authenticator>
			</Flex>
		</ThemeProvider>
	);
};

export default AutenticacaoPage;
