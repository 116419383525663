import React, { useEffect } from 'react';

// chakra imports
import {
	Box,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerOverlay,
	Flex,
	HStack,
	Icon,
	IconButton,
	Image,
	Link,
	useColorModeValue,
	useDisclosure,
} from '@chakra-ui/react';

import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { renderThumb, renderTrack, renderView } from '../scrollbar/Scrollbar';

// Assers
import { IconMenu2 } from '@tabler/icons';
import { IoMenuOutline } from 'react-icons/io5';

// Custom components
import logoEasyMenu from '../../assets/images/logos/logo-easy360-red-and-blue.svg';
import { HSeparator } from '../separator/Separator';

import Content from './components/Content';

// FUNCTIONS
//** Menu principal da página
export function Sidebar(props) {
	const { routes, sidebarState, setSidebarState, sidebarHelpState } = props;

	// Chakra Color Mode
	let sidebarBgColor = useColorModeValue('easyBLUE.100', 'navy.800');
	let buttonBgColor = useColorModeValue('easyBrandMenu.100', 'navy.800');
	let buttonColor = useColorModeValue('easyBLUE.300', 'white');
	let buttonBorderColor = useColorModeValue('easyBrandMenu.200', 'navy.800');
	let sidebarRadius = '30px';
	let sidebarMargins = '0px';

	// this is for the rest of the collapses
	let variantChange = '0.2s linear';
	let shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
		'unset',
	);

	//**
	//FUNCTION: Sidebar Change Size

	useEffect(() => {
		window.onresize = function () {
			let w =
				window.innerWidth ||
				document.documentElement.clientWidth ||
				document.body.clientWidth;
			if (w < 1200) {
				setSidebarState('closed');
			} else {
				setSidebarState('opened');
			}
		};
	}, []);

	// SIDEBAR
	// Componente: MENU
	return (
		<Box
			// display={{ xl: "block" }}

			// display={{ xl: "block"}}
			position='fixed'
			minH='100%'
		>
			<Box
				// ■ ação de abertura do menu ao clicar em qualquer local do menu quando esse estiver com o estado retraído.
				onClick={() => {
					if (sidebarState === 'closed') setSidebarState('opened');
				}}
				// ■ alteração do cursor para pointer quando o menu estiver retraído.
				cursor={sidebarState === 'closed' ? 'pointer' : null}
				bg={sidebarBgColor}
				transition={variantChange}
				//Propriedade: WIDTH, altera o tamanho do MENU
				//Vericiar se a largura do Menu está correta
				// w= "285px"
				w={sidebarState === 'opened' ? '290px' : '76px'}
				ms={{
					sm: '16px',
				}}
				my={{
					sm: '16px',
				}}
				h='calc(100vh - 32px)'
				m={sidebarMargins}
				borderRadius={sidebarRadius}
				minH='100%'
				overflowX='hidden'
				boxShadow={shadow}
			>
				{/** Componente de rolagem do menu */}
				<Scrollbars
					// autoHide
					renderTrackVertical={renderTrack}
					renderThumbVertical={renderThumb}
					renderView={renderView}
				>
					{/** Brand */}
					<Flex
						align='center'
						justifyContent='space-between'
						pt='15px'
						w='100%'
					>
						<HStack
							mb='auto'
							ps='10px'
							ml='10px'
							my='22px'
						>
							{/* COMPONENTE DO MENU: botão */}
							<IconButton
								onClick={() => {
									if (sidebarState === 'closed') {
										setSidebarState('opened');
									} else {
										setSidebarState('closed');
									}
								}}
								variant='outline'
								icon={<IconMenu2 />}
								colorScheme={buttonColor}
								borderColor={buttonBorderColor}
								size='sm'
								borderRadius='10px'
								_hover={{ cursor: 'pointer' }}
								bg={
									sidebarState === 'opened'
										? buttonBgColor
										: '#E9EDF7'
								}
								mr={'20px'}
							/>
							{/******* COMPONENTE DO MENU: logo Easy360 ***/}
							<Link href='/'>
								<Image
									src={logoEasyMenu}
									alt='Easy360'
									w='164px'
									h='59px'
									display={
										sidebarState === 'opened'
											? 'flex'
											: 'none'
									}
								/>
							</Link>
						</HStack>
					</Flex>
					{/******* COMPONENTE DO MENU: separador entre componentes do MENU ***/}
					<HSeparator
						mb='20px'
						bg='#E9EDF7'
					/>
					<Content
						routes={routes}
						sidebarState={sidebarState}
						sidebarHelpState={sidebarHelpState}
					/>
				</Scrollbars>
			</Box>
		</Box>
	);
}

// FUNCTIONS - responsividade
//** Menu responsivo que some da tela quando a mesma fica com um tamanho menor */
export function SidebarResponsive(props) {
	let sidebarBackgroundColor = useColorModeValue('easyBLUE.100', 'navy.800');
	let menuColor = useColorModeValue('easyBLUE.300', 'white');
	// // SIDEBAR
	const { isOpen, onOpen, onClose } = useDisclosure();
	const btnRef = React.useRef();

	const { routes } = props;
	// let isWindows = navigator.platform.startsWith("Win");
	//  BRAND
	return (
		<Flex
			display={{ sm: 'flex', xl: 'none' }}
			alignItems='center'
		>
			<Flex
				// @ts-ignore
				ref={btnRef}
				w='max-content'
				h='max-content'
				onClick={onOpen}
			>
				{/** Botão do menu responsivo*/}
				<Icon
					as={IoMenuOutline}
					color={menuColor}
					my='auto'
					w='20px'
					h='20px'
					me='10px'
					_hover={{ cursor: 'pointer' }}
				/>
			</Flex>

			{/** Componente que some com o menu quando o tamanho da tela fica menor [responsividade]
			 * Foi usado o drawer, pois este componente aparece na tela estilo modal
			 */}
			<Drawer
				isOpen={isOpen}
				onClose={onClose}
				placement={
					document.documentElement.dir === 'rtl' ? 'right' : 'left'
				}
				finalFocusRef={btnRef}
			>
				<DrawerOverlay />
				<DrawerContent
					w='285px'
					maxW='285px'
					ms={{
						sm: '16px',
					}}
					my={{
						sm: '16px',
					}}
					borderRadius='16px'
					bg={sidebarBackgroundColor}
				>
					{/** uma vez que o menu some da tela, quando ele retorna tem um botão de sair para fechar o menu quando necessário */}
					<DrawerCloseButton
						zIndex='3'
						onClose={onClose}
						_focus={{ boxShadow: 'none' }}
						_hover={{ boxShadow: 'none' }}
					/>
					<DrawerBody
						maxW='285px'
						px='0rem'
						pb='0'
					>
						<Scrollbars
							autoHide
							renderTrackVertical={renderTrack}
							renderThumbVertical={renderThumb}
							renderView={renderView}
						>
							<Content routes={routes} />
						</Scrollbars>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</Flex>
	);
}
// PROPS

Sidebar.propTypes = {
	logoText: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object),
	variant: PropTypes.string,
};

export default Sidebar;
