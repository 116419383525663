import { Flex } from '@chakra-ui/react';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import TemplateCadastrarEditarUsuarios from 'components/forms/TemplateCadastrarEditarUsuarios';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useGetUsersById } from 'services/queryClient/wrapperHooks/authentication/useGetUsersById';
import { useParams } from 'react-router-dom';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

export default function EditarUsuarios() {
	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const { id: userId } = useParams<any>();

	const [selectedUserTypes, setSelectedUserTypes] = useState([]);
	const [additionalAutocompleteData, setAdditionalAutocompleteData] =
		useState({});

	const { data: DataUserById } = useGetUsersById(
		userId,
		setSelectedUserTypes,
	);

	const [initialState, setInitialState] = useState<any>();

	const helpCadastroUsuarios: SidebarHelp = useMemo(
		() => ({
			title: 'Editar Usuário',
			firstParagraph:
				'Para editar um Usuário, entre com as informações básicas dele como Nome, Telefone Comercial, CEP e uma senha temporária. Observação: uma vez alterado um usuário com seu respectivo e-mail, ele não poderá utilizar o mesmo e-mail para acessar outra conta da easy360.',
			secondParagraph:
				'Em seguida, informe o perfil do usuário, sendo permitido escolher entre Comercial, Marketing, Planejamento e Produção. Caso deseje que algum usuário possua mais de um perfil, selecione mais de um perfil. Observação: o perfil do usuário gerencia as telas e funcionalidades que cada usuário possui permissão de visualizar/editar.',
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpCadastroUsuarios);
	}, [helpCadastroUsuarios, setDataSidebar]);

	useEffect(() => {
		if (DataUserById) {
			setInitialState({
				email: DataUserById?.email,
				nome: DataUserById?.nome,
				telefone: DataUserById?.telefone?.replace(
					/(\d{2})(\d{5})(\d{4})/,
					'($1) $2-$3',
				),
				cep: DataUserById?.cep?.replace(/(\d{5})(\d)/, '$1-$2'),
				numero: DataUserById?.numero,
				complemento: DataUserById?.complemento,
				grupos: DataUserById?.perfil?.map((type) => {
					return {
						value: type.grupo_usuarios?.id,
						label: type.grupo_usuarios?.descricao,
					};
				}),
				bairro: DataUserById?.bairro,
				rua: DataUserById?.rua,
				perfil: DataUserById?.perfil?.map((type) => {
					return {
						value: type.grupo_usuarios?.id,
						label: type.grupo_usuarios?.descricao,
					};
				}),
				membro_id: DataUserById?.membro_id,
			});

			setAdditionalAutocompleteData({
				membro: DataUserById?.membro,
			});
		}
	}, [DataUserById]);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<TemplateCadastrarEditarUsuarios
				initialState={initialState}
				userId={userId}
				selectedUserTypes={selectedUserTypes}
				setSelectedUserTypes={setSelectedUserTypes}
				additionalAutocompleteData={additionalAutocompleteData}
			/>
		</Flex>
	);
}
