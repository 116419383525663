import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
// Chakra imports
import { Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';
import CheckTable from 'components/dataTables/CheckTable';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import { columnsDataColaboracao } from './variables/columnsData';
import { AlertComponent } from 'components/alerts/AlertComponent';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

interface CollabObject {
	areaEmpresaId: number;
	colaborador: string;
	status: 'Completa' | 'Pendente';
	ano: number;
	cicloId: number;
	ciclo: string;
}
interface CollabList {
	area_empresa_id: number;
	nome_area_empresa: string;
	colaboracao_concluida: boolean;
	ano: number;
	ciclo_id: number;
	ciclo: string;
	colaboracoes_feitas: number;
	possui_rateio: boolean;
}

interface DataCollab {
	ciclo_sem_rateio: boolean;
	dados: CollabList[];
	mapaFiltros: any;
	total_linhas: number;
}

export default function Colaboracao() {
	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;
	const tableColumns = useMemo(() => columnsDataColaboracao, []);
	const [dataCollab, setDataCollab] = useState<DataCollab>();

	const helpCollab: SidebarHelp = useMemo(
		() => ({
			title: 'Colaborações',
			firstParagraph: `Etapa destinada a colaboração dos especialistas de negócios da empresa, representados pelas áreas: comercial, marketing e planejamento.`,
			secondParagraph: `Cada especialista deve realizar sua colaboração de maneira independente no horizonte de tempo definido, sem sofrer qualquer tipo de influência, considerando todas as informações [quanti e quali] que possam auxiliá-los nesse exercício.`,
			thirdParagraph: `Acesse o botão para realizar sua colaboração.`,
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpCollab);
	}, [helpCollab, setDataSidebar]);

	const renderRow = useCallback((collaboration: CollabList): CollabObject => {
		return {
			areaEmpresaId: collaboration.area_empresa_id,
			colaborador: collaboration.nome_area_empresa,
			status: collaboration.colaboracao_concluida
				? 'Completa'
				: 'Pendente',
			ano: collaboration.ano,
			cicloId: collaboration.ciclo_id,
			ciclo: collaboration.ciclo,
		};
	}, []);

	const renderAlertNotHaveApportionment = (data: DataCollab | undefined) => {
		if (!!data && data?.ciclo_sem_rateio) {
			return (
				<AlertComponent
					title={'Atenção!'}
					status={'warning'}
					description={
						'Existem ciclos sem rateio calculado. Importante revisá-los!'
					}
					hasCloseButton
				/>
			);
		}
	};

	const renderTable = () => {
		return (
			<CheckTable
				dataTest='button-filtrar-colaboracoes'
				module={'colaboracao'}
				columnsData={tableColumns}
				showGenericSearchInput={false}
				filterModule='colaboracao'
				renderRow={renderRow}
				noButton
				setData={setDataCollab}
				pt={14}
				tableTest='tabela-colaboracoes'
			/>
		);
	};

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card>
				{renderAlertNotHaveApportionment(dataCollab)}
				{renderTable()}
			</Card>
		</Flex>
	);
}
