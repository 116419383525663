import { useGlobalContext } from 'contexts/GlobalContext';
import { RequireAuth } from 'contexts/RequireAuth';
import AdminDashboard from 'layouts/adminEasy360';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AdminLayout from './layouts/admin';
import AuthLayout from './layouts/auth';
import SystemOfflineNotice from 'pages/admin/erros/systemOfflineNotice';

// rotas autenticadas e rotas não autenticadas ou rota de autenticação (ex., na tela de login)

const AppRoutes: React.FC = () => {
	const { pathCompanyName, permissionsPage } = useGlobalContext();

	return (
		<Switch>
			<Route
				path={`/auth`}
				component={AuthLayout}
			/>
			<Route
				path={`/offline`}
				component={SystemOfflineNotice}
			/>
			{/* 🗝️ Componente abaixo responsável por bloquear o acesso a plataforma, deixar comentado para desenvolvimento e apenas descomentar se for utilizar autenticação */}
			<RequireAuth>
				{pathCompanyName && Object.keys(permissionsPage).length > 0 && (
					<>
						<Route
							path={`/${pathCompanyName}`}
							component={AdminLayout}
						/>
					</>
				)}
				<Route
					path={`/adminEasy360`}
					component={AdminDashboard}
				/>
			</RequireAuth>
		</Switch>
	);
};

export default AppRoutes;
