import { Flex } from '@chakra-ui/react';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useGetBudgetWithId } from 'services/queryClient/wrapperHooks/useGetBudgetById';
import { useParams } from 'react-router-dom';
import Card from 'components/card/Card';
import FormCadastrarEditarOrcamento from 'components/forms/FormCadastrarEditarOrcamento';
import moment from 'moment';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

export default function EditarOrcamento() {
	const { setters } = useContext(SidebarHelpContext);
	const { setDataSidebar } = setters;

	const { id: budgetId } = useParams<any>();

	const { data: BudgetData } = useGetBudgetWithId(budgetId);

	const [initialState, setInitialState] = useState<any>();
	const [autocompleteAdditionalData, setAutocompleteAditionalData] =
		useState<any>();

	const helpEditarOrcamento: SidebarHelp = useMemo(
		() => ({
			title: 'Editar Orçamento',
			firstParagraph: `Para editar um orçamento, selecione a Competência desejada e entre com os dados do Canal de Venda, Família de produto, quantidade e receita bruta.`,
			secondParagraph: `Caso deseje alterar o canal de venda, tenha certeza de ter cadastrado previamente os canais de venda em Cadastros > Comercial > Hierarquia, bem como as famílias de produtos em Cadastros > Produtos > Família.`,
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpEditarOrcamento);
	}, [helpEditarOrcamento, setDataSidebar]);

	useEffect(() => {
		if (BudgetData) {
			setAutocompleteAditionalData({
				family: BudgetData?.familia,
			});

			setInitialState({
				mes_ano: moment(BudgetData?.mes_ano, 'YYYY-MM')?.format(
					'YYYY-MM',
				),
				canal_venda_id: BudgetData?.canal_venda_id,
				familia_id: BudgetData?.familia_id,
				quantidade: BudgetData?.quantidade,
				receita_bruta: BudgetData?.receita_bruta
					? BudgetData?.receita_bruta
					: 0,
			});
		}
	}, [BudgetData]);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				<FormCadastrarEditarOrcamento
					initialState={initialState}
					budgetId={budgetId}
					autocompleteAdditionalData={autocompleteAdditionalData}
				/>
			</Card>
		</Flex>
	);
}
