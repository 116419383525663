import { Flex, Grid } from '@chakra-ui/react';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import { useContext, useEffect } from 'react';
import Cartao from './components/Plano/Cartao';
import CicloDoPlano from './components/Plano/CicloDoPlano';
import PlanoDetalhe from './components/Plano/PlanoDetalhe';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

export default function MinhaConta() {
	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const helpCadastroOportunidades: SidebarHelp = {
		title: 'Minha Conta',
		firstParagraph:
			'Nesta tela é possível gerenciar e visualizar todas as informações relacionadas a sua Conta na Easy360, incluindo dados do Plano contratado, método de Pagamento utilizado e dados Cadastrais da Empresa.',
		secondParagraph:
			'Observação: as funcionalidades completas desta tela estão em construção, em caso de dúvida nos contate.',
		firstLink: URL_CT360,
		secondLink: URL_FALE_CONOSCO,
	};

	useEffect(() => {
		setDataSidebar(helpCadastroOportunidades);
	}, []);
	return (
		<Flex
			direction={{ base: 'column' }}
			pt={{ base: '130px', md: '80px', xl: '80px' }}
		>
			<Grid
				gap='20px'
				gridTemplateColumns='repeat(3, 1fr)'
				mb='20px'
			>
				<Flex>
					<CicloDoPlano />
				</Flex>
				<Flex>
					<Cartao></Cartao>
				</Flex>
				<Flex>
					<PlanoDetalhe />
				</Flex>
			</Grid>
		</Flex>
	);
}
