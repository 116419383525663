import {
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import CheckTable from 'components/dataTables/CheckTable';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import { useContext, useEffect, useMemo } from 'react';
import { URL_CT360, URL_FALE_CONOSCO } from '../../constants/global';

const CheckTableModal = ({
	filterModule,
	isOpen,
	onClose,
	columnsData,
	modalTitle,
	renderRow,
	simpleDelete,
	deleteMany,
	deleteAll,
	queriesToInvalidadeOnRemove,
	queriesToInvalidadeOnEdit,
}) => {
	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const tableColumns = useMemo(() => columnsData, [columnsData]);

	const helpMarket: SidebarHelp = useMemo(
		() => ({
			title: modalTitle,
			firstParagraph: `Nesta tela é possível visualizar ${modalTitle}, refletindo a organização comercial do seu negócio, contendo todos os níveis de iteração.`,
			secondParagraph: '',
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[modalTitle],
	);

	const dataDelete = useMemo(() => simpleDelete, [simpleDelete]);

	const deleteMessage = useMemo(() => deleteMany, [deleteMany]);

	const dataDeleteAll = useMemo(() => deleteAll, [deleteAll]);

	useEffect(() => {
		setDataSidebar(helpMarket);
	}, [helpMarket, setDataSidebar]);

	const renderTable = (): JSX.Element => {
		return (
			<CheckTable
				module={filterModule}
				columnsData={tableColumns}
				dataDelete={dataDelete}
				filterModule={filterModule}
				renderRow={renderRow}
				deleteMessage={deleteMessage}
				bulkDeleteProps={dataDeleteAll}
				showGenericSearchInput={false}
				queriesToInvalidadeOnRemove={queriesToInvalidadeOnRemove}
				queriesToInvalidadeOnEdit={queriesToInvalidadeOnEdit}
				renderedInModal={true}
			/>
		);
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			onEsc={onClose}
			size='6xl'
			isCentered
		>
			<ModalOverlay />
			<ModalContent
				borderRadius={20}
				pt='20px'
			>
				<ModalHeader>{modalTitle}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Flex
						direction='column'
						pt={{ sm: '100px', lg: '60px' }}
					>
						{renderTable()}
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default CheckTableModal;
