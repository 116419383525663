import { Flex } from '@chakra-ui/react';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useGetForecastWithId } from 'services/queryClient/wrapperHooks/forecast/useGetForecastById';
import { useParams } from 'react-router-dom';
import FormCadastrarEditarForecast from 'components/forms/FormCadastrarEditarForecast';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

export default function EditarForecast() {
	const { setters } = useContext(SidebarHelpContext);
	const { setDataSidebar } = setters;

	const { id: forecastId } = useParams<any>();

	const { data: ForecastData } = useGetForecastWithId(forecastId);

	const [initialState, setInitialState] = useState<any>();
	const [autocompleteAdditionalData, setAutocompleteAditionalData] =
		useState<any>();

	const helpEditarForecast: SidebarHelp = useMemo(
		() => ({
			title: 'Editar Forecast',
			firstParagraph: `Para editar os dados de um forecast, selecione a Competência desejada e entre com os dados do Canal de Venda e Cliente.`,
			secondParagraph: `Caso deseje alterar o canal de venda, tenha certeza de ter cadastrado previamente os canais de venda em Cadastros > Comercial > Hierarquia.`,
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpEditarForecast);
	}, [helpEditarForecast, setDataSidebar]);

	useEffect(() => {
		if (ForecastData) {
			setAutocompleteAditionalData({
				client: ForecastData?.cliente,
			});

			setInitialState({
				codigo: ForecastData?.codigo,
				sequencia_codigo: ForecastData?.sequencia_codigo,
				data: ForecastData?.data,
				cliente_id: ForecastData?.cliente?.id,
				canal_venda_id: ForecastData?.canalVenda?.id,
				receita_bruta: ForecastData?.receita_bruta
					? ForecastData?.receita_bruta
					: 0,
			});
		}
	}, [ForecastData]);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<FormCadastrarEditarForecast
				initialState={initialState}
				forecastId={forecastId}
				autocompleteAdditionalData={autocompleteAdditionalData}
			/>
		</Flex>
	);
}
