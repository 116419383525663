import { ButtonGroup, Flex, useDisclosure } from '@chakra-ui/react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import Card from 'components/card/Card';
import CheckTable from 'components/dataTables/CheckTable';
import { columnsDataCarteira } from './variables/columnsDataCarteira';
import { FileUploadModal } from '../../../../../components/modal/fileUpload/FileUploadModal';
import moment from 'moment';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDownloadTemplatePortfolio } from '../../../../../services/queryClient/wrapperHooks/uploads/useDownloadTemplatePortfolio';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useHistory } from 'react-router-dom';
import { useUploadPortfolio } from 'services/queryClient/wrapperHooks/uploads/useUploadPortfolio';
import {
	documentFormatters,
	DocumentType,
} from 'utils/functions/formatDocuments';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

interface Portfolio {
	id: number;
	codigo: string;
	sequencia_codigo: number;
	data: string;
	nome_canal_venda: string;
	razao_social: string;
	cpf: string;
	cnpj: string;
	nome_familia: string;
	codigo_produto: string;
	quantidade: number;
	receita_bruta: number;
	ruim: boolean;
	faturado: boolean;
	data_registro: string;
	tipo_documento: DocumentType;
	documento: string;
}

export default function RotinaCarteira() {
	const { isOpen, onOpen, onClose } = useDisclosure();

	let history = useHistory();

	const { pathCompanyName } = useGlobalContext();

	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const tableColumns = useMemo(() => columnsDataCarteira, []);
	const [hasBaseData, setHasBaseData] = useState<boolean>(false);

	const helpPortfolio: SidebarHelp = useMemo(
		() => ({
			title: 'Carteira de Pedidos',
			firstParagraph: `Nesta tela é possível visualizar todas as Carteiras de Pedidos no tempo, indicando o conjunto de clientes que possui relacionamento com seu negócio.`,
			secondParagraph: `A carteira boa se refere a uma venda já realizada, apenas faltando a emissão da nota fiscal. Uma vez emitida a NF, clique no botão de Faturado para indicar o fechamento completo da venda. Enquanto, carteira ruim de pedidos envolve pedidos que possuem falta de crédito ou indisponibilidade de MP.`,
			thirdParagraph: `Acesse o botão “Cadastrar Carteira” para adicionar novas carteira de clientes.`,
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	const dataDelete = useMemo(
		() => ({
			provider: 'portfolio',
			toastSuccessTitle: 'Excluida com sucesso!',
			toastSuccessDescription:
				'A carteira de pedido foi excluida com sucesso.',
			toastErrorTitle: 'Erro ao excluir carteira de pedido!',
			title: 'Deseja realmente excluir esta Carteira de Pedido?',
			text: [
				'Você está prestes a excluir esta esta carteira de pedido da sua base de cadastros.',
				'',
				'Você tem certeza que deseja excluir?',
			],
			invalidate: 'carteiraPedido-list',
		}),
		[],
	);

	const deleteMessage = useMemo(
		() => [
			'Você está prestes a excluir estas carteiras de pedidos da sua base de cadastros.',
			'',
		],
		[],
	);

	const dataDeleteAll = useMemo(
		() => ({
			provider: 'portfolio',
			toastSuccessTitle: 'Registros excluídos com sucesso!',
			toastSuccessDescription:
				'As carteiras de pedidos foram excluídas com sucesso',
			toastErrorTitle: 'Erro ao excluir carteiras de pedidos!',
			invalidate: 'carteiraPedido-list',
		}),
		[],
	);

	const goToCadastroCarteira = useCallback(() => {
		history.push(`/${pathCompanyName}/rotina/carteira/criar`);
	}, [history, pathCompanyName]);

	const renderRow = useCallback((portfolio: Portfolio) => {
		return {
			id: portfolio?.id,
			codigoCarteiraPedido: portfolio?.codigo,
			sequenciaCodigoCarteiraPedido: portfolio?.sequencia_codigo,
			competencia: portfolio?.data
				? moment(portfolio.data, 'YYYY-MM-DD').format('DD/MM/YYYY')
				: '',
			canalVenda: portfolio?.nome_canal_venda,
			cliente: portfolio?.razao_social,
			cnpjCpfId: documentFormatters(
				portfolio?.tipo_documento,
				portfolio.documento,
			),
			familia: portfolio?.nome_familia,
			codigoProduto: portfolio?.codigo_produto,
			quantidade: portfolio?.quantidade?.toLocaleString('pt-BR'),
			receitaBruta: portfolio?.receita_bruta?.toLocaleString('pt-BR', {
				style: 'currency',
				currency: 'BRL',
			}),
			ruim: portfolio?.ruim,
			faturado: portfolio?.faturado,
			dataRegistro: portfolio?.data_registro
				? moment
						.utc(portfolio.data_registro)
						.local()
						.format('DD/MM/YYYY HH:mm:ss')
				: '',
		};
	}, []);

	useEffect(() => {
		setDataSidebar(helpPortfolio);
	}, [helpPortfolio, setDataSidebar]);

	const renderButtons = useCallback(() => {
		return (
			<Flex
				flexDirection={'row'}
				justifyContent={'flex-end'}
				mt={'20px'}
				me={'20px'}
				mb={'40px'}
			>
				<ButtonGroup spacing={'20px'}>
					<ButtonComponent
						data-test={'button-importar-carteira_de_pedidos'}
						type={'ghost'}
						title={'Importar Carteiras'}
						onClick={onOpen}
					/>
					<FileUploadModal
						isOpen={isOpen}
						onClose={onClose}
						headerText={'Importar Carteiras'}
						module={'carteira'}
						useDownloadTemplate={useDownloadTemplatePortfolio}
						stringInvalidQuery='carteiraPedido-list'
						useUpload={useUploadPortfolio}
						showLineRestricts={false}
						columnsData={tableColumns}
						hasBaseData={hasBaseData}
					/>
					<ButtonComponent
						data-test={'button-cadastrar-carteira_de_pedidos'}
						type={'primary'}
						title={'Cadastrar Carteira'}
						onClick={goToCadastroCarteira}
					/>
				</ButtonGroup>
			</Flex>
		);
	}, [
		goToCadastroCarteira,
		isOpen,
		onClose,
		onOpen,
		tableColumns,
		hasBaseData,
	]);

	function renderTable() {
		return (
			<CheckTable
				dataTest='button-filtrar-carteira_de_pedidos'
				module={'Carteiras'}
				linkEditForm={`/${pathCompanyName}/rotina/carteira/editar`}
				columnsData={tableColumns}
				dataDelete={dataDelete}
				deleteMessage={deleteMessage}
				showGenericSearchInput={false}
				filterModule='carteiraPedido'
				bulkDeleteProps={dataDeleteAll}
				renderRow={renderRow}
				setHasBaseData={setHasBaseData}
			/>
		);
	}

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				{renderButtons()}
				{renderTable()}
			</Card>
		</Flex>
	);
}
