import { CircularProgress, Flex } from '@chakra-ui/react';
import { AlertComponent } from 'components/alerts/AlertComponent';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import Card from 'components/card/Card';
import ManagementTable from 'components/dataTables/ManagementTable';
import { useGlobalContext } from 'contexts/GlobalContext';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetUsersList } from 'services/queryClient/wrapperHooks/userManagement/useGetUsers';
import { tableColumnsManagement } from './variables/tableColumnsManagement';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

// Chakra imports
export default function CadastroPerfil() {
	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const helpUsuarios: SidebarHelp = {
		title: 'Gerenciamento de Usuários',
		firstParagraph:
			'Nesta tela é possível cadastrar e visualizar todas os Usuários que possuem acesso a easy360 da sua indústria. Por padrão, é permitido o cadastro de até 5 usuários, além do usuário administrador.',
		secondParagraph:
			'Acesse o botão “Cadastrar Usuário” para adicionar um0 novo usuário a sua easy360.',
		firstLink: URL_CT360,
		secondLink: URL_FALE_CONOSCO,
	};

	useEffect(() => {
		setDataSidebar(helpUsuarios);
	}, []);

	const history = useHistory();

	const { pathCompanyName } = useGlobalContext();
	function goToCadastrarUsuarios() {
		history.push(`/${pathCompanyName}/gerenciamentoUsuarios/criar`);
	}

	const dataDelete = {
		provider: 'user',
		toastSuccessTitle: 'Excluido com sucesso!',
		toastSuccessDescription: 'O usuário foi excluido com sucesso.',
		toastErrorTitle: 'Erro ao excluir usuário!',
		title: 'Deseja realmente excluir este usuário?',
		text: 'Você está prestes a excluir este usuário. Todos os produtos viculados à ele precisarão ser vinculados à outro usuário. Tem certeza que deseja excluir?',
		invalidate: 'users-list',
	};

	const { data, isLoading, isError } = useGetUsersList();

	data &&
		data[0]?.forEach((user) => {
			if (user.status === true) {
				user.status = 'ativo';
			} else if (user.status === false) {
				user.status = 'inativo';
			}
		});

	const formattedDataUsers =
		data &&
		data[0]?.map((user: any) => {
			return {
				id: user.id,
				usuario: user.nome,
				login: user.email,
				tipo: user.perfil?.map((grupo: any) => {
					return grupo.grupo_usuarios?.descricao;
				}),
				status: user.status,
			};
		});

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			{data &&
				data[1] &&
				data[1].quantidadeUsuarios ===
					data[1].quantidadeUsuariosPermitidos && (
					<AlertComponent
						title='Atenção!'
						description='Você já atingiu o limite de usuários permitidos para sua empresa. Para adicionar mais usuários, será necessário deletar algum usuário já existente ou contratar um plano que permita mais usuários.'
						status='warning'
					/>
				)}
			<Card px='0px'>
				<Flex
					flexDirection={'row'}
					justifyContent={'flex-end'}
					mt={7}
					mx={5}
				>
					<ButtonComponent
						type={'primary'}
						title={'Cadastrar Usuário'}
						onClick={goToCadastrarUsuarios}
						data-test='button-cadastrar-gerenciamento_usuarios'
					/>
				</Flex>
				{isLoading && (
					<CircularProgress
						isIndeterminate
						color='red.600'
					/>
				)}
				{isError && <h1>Ocorreu um erro, tente novamente!</h1>}
				{data && data[0] && (
					<ManagementTable
						tableData={formattedDataUsers}
						columnsData={tableColumnsManagement}
						linkEditForm={`/${pathCompanyName}/gerenciamentoUsuarios/editar/usuarios`}
						dataDelete={dataDelete}
					/>
				)}
			</Card>
		</Flex>
	);
}
