import {
	ButtonGroup,
	Flex,
	FormControl,
	IconButton,
	List,
	Text,
	Select,
	useDisclosure,
	ListItem,
	ListIcon,
	CheckboxGroup,
	Checkbox,
	Stack,
	Tooltip,
	Spinner,
	Tab,
	TabList,
	Tabs,
	TabPanels,
	TabPanel,
	useTheme,
} from '@chakra-ui/react';
import {
	IconAlarmOff,
	IconAlertTriangle,
	IconClock,
	IconInfoCircle,
	IconRefreshAlert,
	IconSquareX,
} from '@tabler/icons';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import Card from 'components/card/Card';
import LineChart from 'components/chart/LineChart';
import AtaModalProduction from 'components/modal/AtaModalProduction';
import {
	useCallback,
	useContext,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import MultiSelect, {
	Option,
	Styles,
} from 'components/multiSelect/Multiselect';
import { MultiValue } from 'react-select';
import {
	donutChartOptions,
	groupedStackedBarChartOptions,
	lineChartOptions,
} from './variables/charts';

import { ModalFamiliaEstoque } from './ModalFamiliaEstoque';
import { useProductionDashboardOptionsFilter } from 'services/queryClient/wrapperHooks/dashboardProducao/useProductionDashboardOptionsFilter';
import {
	useProductionDashboardData,
	useProductionDashboardDataByFamilyStock,
	useProductionDashboardDataByProductStock,
	useProductionDashboardDataByProjectionProductStock,
} from 'services/queryClient/wrapperHooks/dashboardProducao/useProductionDashboardData';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';

import { AlertComponent } from 'components/alerts/AlertComponent';
import { RecalculateIROG } from 'components/modal/RecalculateIROG';
import { useGlobalContext } from 'contexts/GlobalContext';
import ManualPaging from 'components/dataTables/ManualPaging';
import { renderCustomTooltipForGroupedStackedBar } from 'utils/functions/charts';
import { TooltipStandard } from 'components/tooltip/TooltipStandard';
import DashboardProducaoProvider, {
	useDashboardProducaoContext,
} from './context';
import GenericAutocomplete from 'components/autocomplete/GenericAutocomplete';

import './dashboardProducao.css';
import { renderFamily, renderProduct } from 'utils/forms/autocomplete';
import GroupedStackedBarChart from 'components/chart/GroupedStackedBarChart';
import moment from 'moment';
import DonutChart from 'components/chart/DonutChart';
import GenericInput from 'components/inputs/GenericInput';
import { useFamilyListAll } from 'services/queryClient/wrapperHooks/useFamilyListAll';
import { InputFormLabel } from 'components/fields/InputFormLabel';
import { LoadingSpinner } from 'components/loading/Loading';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

const defaultCharts = {
	indicadoresIrog: [],
	mediaMeta: [],
	estoque: [],
};

const multiSelectStyles = {
	container: {
		maxWidth: '100%',
	},
	valueContainer: {
		flexWrap: 'nowrap',
	},
	multiValue: {
		minWidth: 'unset',
	},
} as Styles;

const initialFilterStateValues = {
	recursos: [],
	dias: [],
	familias: [],
	meses: [],
	anos: [new Date().getUTCFullYear()],
	tiposParada: [],
	turnos: [],
};

interface FilterInput {
	startDate: string;
	endDate: string;
}

interface SelectedProducts {
	data_estoque_atual: string | null;
	data_estoque_desejado: string | null;
	data_plano_restrito: string | null;
	empresa_id: number;
	id: number;
	mes_ano: string | null;
	produto: {
		nome_produto: string;
		codigo_produto: string;
	};
	produto_id: number;
	quantidade_estoque_atual: number | null;
	quantidade_estoque_desejado: number | null;
	quantidade_plano_restrito: number | null;
	quantidade_estoque_sugerido: number | null;
	[other: string]: any;
}

interface INecessidadeEstoque {
	data: string;
	plano_restrito: number | null;
	estoque_atual: number | null;
	estoque_seguranca: number | null;
}

const DashboardProducaoComponent = () => {
	const {
		setters: { setDataSidebar },
	} = useContext(SidebarHelpContext);

	const theme = useTheme();
	const graphicColors = theme.colors.graphic;

	const { configsCompany } = useGlobalContext();

	const {
		selectedTab,
		setSelectedTab,
		selectedProducts,
		setSelectedProducts,
		selectProductOptions,
		setSelectProductOptions,
		productsPagination,
		setProductsPagination,
		selectedFamilies,
		setSelectedFamilies,
		selectFamilyOptions,
		setSelectFamilyOptions,
		familiesPagination,
		setFamiliesPagination,
		selectedProjectionStock,
		setSelectedProjectionStock,
		selectProjectionStockOptions,
		setSelectProjectionStockOptions,
		projectionStockPagination,
		setProjectionStockPagination,
	} = useDashboardProducaoContext();

	const cardContentRef = useRef<any>();
	const filterProductRef = useRef<any>();
	const filterFamilyRef = useRef<any>();
	const filterProjectionStockRef = useRef<any>();

	const [filterHasBeenChanged, setFilterHasBeenChanged] = useState(false);
	const [resetAutoComplete, setResetAutoComplete] = useState(false);

	const [dataCharts, setDataCharts] = useState<any>(defaultCharts);

	const [dataParadas, setDataParadas] = useState<any>([]);

	const [showAlertIrogChart, setShowAlertIrogChart] = useState(false);
	const [showAlertPerformanceChart, setShowAlertPerformanceChart] =
		useState(false);
	const [showAlertDisponibilidadeChart, setShowAlertDisponibilidadeChart] =
		useState(false);
	const [showAlertQualidadeChart, setShowAlertQualidadeChart] =
		useState(false);

	const [multiSelectOptions, setMultiSelectOptions] = useState<any>();

	const [filterValues, setFilterValues] = useState<any>(
		initialFilterStateValues,
	);
	const [filterValueParadas, setFilterValueParadas] = useState<
		'all' | 'paradas_programadas' | 'paradas_nao_programadas' | 'apontadas'
	>('all');

	const [filteredItemsParadas, setFilteredItemsParadas] =
		useState(dataParadas);

	const [familyModalData, setFamilyModalData] = useState([]);

	const [cardWidth, setCardWidth] = useState(0);
	const [lastCardWidth, setLastCardWidth] = useState(0);

	const [isFirstRender, setFirstRender] = useState(true);

	const { data: allFamiliesData } = useFamilyListAll();

	const {
		data: productionDashboardFilter,
		isLoading: isLoadingDashboardFilter,
	}: any = useProductionDashboardOptionsFilter(filterValues);

	const {
		data: productionDashboardData,
		isLoading: isLoadingDashboardData,
		refetch: RefetchProductionDashboardData,
	}: any = useProductionDashboardData(filterValues);

	const {
		data: productionDashboardDataByFamilyStockData,
		isLoading: isLoadingProductionDashboardDataByFamilyStockData,
	} = useProductionDashboardDataByFamilyStock(
		selectedTab,
		familiesPagination.page,
		familiesPagination.size,
		familiesPagination?.otherParams?.familyId,
	);

	const { data: productionDashboardDataByProductStockData } =
		useProductionDashboardDataByProductStock(
			productsPagination.page,
			productsPagination.size,
			productsPagination?.otherParams?.productId,
			productsPagination?.otherParams?.familia_id,
			productsPagination?.otherParams?.participa_rateio,
		);

	const {
		data: productionDashboardDataByProjectionProductStockData,
		isLoading: isLoadingProductionDashboardDataByProjectionProductStockData,
	} = useProductionDashboardDataByProjectionProductStock(
		selectedTab,
		projectionStockPagination.page,
		projectionStockPagination.size,
		projectionStockPagination?.otherParams?.productId,
		projectionStockPagination?.otherParams?.familia_id,
		projectionStockPagination?.otherParams?.participa_rateio,
	);

	const startMonth = moment().startOf('month');
	const currentDate = moment().startOf('day');

	const [filterInput, setFilterInput] = useState<FilterInput>({
		startDate: startMonth.format('YYYY-MM-DD'),
		endDate: currentDate.format('YYYY-MM-DD'),
	});

	const familyOptions = useMemo(() => {
		return (
			allFamiliesData?.map((family) => {
				return (
					<option
						key={family?.id}
						value={family?.id}
					>
						{family?.nome_familia}
					</option>
				);
			}) || []
		);
	}, [allFamiliesData]);

	const getDataChartKey = useCallback(
		(key) => {
			return dataCharts.indicadoresIrog.map((item) =>
				parseFloat(item[key]),
			);
		},
		[dataCharts.indicadoresIrog],
	);

	const dataChartFilterFamily = useCallback(() => {
		const data: {
			estoque_atual: number;
			label: string;
			id: number;
		}[] = dataCharts?.estoque?.filter((item) => {
			return selectFamilyOptions.includes(item.id);
		});

		let seriesData: number[] = [];
		let optionsLabels: string[] = [];

		if (data) {
			const hasValuesDifferentThanZero = data.some(
				(item: { estoque_atual: number }) => item.estoque_atual !== 0,
			);

			if (hasValuesDifferentThanZero) {
				data.map(
					(item: {
						estoque_atual: number;
						label: string;
						id: number;
					}) => {
						seriesData.push(item.estoque_atual);
						optionsLabels.push(item.label);
					},
				);
			} else {
				seriesData = [];
				optionsLabels = [];
			}
		}

		return {
			seriesData,
			optionsLabels,
		};
	}, [dataCharts.estoque, selectFamilyOptions]);

	const dataChartIrog = useCallback(
		() => [
			{
				type: 'area',
				name: 'IROG',
				data: getDataChartKey('irog_real'),
			},
		],
		[getDataChartKey],
	);

	const dataChartPerformance = useCallback(
		() => [
			{
				type: 'area',
				name: 'Performance',
				data: getDataChartKey('performance_real'),
			},
		],
		[getDataChartKey],
	);

	const dataChartQualidade = useCallback(
		() => [
			{
				type: 'area',
				name: 'Qualidade',
				data: getDataChartKey('qualidade_real'),
			},
		],
		[getDataChartKey],
	);

	const dataChartDisponibilidade = useCallback(
		() => [
			{
				type: 'area',
				name: 'Disponibilidade',
				data: getDataChartKey('disponibilidade_real'),
			},
		],
		[getDataChartKey],
	);

	const dataChartLineDays = getDataChartKey('dia');

	const optionsParadas = useMemo(
		() => [
			{ value: 'all', label: 'Todas paradas' },
			{ value: 'paradas_programadas', label: 'Paradas programadas' },
			{
				value: 'paradas_nao_programadas',
				label: 'Paradas não programadas',
			},
			{ value: 'apontadas', label: 'Paradas não apontadas' },
		],
		[],
	);

	const {
		isOpen: isOpenModal,
		onOpen: onOpenModal,
		onClose: onCloseModal,
	} = useDisclosure();

	const helpDashboardProducao = useMemo<SidebarHelp>(
		() => ({
			title: 'Dashboard de Produção',
			firstParagraph: `Este dashboard permite o acompanhamento de Produção da sua indústria, apontando dados de estoque, produtividade e paradas.`,
			secondParagraph: `Para analisar o estoque atual versus desejado selecione as famílias de produtos desejadas. É permitido selecionar até 9 famílias.`,
			thirdParagraph: `Use os filtros de recurso e data para analisar o IROG, disponibilidade, performance e qualidade dos seus recursos. Também é possível analisar as paradas da sua indústria, sejam elas programadas e não programadas, apontando quais as paradas com maior tempo.`,
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	const multiSelectOptionsConfig = useMemo(
		() => [
			{
				resource: 'recursos',
				objectKey: 'recursosOptions',
				labelKey: 'descricao',
				valueKey: 'id',
			},
			{
				resource: 'anos',
				objectKey: 'anosOptions',
				labelKey: 'ano',
				valueKey: 'ano',
			},
			{
				resource: 'meses',
				objectKey: 'mesesOptions',
				labelKey: 'mes',
				valueKey: 'mes',
			},
			{
				resource: 'dias',
				objectKey: 'diasOptions',
				labelKey: 'dia',
				valueKey: 'dia',
			},
			{
				resource: 'turnos',
				objectKey: 'turnosOptions',
				labelKey: 'turno',
				valueKey: 'turno',
			},
		],
		[],
	);

	const buildMultiSelectOptions = useCallback(
		(data) =>
			multiSelectOptionsConfig.reduce((config, el) => {
				config[el.objectKey] = data[el.resource]?.map?.(
					(resource) =>
						({
							label: resource[el.labelKey],
							value: resource[el.valueKey],
						} as Option),
				);

				return config;
			}, {}),
		[multiSelectOptionsConfig],
	);

	const onChangeSelected = (key: string, value: MultiValue<Option>) => {
		let newFilterValues = {};

		switch (key) {
			case 'recursos':
				newFilterValues = {
					recursos: [],
					anos: [],
					meses: [],
					dias: [],
					turnos: [],
				};
				break;
			case 'anos':
				newFilterValues = {
					recursos: filterValues?.recursos,
					anos: [],
					meses: [],
					dias: [],
					turnos: [],
				};
				break;
			case 'meses':
				newFilterValues = {
					recursos: filterValues?.recursos,
					anos: filterValues?.anos,
					meses: [],
					dias: [],
					turnos: [],
				};
				break;
			case 'dias':
				newFilterValues = {
					recursos: filterValues?.recursos,
					anos: filterValues?.anos,
					meses: filterValues?.meses,
					dias: [],
					turnos: [],
				};
				break;
			case 'turnos':
				newFilterValues = {
					recursos: filterValues?.recursos,
					anos: filterValues?.anos,
					meses: filterValues?.meses,
					dias: filterValues?.dias,
					turnos: [],
				};
				break;
		}

		setFilterValues({
			...newFilterValues,
			[key]: value?.map?.((v) => v.value),
		});

		setTimeout(() => {
			RefetchProductionDashboardData();

			setFilterHasBeenChanged(true);

			setFamiliesPagination((prev) => ({
				...prev,
				page: 0,
				otherParams: {
					...prev.otherParams,
					familyId: undefined,
				},
			}));
		}, 350);
	};

	const {
		isOpen: isOpenAta,
		onOpen: onOpenAta,
		onClose: onCloseAta,
	} = useDisclosure();

	const { isOpen, onOpen, onClose } = useDisclosure();

	const handleOnChange = useCallback(
		(
			event,
			selectedOptions,
			stockDataArray,
			setSelectedOptions,
			setSelectedItens,
			keyForCompare,
		) => {
			const { value, checked } = event.target;

			if (checked) {
				const itemHasBeenSelected = selectedOptions?.some(
					(el) => el === Number(value),
				);

				if (itemHasBeenSelected) return;

				const stockData = stockDataArray?.rows?.find(
					(el) => el[keyForCompare] === Number(value),
				);

				if (selectedOptions?.length === 9) {
					setSelectedOptions((prev) => [
						...prev.slice(1),
						Number(value),
					]);

					setSelectedItens((prev) => [...prev.slice(1), stockData]);
				} else {
					setSelectedOptions((prev) => [...prev, Number(value)]);
					setSelectedItens((prev) => [...prev, stockData]);
				}
			} else {
				setSelectedOptions((prev) =>
					prev.filter((el) => el !== Number(value)),
				);
				setSelectedItens((prev) =>
					prev.filter((el) => el[keyForCompare] !== Number(value)),
				);
			}
		},
		[],
	);

	const setFamilyOpenModal = useCallback(
		(id) => {
			const family =
				productionDashboardDataByFamilyStockData?.rows?.filter?.(
					(item) => {
						return item.id_familia === id;
					},
				);

			setFamilyModalData(family);
		},
		[productionDashboardDataByFamilyStockData?.rows],
	);

	const getIconList = (parada_programada, apontada) => {
		if (!apontada) {
			return IconAlarmOff;
		} else if (parada_programada) {
			return IconClock;
		} else {
			return IconAlertTriangle;
		}
	};

	const getColorList = (parada_programada, apontada) => {
		if (!apontada) {
			return 'easyRED.300';
		} else if (parada_programada) {
			return 'gray.300';
		} else {
			return 'yellow.300';
		}
	};

	const formatFilterValuesToOptions = useCallback(
		(filterName, keyObject) =>
			multiSelectOptions[keyObject]?.filter?.((el) =>
				filterValues[filterName]?.includes?.(el?.value),
			) || [],
		[filterValues, multiSelectOptions],
	);

	const renderMultiSelect = useCallback(
		({
			id,
			selectOptionsKey,
			filterName,
			onChangeKey,
			placeholder,
			selectAllTitle,
		}) => {
			return (
				<MultiSelect
					id={id}
					options={multiSelectOptions[selectOptionsKey]}
					value={formatFilterValuesToOptions(
						filterName,
						selectOptionsKey,
					)}
					onChange={(value) => onChangeSelected(onChangeKey, value)}
					size={'medium'}
					placeholder={placeholder}
					notHideSelectedOptions
					selectAllTitle={selectAllTitle}
					isLoading={isLoadingDashboardFilter}
					styles={multiSelectStyles}
				/>
			);
		},
		[
			formatFilterValuesToOptions,
			isLoadingDashboardFilter,
			multiSelectOptions,
		],
	);

	const renderFilters = useCallback(() => {
		return (
			<Flex
				gap={4}
				alignItems='center'
				justifyContent='flex-start'
				flexGrow={1}
			>
				<FormControl
					minWidth='150px'
					width={'20%'}
					maxWidth={400}
					flexGrow={1}
				>
					{renderMultiSelect({
						id: 'multiselect-visualizar-recursos-page-dashboard_de_producao',
						selectOptionsKey: 'recursosOptions',
						filterName: 'recursos',
						onChangeKey: 'recursos',
						placeholder: 'Recursos',
						selectAllTitle: 'Todos os Recursos',
					})}
				</FormControl>

				<FormControl maxWidth={200}>
					{renderMultiSelect({
						id: 'multiselect-visualizar-ano-page-dashboard_de_producao',
						selectOptionsKey: 'anosOptions',
						filterName: 'anos',
						onChangeKey: 'anos',
						placeholder: 'Ano',
						selectAllTitle: 'Todos os Anos',
					})}
				</FormControl>

				<FormControl maxWidth={200}>
					{renderMultiSelect({
						id: 'multiselect-visualizar-mes-page-dashboard_de_producao',
						selectOptionsKey: 'mesesOptions',
						filterName: 'meses',
						onChangeKey: 'meses',
						placeholder: 'Mês',
						selectAllTitle: 'Todos os Meses',
					})}
				</FormControl>

				<FormControl
					minWidth='100px'
					maxWidth={200}
				>
					{renderMultiSelect({
						id: 'multiselect-visualizar-dia-page-dashboard_de_producao',
						selectOptionsKey: 'diasOptions',
						filterName: 'dias',
						onChangeKey: 'dias',
						placeholder: 'Dia',
						selectAllTitle: 'Todos os Dias',
					})}
				</FormControl>

				<FormControl
					minWidth='100px'
					maxWidth={200}
				>
					{renderMultiSelect({
						id: 'multiselect-visualizar-turno-page-dashboard_de_producao',
						selectOptionsKey: 'turnosOptions',
						filterName: 'turnos',
						onChangeKey: 'turnos',
						placeholder: 'Turno',
						selectAllTitle: 'Todos os Turnos',
					})}
				</FormControl>

				<Tooltip
					label={'Recalcular IROG'}
					placement='bottom'
					mt={1.5}
					bg='gray.200'
					color='blue.800'
					hasArrow
					arrowSize={15}
				>
					<IconButton
						aria-label='calcular'
						icon={<IconRefreshAlert />}
						onClick={onOpenModal}
					/>
				</Tooltip>
			</Flex>
		);
	}, [onOpenModal, renderMultiSelect]);

	const renderButtons = useCallback(() => {
		return (
			<>
				<ButtonGroup
					spacing={'15px'}
					marginLeft='auto'
				>
					<ButtonComponent
						data-test='button-ata-dashboard_de_producao'
						type={'secondary'}
						title={'ATA'}
						onClick={onOpenAta}
					/>
				</ButtonGroup>
				<RecalculateIROG
					isOpen={isOpenModal}
					onClose={onCloseModal}
				/>
			</>
		);
	}, [isOpenModal, onCloseModal, onOpenAta]);

	const defaultTabStyleProps = useMemo(
		() => ({
			_selected: {
				color: '#55bcdf',
				fontWeight: 'bold',
				borderBottom: '1px solid #55bcdf',
			},
			_hover: {
				color: '#55bcdf',
				borderBottom: '1px solid #55bcdf',
			},
			_focus: {
				outline: '0 !important',
			},
			color: 'white',
			fontSize: '0.8rem',
		}),
		[],
	);

	const customTooltip = useMemo(() => {
		return {
			shared: true,
			intersect: false,
			theme: 'dark',
			custom: function ({ series, seriesIndex, dataPointIndex, w }) {
				return renderCustomTooltipForGroupedStackedBar({
					series,
					seriesIndex,
					dataPointIndex,
					w,
					decimalPrecision: configsCompany?.precisao_decimal,
					dates: dataCharts?.estoquePorProdutos?.dates,
					suggestedStock:
						dataCharts?.estoquePorProdutos?.suggestedStock,
				});
			},
		};
	}, [
		configsCompany?.precisao_decimal,
		dataCharts?.estoquePorProdutos?.dates,
		dataCharts?.estoquePorProdutos?.suggestedStock,
	]);

	/**
	 * Por algum motivo desconhecido, não foi possível enviar como parâmetro os valores de showDates e showSuggestedStock
	 * com base em uma variável condicional, então foi necessário criar 2 memos, um para cada tipo de gráfico.
	 */
	const customTooltipProjectionStock = useMemo(() => {
		return {
			shared: true,
			intersect: false,
			theme: 'dark',
			custom: function ({ series, seriesIndex, dataPointIndex, w }) {
				return renderCustomTooltipForGroupedStackedBar({
					series,
					seriesIndex,
					dataPointIndex,
					w,
					decimalPrecision: configsCompany?.precisao_decimal,
					dates: dataCharts?.estoquePorProdutos?.dates,
					suggestedStock:
						dataCharts?.estoquePorProdutos?.suggestedStock,
					showDates: false,
					showSuggestedStock: false,
				});
			},
		};
	}, [
		configsCompany?.precisao_decimal,
		dataCharts?.estoquePorProdutos?.dates,
		dataCharts?.estoquePorProdutos?.suggestedStock,
	]);

	const renderCharts = useCallback(() => {
		const loading = (
			<LoadingSpinner
				color='white'
				pt='10vh'
			/>
		);

		return (
			<Flex
				w={'75%'}
				flexDirection={'column'}
				gap={4}
				ref={cardContentRef}
			>
				<Card bgColor={'rgba(0, 0, 0, 0.3)'}>
					<Tabs
						size='md'
						variant='enclosed'
						defaultIndex={selectedTab === 'produtos' ? 0 : 1}
					>
						<TabList>
							<Tab
								data-test='tab-selecionar_produtos-dashboard_producao'
								panelId='produtos'
								tabIndex={0}
								onClick={() => {
									setSelectedTab('produtos');
									setResetAutoComplete(true);
								}}
								{...defaultTabStyleProps}
							>
								Estoque por produtos
							</Tab>
							<Tab
								data-test='tab-selecionar_familias-dashboard_producao'
								panelId='produtos'
								tabIndex={1}
								onClick={() => {
									setSelectedTab('familias');
									setResetAutoComplete(true);
								}}
								{...defaultTabStyleProps}
							>
								Estoque por famílias
							</Tab>
							<Tab
								data-test='tab-selecionar_projecao_estoque-dashboard_producao'
								panelId='projecao_estoque'
								tabIndex={2}
								onClick={() => {
									setSelectedTab('projecao_estoque');
									setResetAutoComplete(true);
								}}
								{...defaultTabStyleProps}
							>
								Projeção de necessidade de estoque
							</Tab>
						</TabList>
						<TabPanels>
							<TabPanel>
								<GroupedStackedBarChart
									chartData={
										dataCharts?.estoquePorProdutos
											?.series || []
									}
									chartOptions={groupedStackedBarChartOptions(
										configsCompany.precisao_decimal,
										'Estoque de segurança x Plano restrito x Estoque atual',
										'Para o dashboard funcionar corretamente é necessário ter plano restrito, estoque de segurança e cadastrar o estoque atual!',
										dataCharts?.estoquePorProdutos
											?.categories,
										graphicColors,
										customTooltip,
									)}
								/>
							</TabPanel>
							<TabPanel>
								{isLoadingProductionDashboardDataByFamilyStockData ? (
									<>{loading}</>
								) : (
									<DonutChart
										chartData={
											dataChartFilterFamily().seriesData
										}
										chartOptions={donutChartOptions(
											configsCompany.precisao_decimal,
											dataChartFilterFamily()
												.optionsLabels,
											graphicColors,
											'Estoque atual por família',
										)}
									/>
								)}
							</TabPanel>
							<TabPanel>
								{isLoadingProductionDashboardDataByProjectionProductStockData ? (
									<>{loading}</>
								) : (
									<GroupedStackedBarChart
										chartData={
											dataCharts
												?.projecaoNecessidadeEstoque
												?.series || []
										}
										chartOptions={groupedStackedBarChartOptions(
											configsCompany.precisao_decimal,
											'Estoque de segurança x Plano restrito x Estoque atual',
											'Para o dashboard funcionar corretamente é necessário ter plano restrito, estoque de segurança e cadastrar o estoque atual!',
											dataCharts
												?.projecaoNecessidadeEstoque
												?.categories,
											graphicColors,
											customTooltipProjectionStock,
										)}
									/>
								)}
							</TabPanel>
						</TabPanels>
					</Tabs>
				</Card>

				{showAlertIrogChart && (
					<AlertComponent
						element={'lancamento-diario'}
						title={'Atenção'}
						status={'warning'}
						description={
							'Atenção! Verifique os motivos da capacidade produtiva estar acima de 100%, isso indica que sua capacidade real está maior do que a prevista/teórica!'
						}
						hasCloseButton
					/>
				)}

				<Card bgColor={'rgba(0, 0, 0, 0.3)'}>
					<Flex justifyContent='center'>
						<LineChart
							chartData={dataChartIrog}
							chartOptions={lineChartOptions(
								['#FFA800'],
								'IROG',
								dataChartLineDays,
								Number(dataCharts.mediaMeta.irog_meta),
							)}
							width={cardWidth}
						/>
					</Flex>
				</Card>

				{showAlertDisponibilidadeChart && (
					<AlertComponent
						element={'lancamento-diario'}
						title={'Atenção'}
						status={'warning'}
						description={
							'Atenção! Verifique os motivos da capacidade produtiva estar acima de 100%, isso indica que sua capacidade real está maior do que a prevista/teórica!'
						}
						hasCloseButton
					/>
				)}

				<Card bgColor={'rgba(0, 0, 0, 0.3)'}>
					<Flex justifyContent='center'>
						<LineChart
							chartData={dataChartDisponibilidade}
							chartOptions={lineChartOptions(
								['#9868FF'],
								'Disponibilidade',
								dataChartLineDays,
								Number(
									dataCharts.mediaMeta.disponibilidade_meta,
								),
							)}
							width={cardWidth}
						/>
					</Flex>
				</Card>

				{showAlertPerformanceChart && (
					<AlertComponent
						element={'lancamento-diario'}
						title={'Atenção'}
						status={'warning'}
						description={
							'Atenção! Verifique os motivos da capacidade produtiva estar acima de 100%, isso indica que sua capacidade real está maior do que a prevista/teórica!'
						}
						hasCloseButton
					/>
				)}

				<Card bgColor={'rgba(0, 0, 0, 0.3)'}>
					<Flex justifyContent='center'>
						<LineChart
							chartData={dataChartPerformance}
							chartOptions={lineChartOptions(
								['#85B9FF'],
								'Performance',
								dataChartLineDays,
								Number(dataCharts.mediaMeta.performance_meta),
							)}
							width={cardWidth}
						/>
					</Flex>
				</Card>

				{showAlertQualidadeChart && (
					<AlertComponent
						element={'lancamento-diario'}
						title={'Atenção'}
						status={'warning'}
						description={
							'Atenção! Verifique os motivos da capacidade produtiva estar acima de 100%, isso indica que sua capacidade real está maior do que a prevista/teórica!'
						}
						hasCloseButton
					/>
				)}

				<Card bgColor={'rgba(0, 0, 0, 0.3)'}>
					<Flex justifyContent='center'>
						<LineChart
							chartData={dataChartQualidade}
							chartOptions={lineChartOptions(
								['#FC6574'],
								'Qualidade',
								dataChartLineDays,
								Number(dataCharts.mediaMeta.qualidade_meta),
							)}
							width={cardWidth}
						/>
					</Flex>
				</Card>
			</Flex>
		);
	}, [
		selectedTab,
		defaultTabStyleProps,
		dataCharts?.estoquePorProdutos?.series,
		dataCharts?.estoquePorProdutos?.categories,
		dataCharts?.projecaoNecessidadeEstoque?.series,
		dataCharts?.projecaoNecessidadeEstoque?.categories,
		dataCharts.mediaMeta.irog_meta,
		dataCharts.mediaMeta.disponibilidade_meta,
		dataCharts.mediaMeta.performance_meta,
		dataCharts.mediaMeta.qualidade_meta,
		configsCompany.precisao_decimal,
		graphicColors,
		customTooltip,
		isLoadingProductionDashboardDataByFamilyStockData,
		dataChartFilterFamily,
		isLoadingProductionDashboardDataByProjectionProductStockData,
		customTooltipProjectionStock,
		showAlertIrogChart,
		dataChartIrog,
		dataChartLineDays,
		cardWidth,
		showAlertDisponibilidadeChart,
		dataChartDisponibilidade,
		showAlertPerformanceChart,
		dataChartPerformance,
		showAlertQualidadeChart,
		dataChartQualidade,
		setSelectedTab,
	]);

	const cssList = useMemo(
		() => ({
			'&::-webkit-scrollbar': {
				width: '4px',
			},
			'&::-webkit-scrollbar-track': {
				width: '6px',
			},
			'&::-webkit-scrollbar-thumb': {
				background: '#8a9db9',
				borderRadius: '24px',
			},
		}),
		[],
	);

	const renderFamiliesCheboxGroup = useCallback(() => {
		const selectedIds = selectedFamilies?.map((el) => el?.id);

		return productionDashboardDataByFamilyStockData?.rows?.map?.(
			(option) => (
				<Flex justifyContent='space-between'>
					<Flex
						w='90%'
						ml={1}
					>
						<Checkbox
							colorScheme='red'
							color='#fff'
							key={option?.id}
							value={option?.id}
							onChange={(event: any) =>
								handleOnChange(
									event,
									selectedFamilies,
									productionDashboardDataByFamilyStockData,
									setSelectFamilyOptions,
									setSelectedFamilies,
									'id',
								)
							}
							isDisabled={
								selectedIds.length === 9 &&
								!selectedIds.includes(option?.id)
							}
						>
							{option?.sum_estoque_atual === 0 ? (
								<TooltipStandard
									label={
										'Família sem estoque atual cadastrado.'
									}
								>
									<Text>{option?.nome_familia}</Text>
								</TooltipStandard>
							) : (
								<Text>{option?.nome_familia}</Text>
							)}
						</Checkbox>
					</Flex>
					<Flex w='10%'>
						<IconInfoCircle
							cursor='pointer'
							color='#fff'
							onClick={() => {
								setFamilyOpenModal(option.id);
								onOpen();
							}}
						/>
					</Flex>
				</Flex>
			),
		);
	}, [
		handleOnChange,
		onOpen,
		productionDashboardDataByFamilyStockData,
		selectedFamilies,
		setFamilyOpenModal,
		setSelectFamilyOptions,
		setSelectedFamilies,
	]);

	const renderProductsCheboxGroup = useCallback(() => {
		const selectedIds = selectedProducts?.map((el) => el?.produto_id);

		return productionDashboardDataByProductStockData?.rows?.map?.(
			(option, index) => (
				<Flex justifyContent='space-between'>
					<Flex>
						<Checkbox
							colorScheme='red'
							color='#fff'
							key={option?.produto_id}
							value={option?.produto_id}
							onChange={(event: any) =>
								handleOnChange(
									event,
									selectedProducts,
									productionDashboardDataByProductStockData,
									setSelectProductOptions,
									setSelectedProducts,
									'produto_id',
								)
							}
							isDisabled={
								selectedIds.length === 9 &&
								!selectedIds.includes(option?.produto_id)
							}
							data-test='checkbox-selecionar_filtro_produto-dashboard_producao'
						>
							<Text>{`${option?.codigo_produto} - ${option?.nome_produto}`}</Text>
						</Checkbox>
					</Flex>
				</Flex>
			),
		);
	}, [
		handleOnChange,
		productionDashboardDataByProductStockData,
		selectedProducts,
		setSelectProductOptions,
		setSelectedProducts,
	]);

	const renderProjectionStockCheboxGroup = useCallback(() => {
		const selectedIds = selectedProjectionStock?.map(
			(el) => el?.produto_id,
		);

		return productionDashboardDataByProjectionProductStockData?.rows?.map?.(
			(option, index) => (
				<Flex justifyContent='space-between'>
					<Flex>
						<Checkbox
							colorScheme='red'
							color='#fff'
							key={option?.produto_id}
							value={option?.produto_id}
							onChange={(event: any) =>
								handleOnChange(
									event,
									selectedProjectionStock,
									productionDashboardDataByProjectionProductStockData,
									setSelectProjectionStockOptions,
									setSelectedProjectionStock,
									'produto_id',
								)
							}
							isDisabled={
								selectedIds.length === 1 &&
								!selectedIds.includes(option?.produto_id)
							}
							data-test='checkbox-selecionar_filtro_projecao_estoque-dashboard_producao'
						>
							<Text>{`${option?.codigo_produto} - ${option?.nome_produto}`}</Text>
						</Checkbox>
					</Flex>
				</Flex>
			),
		);
	}, [
		handleOnChange,
		productionDashboardDataByProjectionProductStockData,
		selectedProjectionStock,
		setSelectProjectionStockOptions,
		setSelectedProjectionStock,
	]);

	const renderFamilySearch = useCallback(() => {
		return (
			<Flex
				direction='row'
				pt='10px'
			>
				<GenericAutocomplete
					searchType='familia'
					inputProps={{
						ref: filterFamilyRef,
						renderInputLabel: renderFamily,
						onChange: (family: any) => {
							setFamiliesPagination((prev) => ({
								...prev,
								page: 0,
								otherParams: {
									familyId: family?.id,
								},
							}));
						},
						clearInput: () => {
							if (resetAutoComplete) {
								setResetAutoComplete(false);

								return true;
							}

							return false;
						},
						color: 'white',
						_focus: {
							outline: '0 !important',
						},
					}}
				/>
			</Flex>
		);
	}, [setFamiliesPagination, resetAutoComplete]);

	const renderProductSearch = useCallback(() => {
		const showProjectionStock = selectedTab === 'projecao_estoque';

		let extraRequestData = showProjectionStock
			? {
					familia_id:
						projectionStockPagination?.otherParams?.familia_id,
					participa_rateio:
						projectionStockPagination?.otherParams
							?.participa_rateio,
			  }
			: {
					familia_id: productsPagination?.otherParams?.familia_id,
					participa_rateio:
						productsPagination?.otherParams?.participa_rateio,
			  };
		return (
			<Flex
				direction='row'
				pt='10px'
			>
				<GenericAutocomplete
					searchType='produto'
					inputProps={{
						ref: showProjectionStock
							? filterProjectionStockRef
							: filterProductRef,
						renderInputLabel: renderProduct,
						onChange: (product: any) => {
							if (showProjectionStock) {
								setProjectionStockPagination((prev) => ({
									...prev,
									page: 0,
									otherParams: {
										...prev?.otherParams,
										productId: product?.id,
									},
								}));
							} else {
								setProductsPagination((prev) => ({
									...prev,
									page: 0,
									otherParams: {
										...prev?.otherParams,
										productId: product?.id,
									},
								}));
							}
						},
						clearInput: () => {
							if (resetAutoComplete) {
								setResetAutoComplete(false);

								return true;
							}

							return false;
						},
						color: 'white',
						_focus: {
							outline: '0 !important',
						},
						extraRequestData,
					}}
				/>
			</Flex>
		);
	}, [
		selectedTab,
		productsPagination?.otherParams?.familia_id,
		productsPagination?.otherParams?.participa_rateio,
		projectionStockPagination?.otherParams?.familia_id,
		projectionStockPagination?.otherParams?.participa_rateio,
		setProductsPagination,
		setProjectionStockPagination,
		resetAutoComplete,
	]);

	const renderAdditionalProductFilters = useCallback(() => {
		const showProducts = selectedTab === 'produtos';

		let initialValue = '';

		if (showProducts) {
			initialValue = productsPagination?.otherParams?.familia_id || '';
		} else {
			initialValue =
				projectionStockPagination?.otherParams?.familia_id || '';
		}

		function handleOnChangeAdditionalFilters(
			event,
			setOptions,
			setSelected,
			setPagination,
			actionType,
		) {
			setOptions([]);
			setSelected([]);
			setResetAutoComplete(true);
			setPagination((prev) => ({
				...prev,
				page: 0,
				otherParams: {
					...prev?.otherParams,
					...(actionType === 'rateio'
						? { participa_rateio: event?.target?.checked }
						: { familia_id: event?.target?.value }),
					productId: null,
				},
			}));
		}

		return (
			<Flex
				display='flex'
				direction='column'
				gap={2}
				mb='15px'
			>
				<FormControl>
					<Flex
						display='flex'
						direction='column'
					>
						<InputFormLabel
							label='Família de produtos'
							color='white'
						/>
						<GenericInput
							type='select'
							inputProps={{
								name: 'familia_id',
								value: initialValue,
								'data-test':
									'select-familia-page-filtro-dash_producao',
								placeholder: 'Selecione...',
								selectOptions: familyOptions,
								backgroundColor: 'transparent',
								color: 'white',
								_hover: {
									color: 'white',
								},
								onChange: (event: any) => {
									if (showProducts) {
										handleOnChangeAdditionalFilters(
											event,
											setSelectProductOptions,
											setSelectedProducts,
											setProductsPagination,
											'familia',
										);
									} else {
										handleOnChangeAdditionalFilters(
											event,
											setSelectProjectionStockOptions,
											setSelectedProjectionStock,
											setProjectionStockPagination,
											'familia',
										);
									}
								},
							}}
						/>
					</Flex>
				</FormControl>
				<FormControl>
					<Flex
						display='flex'
						direction='row'
						gap={2}
					>
						{showProducts ? (
							<>
								<GenericInput
									type='checkbox'
									inputProps={{
										name: 'rateio',
										'data-test':
											'checkbox-rateio-page-filtro-dash_producao',
										backgroundColor: 'transparent',
										color: 'white',
										_hover: {
											color: 'white',
										},
										onChange: (event: any) => {
											handleOnChangeAdditionalFilters(
												event,
												setSelectProductOptions,
												setSelectedProducts,
												setProductsPagination,
												'rateio',
											);
										},
									}}
								/>
								<InputFormLabel
									label='Produtos do Rateio'
									color='white'
								/>
							</>
						) : (
							<Checkbox
								isDisabled
								defaultChecked
								color='white'
								_hover={{
									color: 'white',
								}}
								data-test='checkbox-rateio-page-filtro-dash_producao'
							>
								Produtos no Rateio
							</Checkbox>
						)}
					</Flex>
				</FormControl>
			</Flex>
		);
	}, [
		familyOptions,
		productsPagination?.otherParams?.familia_id,
		projectionStockPagination?.otherParams?.familia_id,
		selectedTab,
		setProductsPagination,
		setProjectionStockPagination,
		setSelectProductOptions,
		setSelectProjectionStockOptions,
		setSelectedProducts,
		setSelectedProjectionStock,
	]);

	const renderFamiliesOrProductsCheckboxFilter = useCallback(() => {
		const showFamilies = selectedTab === 'familias';
		const showProducts = selectedTab === 'produtos';
		const showProjectionStock = selectedTab === 'projecao_estoque';

		let checkboxValues: any = [];
		let checkBoxGroup: any = [];
		let pagination: any = {};
		let setPagination: any = () => {};

		if (showFamilies) {
			checkboxValues = selectFamilyOptions;
			checkBoxGroup = renderFamiliesCheboxGroup();
			pagination = familiesPagination;
			setPagination = setFamiliesPagination;
		} else if (showProducts) {
			checkboxValues = selectProductOptions;
			checkBoxGroup = renderProductsCheboxGroup();
			pagination = productsPagination;
			setPagination = setProductsPagination;
		} else {
			checkboxValues = selectProjectionStockOptions;
			checkBoxGroup = renderProjectionStockCheboxGroup();
			pagination = projectionStockPagination;
			setPagination = setProjectionStockPagination;
		}

		return (
			<Card
				bgColor={'rgba(0, 0, 0, 0.3)'}
				h={'50%'}
			>
				{!showFamilies ? renderAdditionalProductFilters() : null}

				<Flex direction='row'>
					<Flex
						direction='column'
						w={showFamilies ? '100%' : '90%'}
					>
						<Text
							color={'#fff'}
							mb={0}
						>
							{showFamilies
								? `Família de produtos (${
										productionDashboardFilter?.familias
											?.length || 0
								  } registros)`
								: null}

							{showProducts
								? `Produtos (${
										productionDashboardDataByProductStockData?.totalRows ||
										0
								  } registros)`
								: null}
							{showProjectionStock
								? `Produtos (${
										productionDashboardDataByProjectionProductStockData?.totalRows ||
										0
								  } registros)`
								: null}
						</Text>
						<Text
							fontSize={'14px'}
							color={'#fff'}
						>
							{showFamilies ? 'Selecione até 9 famílias' : null}
							{showProducts ? 'Selecione até 9 produtos' : null}
							{showProjectionStock ? 'Selecione 1 produto' : null}
						</Text>
					</Flex>
					<Flex w='10%'>
						<TooltipStandard label='limpar seleção'>
							<span>
								<IconButton
									data-test='button-limpar_selecao_filtro_produto-dashboard_producao'
									aria-label='refresh'
									icon={<IconSquareX />}
									onClick={() => {
										if (showFamilies) {
											setSelectFamilyOptions([]);
											setSelectedFamilies([]);
										} else if (showProducts) {
											setSelectProductOptions([]);
											setSelectedProducts([]);
										} else {
											setSelectProjectionStockOptions([]);
											setSelectedProjectionStock([]);
										}
									}}
									border='none'
									backgroundColor='transparent'
									color='white'
									_hover={{
										color: 'white',
									}}
								/>
							</span>
						</TooltipStandard>
					</Flex>
				</Flex>

				{showFamilies ? renderFamilySearch() : renderProductSearch()}
				{/* <SearchBar h='44px' w={'100%'} placeholder="Buscar..." borderRadius='16px' /> */}

				<Stack
					spacing={4}
					mt={'16px'}
					overflow={'auto'}
					css={cssList}
					data-test='stack-filtro_familia_produto-dashboard_producao'
				>
					<CheckboxGroup value={checkboxValues}>
						{checkBoxGroup}
					</CheckboxGroup>

					<br />
					<ManualPaging
						pagination={pagination}
						setPagination={setPagination}
						totalItemsPageList={[9]}
						showGoToPage={false}
						showInputLinesPerPage={false}
						renderedInDarkMode={true}
					/>
					<br />
				</Stack>
			</Card>
		);
	}, [
		selectedTab,
		renderAdditionalProductFilters,
		productionDashboardFilter?.familias?.length,
		productionDashboardDataByProductStockData?.totalRows,
		productionDashboardDataByProjectionProductStockData?.totalRows,
		renderFamilySearch,
		renderProductSearch,
		cssList,
		selectFamilyOptions,
		renderFamiliesCheboxGroup,
		familiesPagination,
		setFamiliesPagination,
		selectProductOptions,
		renderProductsCheboxGroup,
		productsPagination,
		setProductsPagination,
		selectProjectionStockOptions,
		renderProjectionStockCheboxGroup,
		projectionStockPagination,
		setProjectionStockPagination,
		setSelectFamilyOptions,
		setSelectedFamilies,
		setSelectProductOptions,
		setSelectedProducts,
		setSelectProjectionStockOptions,
		setSelectedProjectionStock,
	]);

	const renderFilteredStops = useCallback(() => {
		return filteredItemsParadas?.map((option) => (
			<ListItem>
				<ListIcon
					boxSize={5}
					color={getColorList(
						option.parada_programada,
						option.parada_apontada,
					)}
					as={getIconList(
						option.parada_programada,
						option.parada_apontada,
					)}
				/>
				{!option.parada_apontada ? (
					<Tooltip
						label='Tempo que sobrou do dia útil sem receber nenhum lançamento diário e/ou de paradas.'
						aria-label='A tooltip'
					>
						{`${option.tipologia_parada}  ${new Intl.NumberFormat(
							'pt-BR',
						).format(option.sum_tempo_de_parada)} min`}
					</Tooltip>
				) : (
					<span>
						{`${option.tipologia_parada}  ${new Intl.NumberFormat(
							'pt-BR',
						).format(option.sum_tempo_de_parada)} min`}
					</span>
				)}
			</ListItem>
		));
	}, [filteredItemsParadas]);

	const renderStops = useCallback(() => {
		function renderOptions() {
			return optionsParadas?.map?.((item) => (
				<option
					selected={item.value === 'all'}
					value={item.value}
				>
					{item.label}
				</option>
			));
		}

		return (
			<Card
				bgColor={'rgba(0, 0, 0, 0.3)'}
				h={'50%'}
			>
				<FormControl
					maxWidth={'100%'}
					mb='8px'
				>
					<Select
						onChange={(e) =>
							setFilterValueParadas(
								e.target.value as
									| 'all'
									| 'paradas_programadas'
									| 'paradas_nao_programadas'
									| 'apontadas',
							)
						}
						backgroundColor={'#fff'}
						h='44px'
						borderRadius={10}
						required
					>
						{renderOptions()}
					</Select>
				</FormControl>
				<List
					spacing={3}
					color={'#fff'}
					overflow={'auto'}
					css={cssList}
				>
					{renderFilteredStops()}
				</List>
			</Card>
		);
	}, [cssList, optionsParadas, renderFilteredStops]);

	const renderComponent = useCallback(() => {
		if (
			isLoadingDashboardFilter ||
			isLoadingDashboardData ||
			!multiSelectOptions
		)
			return (
				<Flex
					w='100%'
					height='100vh'
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
				>
					<Spinner
						thickness='4px'
						speed='0.65s'
						emptyColor='gray.200'
						color='blue.500'
						size='xl'
					/>
					<p style={{ color: 'white' }}>Carregando dados...</p>
				</Flex>
			);

		return (
			<>
				<Flex
					rowGap={6}
					columnGap={4}
					mb={12}
					alignItems='center'
					justifyContent='space-between'
				>
					{renderFilters()}

					{renderButtons()}
				</Flex>

				<AtaModalProduction
					isOpen={isOpenAta}
					onClose={onCloseAta}
					provider={'DASHBOARD_PRODUCAO'}
					data={productionDashboardData?.ata}
					endDate={filterInput?.endDate}
					refetchData={RefetchProductionDashboardData}
				/>

				<Flex gap={6}>
					{renderCharts()}

					<Flex
						w={'25%'}
						flexDirection={'column'}
						gap={6}
					>
						{renderFamiliesOrProductsCheckboxFilter()}

						<ModalFamiliaEstoque
							isOpen={isOpen}
							onClose={onClose}
							family={familyModalData}
						/>
						{renderStops()}
					</Flex>
				</Flex>
			</>
		);
	}, [
		isLoadingDashboardFilter,
		isLoadingDashboardData,
		multiSelectOptions,
		renderFilters,
		renderButtons,
		isOpenAta,
		onCloseAta,
		productionDashboardData?.ata,
		RefetchProductionDashboardData,
		renderCharts,
		renderFamiliesOrProductsCheckboxFilter,
		isOpen,
		onClose,
		familyModalData,
		renderStops,
	]);

	const extractDataValues = useMemo(
		() =>
			(
				selectedProducts: SelectedProducts,
				dataKey: string,
			): number | null[] =>
				selectedProducts?.map((item) => item?.[dataKey]),
		[],
	);

	const extractCategories = useMemo(
		() =>
			(selectedProducts: SelectedProducts): string[] =>
				selectedProducts?.map(
					(item) => `${item?.codigo_produto} - ${item?.nome_produto}`,
				),
		[],
	);

	const extractDates = useMemo(
		() =>
			(
				selectedProducts: SelectedProducts,
				dateKey: string,
				dateFormat: string,
			): string | null[] =>
				selectedProducts?.map((item) => {
					const date = item?.[dateKey];
					return date ? moment(date).format(dateFormat) : date;
				}),
		[],
	);

	const arraySortProjectionStock = useCallback(
		(a: INecessidadeEstoque, b: INecessidadeEstoque) => {
			return moment(a.data).isBefore(b.data) ? -1 : 1;
		},
		[],
	);

	useEffect(() => {
		function handleWindowResize() {
			setLastCardWidth(window.innerWidth * 0.5);
		}

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, [lastCardWidth]);

	useLayoutEffect(() => {
		const currentSize = cardContentRef?.current?.clientWidth;

		if (!lastCardWidth || lastCardWidth === 0) {
			setLastCardWidth(currentSize);
			setCardWidth(currentSize);
		} else {
			if (currentSize > lastCardWidth) {
				setCardWidth(lastCardWidth);
				setLastCardWidth(currentSize);
			} else if (currentSize < lastCardWidth) {
				setLastCardWidth(currentSize);
				setCardWidth(lastCardWidth);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [renderCharts]);

	useEffect(() => {
		dataChartIrog[0]?.data?.forEach((element) => {
			if (element > 100) {
				setShowAlertIrogChart(true);
			}
		});
		dataChartDisponibilidade[0]?.data?.forEach((element) => {
			if (element > 100) {
				setShowAlertDisponibilidadeChart(true);
			}
		});
		dataChartPerformance[0]?.data?.forEach((element) => {
			if (element > 100) {
				setShowAlertPerformanceChart(true);
			}
		});
		dataChartQualidade[0]?.data?.forEach((element) => {
			if (element > 100) {
				setShowAlertQualidadeChart(true);
			}
		});
	}, [
		dataChartIrog,
		dataChartDisponibilidade,
		dataChartPerformance,
		dataChartQualidade,
	]);

	useEffect(() => {
		if (filterValueParadas === 'all') {
			return setFilteredItemsParadas(dataParadas);
		} else if (filterValueParadas === 'paradas_programadas') {
			return setFilteredItemsParadas(
				dataParadas.filter((item) => item.parada_programada),
			);
		} else if (filterValueParadas === 'paradas_nao_programadas') {
			return setFilteredItemsParadas(
				dataParadas.filter(
					(item) => !item.parada_programada && item.parada_apontada,
				),
			);
		} else if (filterValueParadas === 'apontadas') {
			return setFilteredItemsParadas(
				dataParadas.filter((item) => !item.parada_apontada),
			);
		}
	}, [dataParadas, filterValueParadas]);

	useEffect(() => {
		setDataSidebar(helpDashboardProducao);
	}, [helpDashboardProducao, setDataSidebar]);

	useEffect(() => {
		if (productionDashboardFilter) {
			setMultiSelectOptions(
				buildMultiSelectOptions(productionDashboardFilter),
			);
		}
	}, [buildMultiSelectOptions, productionDashboardFilter]);

	useEffect(() => {
		if (productionDashboardData) {
			setDataCharts((prev) => ({
				...prev,
				indicadoresIrog: productionDashboardData?.indicadoresIrog?.sort(
					(value, index) => value.dia - index.dia,
				),
				mediaMeta: productionDashboardData.mediaMeta[0],
			}));
			setDataParadas(productionDashboardData.paradas);
		}
	}, [productionDashboardData]);

	useEffect(() => {
		if (!isLoadingDashboardData && !!selectedFamilies?.length) {
			setDataCharts((prev) => ({
				...prev,
				estoque: selectedFamilies?.map((item) => {
					return {
						label: item.nome_familia,
						id: item.id,
						estoque_atual: !!item?.sum_estoque_atual
							? Number(item.sum_estoque_atual)
							: 0,
					};
				}),
			}));
		} else {
			setDataCharts((prev) => ({
				...prev,
				estoque: [],
			}));
		}
	}, [
		isLoadingDashboardData,
		selectedFamilies,
		filterHasBeenChanged,
		isLoadingDashboardFilter,
	]);

	useEffect(() => {
		if (productionDashboardDataByProductStockData) {
			setProductsPagination((prev) => ({
				...prev,
				totalPages:
					!!productionDashboardDataByProductStockData?.totalRows
						? Math.ceil(
								Number(
									productionDashboardDataByProductStockData?.totalRows,
								) / productsPagination.size,
						  )
						: 0,
			}));

			if (!selectedProducts?.length) {
				setSelectedProducts(
					productionDashboardDataByProductStockData?.rows?.slice(
						0,
						9,
					),
				);

				setSelectProductOptions(
					productionDashboardDataByProductStockData?.rows?.map(
						(el) => el?.produto_id,
					) || [],
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		productionDashboardDataByProductStockData,
		productsPagination.size,
		setProductsPagination,
	]);

	useEffect(() => {
		if (productionDashboardDataByProjectionProductStockData) {
			setProjectionStockPagination((prev) => ({
				...prev,
				totalPages:
					!!productionDashboardDataByProjectionProductStockData?.totalRows
						? Math.ceil(
								Number(
									productionDashboardDataByProjectionProductStockData?.totalRows,
								) / projectionStockPagination.size,
						  )
						: 0,
			}));

			if (!selectProjectionStockOptions?.length) {
				setSelectedProjectionStock(
					productionDashboardDataByProjectionProductStockData?.rows?.slice(
						0,
						1,
					),
				);

				setSelectProjectionStockOptions(
					productionDashboardDataByProjectionProductStockData?.rows
						?.slice(0, 1)
						?.map((el) => el?.produto_id) || [],
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		productionDashboardDataByProjectionProductStockData,
		projectionStockPagination.size,
		setProjectionStockPagination,
	]);

	// Grafico em grupos e empilhado
	useEffect(() => {
		if (
			(!isLoadingDashboardData || filterHasBeenChanged) &&
			!!selectedProducts?.length
		) {
			setDataCharts((prev) => ({
				...prev,
				estoquePorProdutos: {
					series: [
						{
							name: 'Estoque Atual',
							group: 'grupo 1',
							data: extractDataValues(
								selectedProducts,
								'quantidade_estoque_atual',
							),
						},
						{
							name: 'Estoque de Segurança',
							group: 'grupo 2',
							data: extractDataValues(
								selectedProducts,
								'quantidade_estoque_seguranca',
							),
						},
						{
							name: 'Plano Restrito',
							group: 'grupo 2',
							data: extractDataValues(
								selectedProducts,
								'quantidade_plano_restrito',
							),
						},
					],
					categories: extractCategories(selectedProducts),
					dates: {
						estoqueAtual: extractDates(
							selectedProducts,
							'data_estoque_atual',
							'DD/MM/YYYY',
						),
						seguranca: extractDates(
							selectedProducts,
							'data_estoque_seguranca',
							'MM/YYYY',
						),
						restrito: extractDates(
							selectedProducts,
							'data_plano_restrito',
							'MM/YYYY',
						),
					},
					suggestedStock: extractDataValues(
						selectedProducts,
						'quantidade_estoque_sugerido',
					),
				},
			}));

			setFilterHasBeenChanged(false);
		} else {
			setDataCharts((prev) => ({
				...prev,
				estoquePorProdutos: {
					series: [],
					categories: [],
					dates: {
						estoqueAtual: [],
						seguranca: [],
						restrito: [],
					},
				},
			}));
		}
	}, [
		isLoadingDashboardData,
		selectedProducts,
		filterHasBeenChanged,
		isLoadingDashboardFilter,
		extractDataValues,
		extractCategories,
		extractDates,
	]);

	useEffect(() => {
		if (
			(!isLoadingDashboardData || filterHasBeenChanged) &&
			!!selectedProjectionStock?.length
		) {
			setDataCharts((prev) => ({
				...prev,
				projecaoNecessidadeEstoque: {
					series: [
						{
							name: 'Estoque Atual',
							group: 'grupo 1',
							data:
								selectedProjectionStock[0]?.necessidadeEstoque
									?.sort(arraySortProjectionStock)
									?.map((el) => el?.estoque_atual || null) ||
								[],
						},
						{
							name: 'Estoque de Segurança',
							group: 'grupo 2',
							data:
								selectedProjectionStock[0]?.necessidadeEstoque
									?.sort(arraySortProjectionStock)
									?.map(
										(el) => el?.estoque_seguranca || null,
									) || [],
						},
						{
							name: 'Plano Restrito',
							group: 'grupo 2',
							data:
								selectedProjectionStock[0]?.necessidadeEstoque
									?.sort(arraySortProjectionStock)
									?.map((el) => el?.plano_restrito || null) ||
								[],
						},
					],
					categories:
						selectedProjectionStock[0]?.necessidadeEstoque
							?.sort(arraySortProjectionStock)
							?.map(
								(el) =>
									moment(el?.data)?.format('MMM/YYYY') ||
									'Não informado',
							) || [],
				},
			}));

			setFilterHasBeenChanged(false);
		} else {
			setDataCharts((prev) => ({
				...prev,
				projecaoNecessidadeEstoque: {
					series: [],
					categories: [],
				},
			}));
		}
	}, [
		isLoadingDashboardData,
		selectedProjectionStock,
		filterHasBeenChanged,
		isLoadingDashboardFilter,
		arraySortProjectionStock,
	]);

	useEffect(() => {
		if (!!productionDashboardDataByFamilyStockData?.rows?.length) {
			setFamiliesPagination((prev) => ({
				...prev,
				totalPages:
					!!productionDashboardDataByFamilyStockData?.totalRows
						? Math.ceil(
								Number(
									productionDashboardDataByFamilyStockData?.totalRows,
								) / familiesPagination.size,
						  )
						: 0,
			}));

			if (!selectFamilyOptions.length) {
				setSelectedFamilies(
					productionDashboardDataByFamilyStockData?.rows?.slice(0, 9),
				);

				setSelectFamilyOptions(
					productionDashboardDataByFamilyStockData?.rows?.map(
						(el) => el?.id,
					) || [],
				);
			}
		}
	}, [productionDashboardDataByFamilyStockData]);

	/**
	 * useEffect para inserir valores nos inputs de filtro quando o usuário acessa
	 * a tela na primeira renderização.
	 * Toda vez que um input é preenchido consulta o back para descobrir quais
	 * valores devem ser inseridos nos inputs seguintes.
	 */
	useEffect(() => {
		if (isFirstRender && productionDashboardFilter) {
			if (!productionDashboardFilter?.recursos?.length) {
				setFirstRender(false);
				setTimeout(() => RefetchProductionDashboardData(), 250);
				return;
			}

			if (!filterValues?.recursos?.length) {
				setFilterValues((prev) => ({
					...prev,
					recursos:
						productionDashboardFilter?.recursos?.map(
							(r) => r?.id,
						) || [],
				}));
			} else {
				if (!productionDashboardFilter?.meses?.length) {
					setFirstRender(false);
					setTimeout(() => RefetchProductionDashboardData(), 250);
					return;
				}

				if (!filterValues?.meses?.length) {
					setFilterValues((prev) => ({
						...prev,
						meses: !!productionDashboardFilter?.meses?.length
							? [
									Math.max(
										...productionDashboardFilter.meses
											.filter((m) => !!m?.mes)
											.map((m) => m.mes),
									),
							  ]
							: [],
					}));
				} else {
					if (!productionDashboardFilter?.dias?.length) {
						setFirstRender(false);
						setTimeout(() => RefetchProductionDashboardData(), 250);
						return;
					}

					if (!filterValues?.dias?.length) {
						setFilterValues((prev) => ({
							...prev,
							dias:
								productionDashboardFilter?.dias?.map(
									(d) => d?.dia,
								) || [],
						}));
					} else {
						if (!productionDashboardFilter?.turnos?.length) {
							setFirstRender(false);
							setTimeout(
								() => RefetchProductionDashboardData(),
								250,
							);
							return;
						}

						setFilterValues((prev) => ({
							...prev,
							turnos:
								productionDashboardFilter?.turnos?.map(
									(t) => t?.turno,
								) || [],
						}));

						setFirstRender(false);
						setTimeout(() => RefetchProductionDashboardData(), 250);
					}
				}
			}
		}
	}, [productionDashboardFilter]);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card
				bgGradient={
					'radial-gradient(100% 100.05% at 0% 0%, #8EA1BE 0%, #293039 63.54%, #5E6E85 100%)'
				}
				pr={8}
				minHeight={'80vh'}
			>
				{renderComponent()}
			</Card>
		</Flex>
	);
};

const DashboardProducao = () => {
	return (
		<DashboardProducaoProvider>
			<DashboardProducaoComponent />
		</DashboardProducaoProvider>
	);
};

export default DashboardProducao;
