import { Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import { useContext, useEffect, useMemo, useState } from 'react';
import FormCadastrarEditarCidade from '../../../../../components/forms/FormCadastrarEditarCidade';
import { useStateList } from '../../../../../services/queryClient/wrapperHooks/useStateList';
import { LoadingSpinner } from '../../../../../components/loading/Loading';
import { useSalesZoneList } from '../../../../../services/queryClient/wrapperHooks/useSalesZoneList';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';
export default function CadastrarCidades() {
	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const [additionalData, setAdditionalData] = useState<any>();

	const { data: StatesData, isLoading: isLoadingState } = useStateList();
	const { data: SalesZoneData, isLoading: isLoadingSalesZone } =
		useSalesZoneList();

	const helpCadastrarCidades: SidebarHelp = useMemo(
		() => ({
			title: 'Cadastrar Cidades',
			firstParagraph:
				'Para cadastrar uma nova associação de Zona de Venda com uma Cidade, selecione o estado (UF), a cidade e a zona de venda desejada.',
			secondParagraph:
				'Observação: uma cidade só pode ser associada a uma zona de venda.',
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	useEffect(() => {
		setDataSidebar(helpCadastrarCidades);
	}, [helpCadastrarCidades, setDataSidebar]);

	useEffect(() => {
		if (!!StatesData?.length) {
			setAdditionalData((prev) => ({ ...prev, states: StatesData }));
		}

		if (!!SalesZoneData?.data?.length) {
			setAdditionalData((prev) => ({
				...prev,
				salesZone: SalesZoneData?.data,
			}));
		}
	}, [StatesData, SalesZoneData]);

	const initialState = {};

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				{isLoadingState || isLoadingSalesZone ? (
					<LoadingSpinner />
				) : (
					<FormCadastrarEditarCidade
						initialState={initialState}
						additionalData={additionalData}
					/>
				)}
			</Card>
		</Flex>
	);
}
