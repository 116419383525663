import { ButtonGroup, Flex, useDisclosure } from '@chakra-ui/react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import Card from 'components/card/Card';
import CheckTable from 'components/dataTables/CheckTable';
import { columnsDataProduct } from './variables/columnsDataProduct';
import { FileUploadModal } from 'components/modal/fileUpload/FileUploadModal';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDownloadTemplateProduct } from 'services/queryClient/wrapperHooks/uploads/useDownloadTemplateProduct';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useHistory } from 'react-router-dom';
import { useUploadProducts } from 'services/queryClient/wrapperHooks/uploads/useUploadProducts';

import { AlertComponent } from 'components/alerts/AlertComponent';
import { useProductNotFamilyList } from 'services/queryClient/wrapperHooks/product/useProductNotFamilyList';
import { TooltipStandard } from 'components/tooltip/TooltipStandard';
import ButtonExportGenericCSV from 'components/buttons/ButtonExportGenericCSV';
import { useExportProducts } from 'services/queryClient/wrapperHooks/useExportProducts';
import { formatStringoToDateAndTime } from 'utils/functions/dateUtils';
import { URL_CT360, URL_FALE_CONOSCO } from 'constants/global';

interface Products {
	codigo_produto: string;
	empresa_id: number;
	nome_familia: string;
	familia_id: number;
	id: number;
	nome_produto: string;
	custo: number;
	status: boolean;
	nome_sub_familia: string;
	nome_modelo_produto: string;
	participa_rateio: boolean;
	data_registro: string;
}

export default function CadastroProdutos() {
	const { isOpen, onOpen, onClose } = useDisclosure();

	let history = useHistory();

	const { pathCompanyName } = useGlobalContext();

	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const tableColumns = useMemo(() => columnsDataProduct, []);

	const [hasBaseData, setHasBaseData] = useState<boolean>(false);

	const [parentCheckedItems, setParentCheckedItems] = useState<
		{ checked: boolean }[]
	>([]);

	const helpProducts: SidebarHelp = useMemo(
		() => ({
			title: 'Produtos',
			firstParagraph:
				'Nesta tela é possível visualizar todos os Produtos (por código) do seu negócio.  Note que todos os produtos devem pertencer a alguma família de produto.',
			secondParagraph:
				'Utilize o botão de “Rateio” da tabela para indicar se o produto participa ou não do rateio de vendas, e assim da previsão da demanda. Ao ativar o botão, você indica que ele participa do rateio.',
			thirdParagraph:
				'Acesse o botão “Cadastrar Produto” para adicionar novos produtos ou importe um arquivo de produtos acessando “Importar Produto”.',
			firstLink: URL_CT360,
			secondLink: URL_FALE_CONOSCO,
		}),
		[],
	);

	const dataDelete = useMemo(
		() => ({
			provider: 'product',
			toastSuccessTitle: 'Excluido com sucesso!',
			toastSuccessDescription: 'O produto foi excluido com sucesso.',
			toastErrorTitle: 'Erro ao excluir produto!',
			title: 'Deseja realmente excluir este produto?',
			toastProcessingTitle: 'Processando solicitação...',
			toastProcessingDescription:
				'Processando a solicitação, aguarde o e-mail de confirmação.',
			text: [
				'Você está prestes a excluir este produto da sua base de cadastros. Excluir este produto resultará na remoção dos seguintes itens vinculados a ele:',
				'REPLACE_ME',
				'Você tem certeza que deseja excluir?',
			],
			invalidate: 'produto-list',
		}),
		[],
	);

	const deleteMessage = useMemo(
		() => [
			'Você está prestes a excluir estes produtos da sua base de cadastros. Excluir estes produtos resultará na remoção dos seguintes itens vinculados a ele:',
			'REPLACE_ME',
		],
		[],
	);

	const dataDeleteAll = useMemo(
		() => ({
			provider: 'product',
			toastSuccessTitle: 'Registros excluídos com sucesso!',
			toastSuccessDescription: 'Os produtos foram excluídos com sucesso',
			toastErrorTitle: 'Erro ao excluir produtos!',
			toastProcessingTitle: 'Processando solicitação...',
			toastProcessingDescription:
				'Processando a solicitação, aguarde o e-mail de confirmação.',
			invalidate: 'produto-list',
		}),
		[],
	);

	const { data: ProductNotFamilyData } = useProductNotFamilyList();

	const goToCadastroProdutos = useCallback(() => {
		history.push(`/${pathCompanyName}/cadastros/produto/criar`);
	}, [history, pathCompanyName]);

	const renderRow = useCallback((product: Products) => {
		return {
			empresa_id: product.empresa_id,
			id:
				product.status === true
					? [product.id, false]
					: [product.id, true],
			produto: product.nome_produto,
			codigo_produto: product.codigo_produto,
			custo:
				product?.custo >= 0
					? product?.custo?.toLocaleString?.('pt-BR', {
							style: 'currency',
							currency: 'BRL',
					  })
					: '',
			familia_id: product.familia_id,
			familia: product?.nome_familia,
			subfamilia: product?.nome_sub_familia || '',
			nomeModeloProduto: product?.nome_modelo_produto || '',
			status: product.status === true ? 'Ativo' : 'Inativo',
			rateio: product.participa_rateio,
			dataRegistro: formatStringoToDateAndTime(product?.data_registro),
		};
	}, []);

	const renderButtons = useCallback(() => {
		return (
			<Flex
				flexDirection={'row'}
				justifyContent={'flex-end'}
				mt={'20px'}
				me={'20px'}
				mb={'40px'}
			>
				<ButtonGroup spacing={'20px'}>
					<ButtonComponent
						data-test='button-importar-produtos'
						type={'ghost'}
						title={'Importar Produtos'}
						onClick={onOpen}
					/>
					<FileUploadModal
						isOpen={isOpen}
						onClose={onClose}
						headerText={'Importar Produtos'}
						module={'produtos'}
						useDownloadTemplate={useDownloadTemplateProduct}
						stringInvalidQuery='produto-list'
						useUpload={useUploadProducts}
						showLineRestricts={true}
						columnsData={tableColumns}
						hasBaseData={hasBaseData}
					/>
					<ButtonComponent
						data-test='button-cadastrar-produtos'
						type={'primary'}
						title={'Cadastrar Produtos'}
						onClick={goToCadastroProdutos}
					/>
					<TooltipStandard
						label='Exportar Produtos para CSV'
						shouldWrapChildren={true}
					>
						<ButtonExportGenericCSV
							useExport={useExportProducts}
							cycleId={'produtos'}
							module={'produtos'}
							errorDescription='Não há produtos para exportar!'
						/>
					</TooltipStandard>
				</ButtonGroup>
			</Flex>
		);
	}, [
		goToCadastroProdutos,
		hasBaseData,
		isOpen,
		onClose,
		onOpen,
		tableColumns,
	]);

	const renderTable = useCallback(
		() => (
			<CheckTable
				dataTest='button-filtrar-produtos'
				module={'Produtos'}
				linkEditForm={`/${pathCompanyName}/cadastros/produto/editar`}
				columnsData={tableColumns}
				dataDelete={dataDelete}
				deleteMessage={deleteMessage}
				showGenericSearchInput={false}
				filterModule='produto'
				bulkDeleteProps={dataDeleteAll}
				renderRow={renderRow}
				setHasBaseData={setHasBaseData}
				setAlertUpdate={() => {}}
				updateCheckedItems={setParentCheckedItems}
			/>
		),
		[
			dataDelete,
			dataDeleteAll,
			deleteMessage,
			pathCompanyName,
			renderRow,
			tableColumns,
		],
	);

	useEffect(() => {
		setDataSidebar(helpProducts);
	}, [helpProducts, setDataSidebar]);

	const selectionLimit = 10;

	useEffect(() => {
		const checkedCount = parentCheckedItems.filter(
			(el) => el.checked,
		).length;
	}, [parentCheckedItems, selectionLimit]);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				<Flex
					pl={5}
					pr={5}
					w={'100%'}
					direction={'column'}
				>
					{ProductNotFamilyData &&
						ProductNotFamilyData.length > 0 && (
							<AlertComponent
								title='Atenção!'
								description='Existem produtos cadastrados sem famílias. Necessário corrigir!'
								status={'warning'}
								hasCloseButton
							/>
						)}
				</Flex>

				{renderButtons()}
				{renderTable()}
			</Card>
		</Flex>
	);
}
