import { Flex } from '@chakra-ui/react';
import FormBotProcessLogs from 'components/forms/FormBotProcessLogs';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';

import { SidebarHelp } from 'models/sidebar-help.model';
import { useContext, useEffect, useState } from 'react';

import { URL_CT360, URL_FALE_CONOSCO } from '../../../../constants/global';

export default function BotProcessLogs() {
	const { setters } = useContext(SidebarHelpContext);
	const { setDataSidebar } = setters;

	const helpTipologiaParadas: SidebarHelp = {
		title: 'Atividades Recentes do Robô',
		firstParagraph:
			'Nesta tela é possível acompanhar o histórico das atividades de importações via robô, conseguindo analisar o tipo de importação realizada, o sucesso ou insucesso da mesma e data/horário.',
		firstLink: URL_CT360,
		secondLink: URL_FALE_CONOSCO,
	};

	useEffect(() => {
		setDataSidebar(helpTipologiaParadas);
	}, []);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			{
				<FormBotProcessLogs
					isOpen={true}
					onOpen={() => {}}
					onClose={() => {}}
				/>
			}
		</Flex>
	);
}
